import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import L from 'leaflet'
import {
  Map,
  TileLayer,
  Circle,
  Polygon,
  Tooltip,
  Polyline,
  Marker,
  Popup,
  ZoomControl,  
  FeatureGroup
} from 'react-leaflet'
import { connect } from 'react-redux'
import TextPath from 'react-leaflet-textpath'
import moment from 'moment'
import * as turf from '@turf/turf'
import './../common/Maps/DrawMap.scss'
import './../../assets/KML/index.js'
import ReactHtmlParser from 'react-html-parser'
import { getDateTimeFormat, setAttributeFormat } from '../../Helpers'
import { MapFilters } from './MapFilters'
class RouteMap extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lat: 0,
      lng: 0,
      zoom: 3,
      minZoom: 3,
      MarkerDOM: null,
      fitBounds: {},
      bounds: [],
      position: [0, 0],
      kmlLayer: null
    }
    this.mapRef = this.mapRef.bind(this)
    this.viewData = this.viewData.bind(this)
    this.setBoundOptions = this.setBoundOptions.bind(this)
    this.map = null
  }

  mapRef (el) {
    if (el) {
      this.map = el.leafletElement
    }
  }

  onAdded (e, b) {
    this.setState({
      fitBounds: {
        ...this.state.fitBounds,
        [b]: e.target.getBounds()
      }
    })
  }

  setBoundOptions () {
    return {}
  }

  componentWillReceiveProps (n) {
    if (n.kmlContent) {
      if (!this.state.kmlLayer && this.map) {
        try {
        const layer = new L.KML(n.kmlContent)
        this.setState({ kmlLayer: layer }, () => {
          if(this.map) {
            this.map.addLayer(layer)
            this.map.fitBounds(layer.getBounds())
          }
        })
      } catch (error) {
        console.error("Error creating KML layer=======", error);
      }
      } else {
        this.clearSelection()
        this.setState(
          {
            kmlLayer: null
          },
          () => {
            
            const layer = new L.KML(n.kmlContent)
            this.setState({ kmlLayer: layer }, () => {
              if(this.map) {
                this.map.addLayer(layer)
                this.map.fitBounds(layer.getBounds())
              }
            })
          }
        )
      }
    }
  }

  componentWillMount () {
    if (this.props.logInUser.zoom) {
      this.setState({
        zoom: this.props.logInUser.zoom,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude
      })
    } else if (this.props.ServerSetting.zoom) {
      this.setState({
        zoom: this.props.ServerSetting.zoom,
        lat: this.props.ServerSetting.latitude,
        lng: this.props.ServerSetting.longitude
      })
    }
  }

  viewData (row, e) {
    let points = e.target._latlngs.map(latlng => [latlng.lng, latlng.lat])
    var line = turf.lineString(points)
    var pt = turf.point([e.latlng.lng, e.latlng.lat])
    var nearestPoint = turf.nearestPointOnLine(line, pt, { units: 'meters' })

    var nlatlng = this.props.routes[row.startPositionId]['positions'][
      nearestPoint['properties']['index']
    ]

    let timeFormat = getDateTimeFormat()

    let t = moment(nlatlng.fixTime)
      .tz(this.props.serverTimeZoneName)
      .format(timeFormat)

    let html =
      '<div class="position-box"><strong>' +
      row.deviceName +
      '</strong><div class="position-box-body">Address: ' +
      (ReactHtmlParser(nlatlng.address)[0] || '') +
      ' <br />Time: ' +
      t +
      '<br/> Speed : ' +
      // (nlatlng.speed * 1.852).toFixed(0) 
      setAttributeFormat('speed', nlatlng.speed) +
      '<br />Coordinates: ' +
      e.latlng.lat.toFixed(8) +
      ', ' +
      e.latlng.lng.toFixed(8) +
      '</div></div>'

    e.target.bindPopup(html).openPopup(e.latlng)

    if (e.sourceTarget._map) {
      //L.marker([nearestPoint.geometry.coordinates[1], nearestPoint.geometry.coordinates[0]]).addTo(e.sourceTarget._map);
      // [lat, lng]
      //L.marker([nlatlng.latitude, nlatlng.longitude]).addTo(e.sourceTarget._map);
    }
  }

  clearSelection = () => {
    if(this.map) {
      this.map.removeLayer(this.state.kmlLayer)   
    }
  }

  updateVisible = () => {
    setTimeout(() => {
      let bounds = []
      this.props.geoFence.map(g => {
        if (g.visible === true && this.state.fitBounds[g.id]) {
          bounds.push(this.state.fitBounds[g.id])
        }
        return null
      })
      if (bounds.length) {
        this.map.fitBounds(bounds)
      }
    }, 50)
  }

  render () {
    let polylines = []
    let waypoints = []

    let timeFormat = getDateTimeFormat()

    if (Object.keys(this.props.routes).length) {
      Object.keys(this.props.routes).map(key => {
        if (this.props.routes[key].visible) {
          let p = []
          let obj = this.props.routes[key]

          if (this.props.routes[key]) {
            polylines.push(
              <Marker
                key={obj.row.startPositionId + 'start'}
                position={[obj.row.startLat, obj.row.startLon]}
                icon={L.icon({
                  iconUrl: '/assets/images/maps/start-marker.svg',
                  iconSize: [40, 40],
                  iconAnchor: [10, 40],
                  popupAnchor: [0, -40]
                })}
              >
                <Popup>
                  <div className='position-box'>
                    <strong  style={{display:"flex", justifyContent:"space-between",  alignItems:"center"}}>{obj.row.deviceName}
                    {this.props.monitoring && 
                        (<button style={{ background:"transparent", border:"none",  color:"white"}} onClick={this.props.hideTracks}>
                            X
                        </button>)
                      } 
                    </strong>
                    <div className='position-box-body'>
                      Address: {ReactHtmlParser(obj.row.startAddress)} <br />
                      Time:{' '}
                      {moment(obj.row.startTime)
                        .tz(this.props.serverTimeZoneName)
                        .format(timeFormat)}
                      <br />
                      Coordinates: {obj.row.startLat + ', ' + obj.row.startLon}
                    </div>{' '}
                  </div>
                </Popup>
              </Marker>
            )
            polylines.push(
              <Marker
                key={obj.row.endPositionId + 'end'}
                position={[obj.row.endLat, obj.row.endLon]}
                icon={L.icon({
                  iconUrl: '/assets/images/maps/end-marker.svg',
                  iconSize: [40, 40],
                  iconAnchor: [10, 40],
                  popupAnchor: [0, -40]
                })}
              >
                <Popup>
                  <div className='position-box'>
                    <strong style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>{obj.row.deviceName}
                    {this.props.monitoring && 
                      (    <button style={{ background:"transparent", border:"none",  color:"white"}} onClick={this.props.hideTracks}>
                            X
                          </button>)
                        } 
                    </strong>
                    <div className='position-box-body'>
                      Address: {ReactHtmlParser(obj.row.endAddress)} <br />
                      Time:{' '}
                      {moment(obj.row.endTime)
                        .tz(this.props.serverTimeZoneName)
                        .format(timeFormat)}
                      <br />
                      Coordinates: {obj.row.endLat + ', ' + obj.row.endLon}
                    </div>{' '}
                  </div>
                </Popup>
              </Marker>
            )

            obj.positions.map(pos => {
              p.push(L.latLng(pos.latitude, pos.longitude))
              waypoints.push({ latLng: L.latLng(pos.latitude, pos.longitude) })
              return null
            })

            polylines.push(
              <Polyline
                pane='overlayPane'
                // onClick={e => {this.props.monitoring ? null : this.viewData(obj.row, e)}}
                onClick={this.props.monitoring ? null :  e => this.viewData(obj.row, e) }
                onAdd={e => this.onAdded(e, key)}
                key={key}
                color={obj.row.color}
                positions={p}
                weight={6}
              >
                {/* {this.props.monitoring && 
                  <Popup >
                    <button style={{ border:"none",  color:"white"}} onClick={this.props.hideTracks}>
                      Hide Tracks
                    </button>
                    </Popup>
                  }  */}
                <TextPath
                  positions={p}
                  text='&#x25B6;          '
                  repeat
                  offset={8}
                  attributes={{ 'font-size': 30, fill: obj.row.color }}
                />
              </Polyline>
            )
          }
        }
        return null
      })
    }

    let bounds = []
    let zoom = this.state.zoom
    let position = this.state.position

    if (Object.keys(this.state.fitBounds).length) {
      Object.keys(this.state.fitBounds).map(id => {
        if (Object.keys(this.props.routes).length) {
          let obj = this.props.routes[id]
          if (obj && obj.visible) {
            // bounds.push(this.state.fitBounds[id])
            if (this.state.fitBounds[id] && Object.keys(this.state.fitBounds[id]).length > 0) {
              bounds.push(this.state.fitBounds[id]);
            }
          }
        }
        return null
      })
    } else {
      if (this.props.logInUser.zoom) {
        position = [
          this.props.logInUser.latitude || 0,
          this.props.logInUser.longitude || 0
        ]
        zoom = this.props.logInUser.zoom
      } else if (this.props.ServerSetting.zoom) {
        position = [
          this.props.ServerSetting.latitude || 0,
          this.props.ServerSetting.longitude || 0
        ]
        zoom = this.props.ServerSetting.zoom
      }
    }

    const geofences = this.props.geoFence.map(obj => {
      if (obj.attributes.type === 'circle' && obj.visible === true) {
        return (
            <Circle
              onAdd={e => this.onAdded(e, obj.id)}
              id={obj.id}
              radius={obj.attributes.radius}
              center={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={'top'} permanent>
                <div>
                  <span>{ReactHtmlParser(obj.name)}</span>
                </div>
              </Tooltip>
            </Circle>
        )
      } else if (obj.attributes.type === 'polygon' && obj.visible === true) {
        return (
            <Polygon
              onAdd={e => this.onAdded(e, obj.id)}
              id={obj.id}
              key={obj.id + '__1'}
              positions={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={'top'} permanent>
                <div>
                  <span>{ReactHtmlParser(obj.name)}</span>
                </div>
              </Tooltip>
            </Polygon>
        )
      } else if (obj.attributes.type === 'polyline' && obj.visible === true) {
        return (
            <Polyline
              onAdd={e => this.onAdded(e, obj.id)}
              id={obj.id}
              key={obj.id + '__1'}
              positions={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={'top'} permanent>
                <div>
                  <span>{ReactHtmlParser(obj.name)}</span>
                </div>
              </Tooltip>
            </Polyline>
        )
      }else if (
        obj &&
        obj.attributes.type === "kmlFile" &&
        obj.visible === true
      ) {
       const coordinates = obj?.attributes?.latlng;
       bounds = coordinates;
        return (
          <FeatureGroup
            // onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
            // ref={this._onFeatureGroupReady}
            key={obj.id}
          >
           <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* Render Polyline with the extracted coordinates */}
          <Polyline positions={coordinates} color={"red"} />
          </FeatureGroup>
        )
      }

      return ''
    })

    let crs = {}
    if (['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 }
    }
    const thisMap = [
      <Map
        boundsOptions={this.setBoundOptions}
        bounds={bounds && bounds.length ? bounds : null}
        // bounds={this.props.listType === 'tracks' ?  kmlBounds : this.props.bounds.length ? this.props.bounds : null}

        key={1}
        center={position}
        zoom={zoom}
        zoomControl={false}
        ref={this.mapRef}
        style={{ height: '100%' }}
        {...crs}
      >
        <TileLayer
          {...this.props.mapLayer}
          maxNativeZoom={this.props.mapLayer.maxZoom}
          maxZoom={this.props.mapLayer.maxZoom}
          minZoom={this.state.minZoom}
        />
        <ZoomControl position={'topright'} />
         {this.props.monitoring && (
            <button 
                style={{
                  position: 'absolute',
                  bottom: '35px', // Adjust the bottom offset as needed
                  left: '56%', // Center horizontally
                  transform: 'translateX(-50%)', // Center horizontally precisely
                  zIndex: '10000',
                  background: this.props.themecolors.backgroundColor,
                  border: 'none',
                  padding: '10px',
                  color: 'white',
                  borderRadius:"6px", 
                  cursor: 'pointer' // Set cursor to pointer
                }} 
                onClick={this.props.hideTracks}
                // onMouseDown={(e) => e.stopPropagation()} // Prevent map click
              >
               {this.props.translate('Close Tracks')}
            </button>
       
           )} 

        {this.state.MarkerDOM}
        {polylines}
        {geofences}
        <div className='map-filters-wrapper'>
          <MapFilters
            disableBottomLeftFilters
            disablePOIFilters
            updateVisible={this.updateVisible}
            themecolors={this.props.themecolors}
            translate={this.props.translate}
            mapRef={this.map}
            showListType={this.props.showListType}
            listType={this.props.listType}
            
          />
        </div>
    </Map>
    ]
    return (
      <React.Fragment>
        <button
          id='clearSelection'
          onClick={this.clearSelection}
          style={{ display: 'none' }}
        >
          clear
        </button>
        {['osm', ''].includes(this.props.mapLayer.id) ? thisMap : null}
        {['carto'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['gccStreet'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleTerrain'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleSatellite'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleHybrid'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleRoad'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['baidu'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)
          ? thisMap
          : null}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  mapLayer: state.mapLayer,
  geoFence: state.geoFence,
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)(withLocalize(RouteMap))
