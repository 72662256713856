import React, { Component } from 'react'
import TextField from '../../../Components/common/TextField'
import MenuItem from '../../../Components/common/MenuItem'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '../../../Components/common/Autocomplete'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
//import CloseIcon from '@material-ui/icons/Close';
//import IconButton from '@material-ui/core/IconButton';
import Notifications from 'react-notification-system-redux'
import Checkbox from '../../../Components/common/CheckboxPermissions'
import Tooltip from '../../../Components/common/Tooltip'
import ReactHtmlParser from 'react-html-parser'
import instance from '../../../axios'
import { errorHandler } from '../../../Helpers'
class Sensors extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.changeAttribute = this.changeAttribute.bind(this)
    this.computedAttributes_Selection = this.computedAttributes_Selection.bind(
      this
    )
  }

  handleChange (name, event) {
    let e = event
    if (name === 'expression') {
      e = { target: { value: event } }
      this.props.handleChange('expression', e)
    } else {
      this.props.handleChange(name, e)
    }
  }
  changeAttribute (name, value) {
    this.props.changeAttribute(name, value)
  }

  computedAttributes_Selection (deviceId, attributeId) {
    let obj = {
      deviceId,
      attributeId
    }

    // fetch(`/api/permissions/`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions/`,
      method: 'POST',
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
          //this.props.dispatch(addComputedAttributeDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('attributeAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('attributeAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        // } else {
        //   throw response
        // }
      })
      // .catch(e => {
      //   if (e && e.text) {
      //     e.text().then(err => {
      //       alert(err)
      //     })
      //   }
      // })
      .catch(error => {errorHandler(error,this.props.dispatch)}) 
  }

  /*shouldComponentUpdate(nextProps, nextState){
    return !isEqual(nextProps.computedAttributesDeviceWise, this.props.computedAttributesDeviceWise);
  }
*/
renderAttributeField = (attribute, id, label) => {
  const { form, translate } = this.props;

  return form.attribute === attribute ? (
    <Grid item xs={12} key={id}>
      <TextField
        id={id}
        label={translate(label)}
        type='text'
        value={
          form.attributes && form.attributes[id]
            ? form.attributes[id]
            : 'disabled'
        }
        onChange={e => this.changeAttribute(id, e.target.value)}
        variant='outlined'
        margin='dense'
        fullWidth
        select
      >
        <MenuItem value={'disabled'}>
          {translate('sharedDisabled')}
        </MenuItem>
        <MenuItem value={'parking'}>
          {translate('parkingStart')}
        </MenuItem>
        <MenuItem value={'ignition'}>
          {translate('notification.ignitionOff')}
        </MenuItem>
      </TextField>
    </Grid>
  ) : null;
};


  render () {
    const disceteSensorsTypes = [
      {
        label: this.props.translate('sensorsTranslation.driverUniqueId'),
        value: 'driverUniqueId'
      },
      {
        label: this.props.translate('sensorsTranslation.passengerId'),
        value: 'passengerUniqueId'
      },
      {
        label: this.props.translate('sensorsTranslation.binUniqueId'),
        value: 'binUniqueId'
      },
      {
        label: this.props.translate('sensorsTranslation.trailerUniqueId'),
        value: 'trailerUniqueId'
      }
    ]
    const attributesToRender = [
      { attribute: 'driverUniqueId', id: 'resetDriver', label: 'resetDriver' },
      { attribute: 'passengerUniqueId', id: 'resetPassenger', label: 'resetPassenger' },
      { attribute: 'binUniqueId', id: 'resetBin', label: 'resetBin' },
      { attribute: 'trailerUniqueId', id: 'resetTrailer', label: 'resetTrailer' }
    ];
    // let fitlerDisceteSensorsTypes = []
    // if (this.props.computedAttributesDeviceWise.length) {
    //   disceteSensorsTypes.map(item => {
    //     let prsntvalue = false
    //     this.props.computedAttributesDeviceWise.map(item1 => {
    //       if (item.value === item1.attribute) {
    //         prsntvalue = true
    //       }
    //     })
    //     if (!prsntvalue) {
    //       fitlerDisceteSensorsTypes.push(item)
    //     }
    //   })
    // } else {
    //   fitlerDisceteSensorsTypes = disceteSensorsTypes
    // }

    return (
      <div style={{ minHeight: 300 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id='description'
              label={this.props.translate('sharedDescription')}
              type='text'
              value={ReactHtmlParser(this.props.form.description) || ''}
              onChange={e => this.handleChange('description', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='attribute'
              label={this.props.translate('positionTooltip.sensorsType')}
              type='text'
              value={this.props.form.attribute ? this.props.form.attribute : ''}
              onChange={e => this.handleChange('attribute', e)}
              variant='outlined'
              margin='dense'
              fullWidth
              select
            >
              {disceteSensorsTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              translate={this.props.translate}
              value={this.props.form.attributes.input || ''}
              suggestions={this.props.suggestions}
              handleChange={this.changeAttribute}
              fieldName='input'
              label={this.props.translate('sensorInput')}
            />
          </Grid>
          <>
        {attributesToRender?.map(attr =>
          this.renderAttributeField(attr.attribute, attr.id, attr.label)
        )}
        </>
          {/* {this.props.form.attribute &&
          this.props.form.attribute === 'driverUniqueId' ? (
            <Grid item xs={12}>
              <TextField
                id='resetDriver'
                label={this.props.translate('resetDriver')}
                type='text'
                value={
                  this.props.form.attributes &&
                  this.props.form.attributes.resetDriver
                    ? this.props.form.attributes.resetDriver
                    : 'disabled'
                }
                onChange={e =>
                  this.changeAttribute('resetDriver', e.target.value)
                }
                variant='outlined'
                margin='dense'
                fullWidth
                select
              >
                <MenuItem value={'disabled'}>
                  {this.props.translate('sharedDisabled')}
                </MenuItem>
                <MenuItem value={'parking'}>
                  {this.props.translate('parkingStart')}
                </MenuItem>
                <MenuItem value={'ignition'}>
                  {this.props.translate('notification.ignitionOff')}
                </MenuItem>
              </TextField>
            </Grid>
          ) : null}

        {this.props.form.attribute &&
          this.props.form.attribute === 'passengerUniqueId' ? (
            <Grid item xs={12}>
              <TextField
                id='resetPassenger'
                label={this.props.translate('resetPassenger')}
                type='text'
                value={
                  this.props.form.attributes &&
                  this.props.form.attributes.resetPassenger
                    ? this.props.form.attributes.resetPassenger
                    : 'disabled'
                }
                onChange={e =>
                  this.changeAttribute('resetPassenger', e.target.value)
                }
                variant='outlined'
                margin='dense'
                fullWidth
                select
              >
                <MenuItem value={'disabled'}>
                  {this.props.translate('sharedDisabled')}
                </MenuItem>
                <MenuItem value={'parking'}>
                  {this.props.translate('parkingStart')}
                </MenuItem>
                <MenuItem value={'ignition'}>
                  {this.props.translate('notification.ignitionOff')}
                </MenuItem>
              </TextField>
            </Grid>
          ) : null}
          
        {this.props.form.attribute &&
          this.props.form.attribute === 'binUniqueId' ? (
            <Grid item xs={12}>
              <TextField
                id='resetBin'
                label={this.props.translate('resetBin')}
                type='text'
                value={
                  this.props.form.attributes &&
                  this.props.form.attributes.resetBin
                    ? this.props.form.attributes.resetBin
                    : 'disabled'
                }
                onChange={e =>
                  this.changeAttribute('resetBin', e.target.value)
                }
                variant='outlined'
                margin='dense'
                fullWidth
                select
              >
                <MenuItem value={'disabled'}>
                  {this.props.translate('sharedDisabled')}
                </MenuItem>
                <MenuItem value={'parking'}>
                  {this.props.translate('parkingStart')}
                </MenuItem>
                <MenuItem value={'ignition'}>
                  {this.props.translate('notification.ignitionOff')}
                </MenuItem>
              </TextField>
            </Grid>
          ) : null}
            {this.props.form.attribute &&
          this.props.form.attribute === 'trailerUniqueId' ? (
            <Grid item xs={12}>
              <TextField
                id='resetBin'
                label={this.props.translate('resetTrailer')}
                type='text'
                value={
                  this.props.form.attributes &&
                  this.props.form.attributes.resetTrailer
                    ? this.props.form.attributes.resetTrailer
                    : 'disabled'
                }
                onChange={e =>
                  this.changeAttribute('resetTrailer', e.target.value)
                }
                variant='outlined'
                margin='dense'
                fullWidth
                select
              >
                <MenuItem value={'disabled'}>
                  {this.props.translate('sharedDisabled')}
                </MenuItem>
                <MenuItem value={'parking'}>
                  {this.props.translate('parkingStart')}
                </MenuItem>
                <MenuItem value={'ignition'}>
                  {this.props.translate('notification.ignitionOff')}
                </MenuItem>
              </TextField>
            </Grid>
          ) : null} */}
          
            {this.props.form.attribute &&
            this.props.deviceModal &&
            this.props.form.attribute === 'driverUniqueId' ? (
              <Grid item xs={12}>
                <Tooltip
                  classes={{
                    popper: 'menu-popper',
                    tooltip: 'menu-popper-tooltip'
                  }}
                  // title={`If this option is enabled, last known sensor's value will be considered as "current" and if disabled, sensor's current value will be calculated from the last message only.`}
                >
                  <Checkbox
                    canAssign
                    canRemove
                    translate={this.props.translate}
                    checked={this.props.form.attributes.decodeToDecimal}
                    onChange={e =>
                      this.changeAttribute('decodeToDecimal', e.target.checked)
                    }
                    label={this.props.translate('decodeToDecimal')}
                  />
                </Tooltip>
              </Grid>
            ) : null}
       
          <Grid item xs={12}>
            <Tooltip
              classes={{
                popper: 'menu-popper',
                tooltip: 'menu-popper-tooltip'
              }}
              title={`If this option is enabled, last known sensor's value will be considered as "current" and if disabled, sensor's current value will be calculated from the last message only.`}
            >
              <Checkbox
                canAssign
                canRemove
                translate={this.props.translate}
                checked={this.props.form.attributes.copyFromLast}
                onChange={e =>
                  this.changeAttribute('copyFromLast', e.target.checked)
                }
                label={this.props.translate('copyFromLast')}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapState = state => ({
  positions: state.positions,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withSnackbar(Sensors))
