import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import ChangedPasword from './changedPassword'
import VerifiedEmails from './verifiedEmails'
import ServicePreferences from './servicePerferences'
import UserDefault from './userDefault'
//import EmailGatway from './emailGatway'
import Permissions from './Permissions'
import { checkPrivileges } from '../../Helpers'
import ReleaseHistory from './releaseData'

class accountManagements extends Component {
  constructor () {
    super()
    this.state = {
      value: 'tab1'
    }
  }
  handleChange = (event, value) => {
    this.setState({ value })
  }
  render () {
    const { value } = this.state
    if (this.props.logInUser.id) {
      return (
        <div style={{ paddingTop: 17 }}>
          <AppBar
            position='static'
            color='inherit'
            style={{ background: 'none', boxShadow: 'none' }}
          >
            <Tabs
              value={value}
              onChange={this.handleChange}
              TabIndicatorProps={{
                style: { background: this.props.themecolors.backgroundColor }
              }}
              scrollButtons='on'
              classes={{
                root: 'custom-tabs-root',
                flexContainer: 'custom-tabs',
                scrollable: 'custom-tabs-scrollable',
                indicator: 'custom-indicator',
                scrollButtons: 'scrollable-buttons'
              }}
            >
              <Tab
                value='tab1'
                classes={{
                  root: 'custom-tab-button',
                  selected: 'custom-tab-button-selected'
                }}
                label={this.props.translate('sharedPreferences')}
              />
              {this.props.logInUser && this.props.logInUser.userType !== 1 ? (
                <Tab
                  value='tab2'
                  classes={{
                    root: 'custom-tab-button',
                    selected: 'custom-tab-button-selected'
                  }}
                  label={this.props.translate('sharedPermissions')}
                />
              ) : null}
              <Tab
                value='tab3'
                classes={{
                  root: 'custom-tab-button',
                  selected: 'custom-tab-button-selected'
                }}
                label={this.props.translate('sharedUserDefault')}
              />
              {checkPrivileges("userChangePassword") ?
                <Tab
                value='tab4'
                classes={{
                  root: 'custom-tab-button',
                  selected: 'custom-tab-button-selected'
                }}
                label={this.props.translate('sharedChangedPassword')}
              />:null}
              {/* <Tab
                value='tab5'
                classes={{
                  root: 'custom-tab-button',
                  selected: 'custom-tab-button-selected'
                }}
                label={this.props.translate('verifiedEmails')}
              /> */}
              {/* <Tab
                value='tab5'
                classes={{
                  root: 'custom-tab-button',
                  selected: 'custom-tab-button-selected'
                }}
                label={this.props.translate('releaseHistory')}
              /> */}
            </Tabs>
          </AppBar>
          <Paper
            style={{ padding: 16, minHeight: 270 }}
            className='custom-menu-paper'
            square={true}
          >
            {value === 'tab1' && (
              <div>
                <ServicePreferences {...this.props} />
              </div>
            )}
            {this.props.logInUser &&
            this.props.logInUser.userType !== 1 &&
            value === 'tab2' ? (
              <div>
                <Permissions {...this.props} />
              </div>
            ) : null}
            {value === 'tab3' && (
              <div>
                <UserDefault {...this.props} />
              </div>
            )}
            {value === 'tab4' && checkPrivileges("userChangePassword") && (
              <div>
                <ChangedPasword {...this.props} />
              </div>
            )}
            {value === 'tab5' && (
              <div>
                {/* <VerifiedEmails {...this.props} /> */}
                <ReleaseHistory {...this.props} />
              </div>
            )}
          </Paper>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapState = state => ({
  users: state.users,
  devices: state.devices.data,
  groups: state.groups,
  logInUser: state.logInUsers,
  themecolors: state.themeColors
})
const mapStateToProps = connect(mapState)
export const AcManagements = mapStateToProps(accountManagements)
