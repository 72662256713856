import React from 'react'
import { connect } from 'react-redux'
import { EditItem } from './EditItem'
import { withSnackbar } from 'notistack'
import AddUserModal from './addUserModal'
import MarkerMap from '../common/Maps'
import CustomDialog from '../common/Dialog'
import { addUser, removeUser, updateUser } from '../../Actions/Users'
import Button from '../common/Button'
import { withLocalize } from 'react-localize-redux'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './usersModal.scss'
import isEqual from 'react-fast-compare'
import { checkPrivileges, errorHandler, validEmailPattern } from '../../Helpers/index'
import Notifications from 'react-notification-system-redux'
import { checkUnitsWithStandardType } from './userDataTypes'
import Loader from '../../Layout/Loader'
import instance from '../../axios'
const userDataFormat = (data, extAttriubtes, itemId) => {
  if(data.roleId === 'defaultRole'){
    data.roleId=''
  }
  return {
    administrator: data.userType === 1 ? false : true,
    attributes: extAttriubtes ? extAttriubtes : {},
    coordinateFormat: data.coordinateFormat ? data.coordinateFormat : '',
    deviceLimit: data.deviceLimit,
    deviceReadonly:
      data.deviceReadonly === 'on' || data.deviceReadonly === true
        ? true
        : false,
    disabled: data.disabled === 'on' || data.disabled === true ? true : false,
    email: data.email.trim(),
    expirationTime: data.expirationTime ? data.expirationTime : null,
    id: itemId ? itemId : 0,
    latitude: data.latitude ? data.latitude : 0,
    limitCommands:
      data.limitCommands === 'on' || data.limitCommands === true ? true : false,
    login: data.login,
    longitude: data.longitude ? data.longitude : 0,
    map: data.map,
    name: data.name.trim(),
    password: data.password,
    phone: data.phone,
    poiLayer: data.poiLayer,
    readonly: data.readonly === 'on' || data.readonly === true ? true : false,
    token: data.token,
    twelveHourFormat:
      data.twelveHourFormat === 'on' || data.twelveHourFormat === true
        ? true
        : false,
    userLimit: data.userLimit,
    zoom: data.zoom ? data.zoom : 0,
    roleId: data.roleId ? data.roleId : data.userType === 1 ? 2 : 3,
    userType: parseInt(data.userType) || 1,
    parentId: data.parentId || 0
  }
}
const attributesDefaultValue = {
  distanceUnit: 'km',
  speedUnit: 'kmh',
  volumeUnit: 'ltr',
  measurementStandard: 'metric',
  currencyLabel: 'AED',
  viewIMEI:false,
  viewModel:false,
  unitsViewType:"default"
}
const formDefaultState = {
  name: '',
  email: '',
  attributes: { ...attributesDefaultValue },
  password: null,
  confirmPassword: null,
  phone: '',
  map: '',
  latitude: '',
  longitude: '',
  zoom: 12,
  twelveHourFormat: '',
  coordinateFormat: '',
  poiLayer: '',
  disabled: '',
  admin: '',
  readonly: '',
  deviceReadonly: '',
  limitCommands: '',
  deviceLimit: 0,
  userLimit: 0,
  token: '',
  expirationTime: null,
  userType: '',
  accountId: 0,
  parentId: 0
}

class usersModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      deleteProcess: false,
      selectedUser: '',
      addOption: false,
      editOption: false,
      u_attriubtes: '',
      selectedOption: false,
      passwordOption: false,
      selectedUserTrackesList: '',
      isSelectedUser: false,
      localTime: new Date(),
      directOption: false,
      isVisableUserBtn: false,
      isAttChanged: false,
      usesId: '',
      isMapModal: false,
      duplicateEntry: false,
      isVisableAddlocBtn: false,
      localPostionObj: '',
      selectedUsers: '',
      usersArray: [],
      itemAttributes: {},
      attributeChangesMessage: '',
      defaultAccountsList: [],
      defaultUsersList: [],
      accountSelectedData: '',
      userSelectedData: '',
      isUserRecivedList: true,
      // all fields of form
      form: {
        ...formDefaultState
      },
      lengthCheck :false,
      lowerCaseCheck:false,
      upperCaseCheck :false,
      numberCheck :false,
      characterCheck : false,
      confirmPasswordCheck : false
    }
    this.au_submit = this.au_submit.bind(this)
    this.uu_submit = this.uu_submit.bind(this)
    // this.editUser = this.editUser.bind(this);
    // this.removeUser = this.removeUser.bind(this);
    this.generateToken = this.generateToken.bind(this)
    this.modalControle = this.modalControle.bind(this)
    this.changeAttribute = this.changeAttribute.bind(this)
    this.timeDateOnchange = this.timeDateOnchange.bind(this)
    this.userSelection = this.userSelection.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.checkForAttrubutesChanged = this.checkForAttrubutesChanged.bind(this)
    this.changeAttribute2 = this.changeAttribute2.bind(this)
    this.timezone_handleChange = this.timezone_handleChange.bind(this)
  }

  componentWillMount () {
    let defaulfFeildsSetting = this.checkAttributes(this.props.logInUser)
    if (this.props.showItemDetail) {
      this.setState({
        selectedUser: this.props.selecteditem
      })
    } else {
      if (
        this.props.activeOperation === 'edit' &&
        (!this.state.editOption ||
          !isEqual(this.state.selectedUser, this.props.selecteditem))
      ) {
        this.setState({
          selectedUser: this.props.selecteditem,
          editOption: true,
          addOption: false,
          itemAttributes: this.props.selecteditem.attributes,
          attributeChangesMessage: '',
          isVisableUserBtn: false,
          isAttChanged: false,
          form: { ...formDefaultState, ...this.props.selecteditem }
        })
        this.updateUser(this.props.selecteditem)
      }
      if (this.props.activeOperation === 'changePassword' &&
      (!this.state.editOption ||
        !isEqual(this.state.selectedUser, this.props.selecteditem))
      ) {
        this.setState({ 
          selectedUser: this.props.selecteditem,
          form: { ...formDefaultState, ...this.props.selecteditem },
          editOption: true 
        })
      }
      if (this.props.activeOperation === 'delete') {
        this.deleteUser(this.props.selecteditem)
      }
      if (this.props.activeOperation === 'add' && !this.state.addOption) {
        //this.fetchAccountsList(this.props.logInUser.accountId)
        //this.fetchUsersList(this.props.logInUser.accountId)
        this.setState({
          selectedUser: '',
          addOption: true,
          editOption: false,
          itemAttributes: {},
          attributeChangesMessage: '',
          form: {
            ...formDefaultState,
            ...defaulfFeildsSetting
          }
        })
      }
    }
  }
  componentWillReceiveProps (nextprops) {
    if (nextprops.showItemDetail) {
      this.setState({
        selectedUser: nextprops.selecteditem
      })
    } else {
      if (
        nextprops.activeOperation === 'edit' &&
        (!this.state.editOption ||
          !isEqual(this.state.selectedUser, nextprops.selecteditem))
      ) {
        let formData = nextprops.selecteditem
        if (this.state.selectedUser.id === nextprops.selecteditem.id) {
          formData = this.state.selectedUser
        }
        this.setState({
          selectedUser: nextprops.selecteditem,
          editOption: true,
          addOption: false,
          isVisableUserBtn: false,
          isAttChanged: false,
          itemAttributes: nextprops.selecteditem.attributes,
          attributeChangesMessage: '',
          form: { ...formDefaultState, ...formData }
        })
        this.updateUser(nextprops.selecteditem)
      }
      if (
        nextprops.activeOperation === 'delete' &&
        this.state.deleteProcess === false
      ) {
        this.setState({ deleteProcess: true }, () => {
          this.deleteUser(nextprops.selecteditem)
        })
      }
      if (nextprops.activeOperation === 'changePassword' &&
        (!this.state.editOption ||
        !isEqual(this.state.selectedUser, nextprops.selecteditem))
        ) {
        this.setState({ 
          selectedUser: nextprops.selecteditem,
          form: { ...formDefaultState, ...nextprops.selecteditem },
          editOption: true 
        })
      }
      if (nextprops.activeOperation === 'add' && !this.state.addOption) {
        // this.fetchAccountsList(nextprops.logInUser.accountId)
        // this.fetchUsersList(nextprops.logInUser.accountId)
        let defaulfFeildsSetting = this.checkAttributes(nextprops.logInUser)
        this.setState({
          selectedUser: '',
          attributeChangesMessage: '',
          addOption: true,
          editOption: false,
          itemAttributes: {},
          form: {
            ...formDefaultState,
            ...defaulfFeildsSetting
          }
        })
      }
    }
    if(nextprops.resourceList){
          this.modalControle()
        }
  }
  fetchAccountsList = id => {
    // let fetchApi = `/api/accounts/all?accountId=${id}&limit=100`
    // if (this.props.logInUser.userType === -1) {
    //   fetchApi = `/api/accounts/all?accountId=${id}&all=true&limit=100`
    // }
    let params = {
      accountId: id,
      limit: 100
    }
    if (this.props.logInUser.userType === -1) {
      params = {
        ...params,
        all: true
      }
    }
    // fetch(fetchApi, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/accounts/all`,
      method: 'GET',
      params: {
        ...params
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(userlist => {
          let result =
            userlist.data &&
            userlist.data.map(item => ({
              ...item,
              label: item.label || item.name,
              value: item.id
            }))
          result.unshift({
            id: this.props.ServerSetting.id,
            label: this.props.ServerSetting.name,
            value: this.props.ServerSetting.id
          })
          this.setState({
            defaultAccountsList: result
          })
      //   })
      // }
      // else{
      //   throw response
      // }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }
  fetchUsersList = id => {
    // let fetchApi = `/api/users/list?accountId=${id}&limit=100`
    // fetch(fetchApi, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/users/list`,
      method: 'GET',
      params: {
        accountId: id,
        limit: 100
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(userlist => {
          let result =
            userlist.data &&
            userlist.data.map(item => ({
              ...item,
              label: item.label || item.name,
              value: item.id
            }))
          if (parseInt(id) === parseInt(this.props.ServerSetting.id)) {
            result.unshift({
              id: this.props.logInUser.id,
              label: this.props.logInUser.name,
              value: this.props.logInUser.id
            })
          }
          this.setState({
            defaultUsersList: result,
            isUserRecivedList: true
          })
      //   })
      // }
      // else{
      //   throw response
      // }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }
  checkAttributes = data => {
    let form = {
      coordinateFormat: data.coordinateFormat,
      poiLayer: data.poiLayer,
      latitude: data.latitude,
      longitude: data.longitude,
      zoom: data.zoom,
      attributes: {
        'web.liveRouteLength':
          data.attributes && data.attributes['web.liveRouteLength'],
        'web.maxZoom': data.attributes && data.attributes['web.maxZoom'],
        'web.selectZoom': data.attributes && data.attributes['web.selectZoom'],
        timezone: data.attributes && data.attributes.timezone,
        distanceUnit: data.attributes && data.attributes.distanceUnit,
        speedUnit: data.attributes && data.attributes.speedUnit,
        volumeUnit: data.attributes && data.attributes.volumeUnit,
        measurementStandard: data.attributes && data.attributes.measurementStandard,
        viewIMEI:data.attributes && data.attributes.viewIMEI,
        viewModel:data.attributes && data.attributes.viewModel,
        unitsViewType:data?.attributes.unitsViewType?data?.attributes.unitsViewType:"default"
      }
    }
    return form
  }
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

    if (name === 'measurementStandard') {
      let data = checkUnitsWithStandardType(value)
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.target.type === 'number' ? parseInt(value) : value,
              distanceUnit: data[0].distanceUnit.key,
              speedUnit: data[0].speedUnit.key,
              volumeUnit: data[0].volumeUnit.key
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  timezone_handleChange = (name, value) => {
    if (name === 'timezone') {
      if (!value || value.value === 'None') {
        delete this.state.form.attributes[name]
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes
              }
            }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]: value.value
              }
            }
          },
          () => this.checkRequiredFields()
        )
      }
    } else {
      if (name === 'accountId') {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value.value,
              userId: ''
            },
            accountSelectedData: value,
            userSelectedData: '',
            isUserRecivedList: false
          },
          () => {
            this.fetchUsersList(value.value)
            this.checkRequiredFields()
          }
        )
      } else if (name === 'parentId') {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value.value
            },
            userSelectedData: value
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value.value
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }
  }

  au_submit () {
    this.setState(
      {
        loading: true
      },
      () => {
        let { name, email, password, attributes } = this.state.form
        if (
          name.trim().length > 0 &&
          email.trim().length > 0 &&
          password.trim().length > 0
        ) {
          this.setState({
            selectedUser: ''
          })
          const obj = userDataFormat(this.state.form, attributes)
          // fetch(`/api/users/`, {
          //   method: 'POST',
          //   headers: {
          //     Accept: 'application/json',
          //     'Content-Type': 'application/json'
          //   },
          //   body: JSON.stringify({
          //     ...obj
          //   })
          // })
          instance({
            url: `/api/users/`,
            method: 'POST',
            data: {
              ...obj
            }
          })
            // .then(response => {
            //   if (response.ok) {
            //     response.json()
                .then(user => {
                  let parent = {
                    parentId: this.props.logInUser.id,
                    parentName: this.props.logInUser.name
                  }
                  user = { ...user, ...parent }

                  this.props.fetchUserLimit(false)

                  this.props.dispatch(addUser(user))
                  //this.props.enqueueSnackbar(this.props.translate('userIsCreated'), { autoHideDuration: 1000 });
                  this.props.dispatch(
                    Notifications.success({
                      message: this.props.translate('userIsCreated'),
                      autoDismiss: 10
                    })
                  )
                  this.setState(
                    {
                      addOption: false,
                      u_attriubtes: '',
                      selectedUser: '',
                      isVisableUserBtn: false,
                      isAttChanged: false,
                      attributeChangesMessage: '',
                      loading: false,
                      form: {
                        ...formDefaultState
                      }
                    },
                    () => {
                      this.props.history.push('/users/' + user.id + '#device')
                    }
                  )
                })
            //   } else {
            //     throw response
            //   }
            // })
            .catch(error => {
              errorHandler(error, this.props.dispatch, this.errorCallBack)
            })
            // .catch(e => {
            //   this.setState({
            //     loading: false
            //   })
            //   if (e) {
            //     if (e && e.text) {
            //     e.text().then(err => {
            //       if (err.includes('Duplicate entry')) {
            //         this.setState({
            //           duplicateEntry: true,
            //           isVisableUserBtn: false
            //         })
            //         this.props.dispatch(
            //           Notifications.error({
            //             message: this.props.translate(
            //               'userEmailIsAllreadyInUsed'
            //             ),
            //             autoDismiss: 5,
            //             location: this.props.location
            //           })
            //         )
            //       } else {
            //         let a = err.split('- SecurityException').splice(0, 1)
            //         this.props.dispatch(
            //           Notifications.error({
            //             message: a[0],
            //             autoDismiss: 10
            //           })
            //         )
            //       }
            //     })
            //   }
            //   } else {
            //     this.props.dispatch(
            //       Notifications.error({
            //         message: this.props.translate('somthingWentWrongMessage'),
            //         autoDismiss: 5,
            //         location: this.props.location
            //       })
            //     )
            //   }
            // })
        } else {
          this.setState(
            {
              isVisableUserBtn: false,
              isAttChanged: false
            },
            () => {
              //this.props.enqueueSnackbar(this.props.translate('emptyField'), { autoHideDuration: 1000 });
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate('emptyField'),
                  autoDismiss: 10
                })
              )
            }
          )
        }
      }
    )
  }
  errorCallBack = (data) => {
    this.setState({
      duplicateEntry: true,
      isVisableUserBtn: false,
      loading: false
    })
}

// goToLogoutpage = () => {
//   localStorage.removeItem('backtoAdmin')
//   this.props.history.push('/logout')
// }
  uu_submit () {
    const { selectedUser } = this.state
    const { attributes } = this.state.form
    let { name, email } = this.state.form
    if (name.trim().length > 0 && email.trim().length > 0) {
      let itemId = selectedUser.id

      const obj = userDataFormat(this.state.form, attributes, itemId)

      if (this.state.form && !this.state.form.password) {
        delete this.state.form.password
      }

      // fetch(`/api/users/${itemId}`, {
      //   method: 'PUT',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/users/${itemId}`,
        method: 'PUT',
        data: {
          ...obj
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(user => {
              let parent = {
                parentId: this.props.logInUser.id,
                parentName: this.props.logInUser.name
              }
              user = { ...user, ...parent }

              this.props.dispatch(updateUser(user))
              this.props.getCurrentUser(user.id)
              this.userSelection(user)
              //this.props.enqueueSnackbar(this.props.translate('userIsUpdated'), { autoHideDuration: 1000 });
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('userIsUpdated'),
                  autoDismiss: 10
                })
              )
              this.props.fetchUserLimit(false)
              this.setState({
                //editOption: false,
                //u_attriubtes: '',
                //selectedUser: '',
                //form: '',
                isVisableUserBtn: false,
                isAttChanged: false,
                attributeChangesMessage: ''
              })
            })
        //   } else {
        //     throw response
        //   }
        // })
        .catch(error => {errorHandler(error, this.props.dispatch)})
        // .catch(e => {
          // if (e && e.text) {
          //   e.text().then(err => {
          //     let a = err.split('- SecurityException').splice(0, 1)
          //     //alert(a[0])
          //     this.props.dispatch(
          //       Notifications.error({
          //         message: a[0],
          //         autoDismiss: 10
          //       })
          //     )

              /* if (err.includes("Duplicate entry")) {
              this.setState({
                duplicateEntry: true
              })
              alert("User Email is Allready in Used")
            } */
        //     })
        //   }
        // })
    } else {
      this.setState({
        isVisableUserBtn: false,
        isAttChanged: false
      })
      //this.props.enqueueSnackbar(this.props.translate('emptyField'), { autoHideDuration: 1000 });
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('emptyField'),
          autoDismiss: 10
        })
      )
    }
  }
  deleteUser (selectedUser) {
    if (window.confirm('Are you Sure to Delete')) {
      if (selectedUser.id) {
        // fetch(`/api/users/${selectedUser.id}`, {
        //   method: 'DELETE',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({
        //     ...selectedUser
        //   })
        // })
        instance({
          url: `/api/users/${selectedUser.id}`,
          method: 'DELETE',
          data: {
            ...selectedUser
          }
        })
          .then(response => {
            // if (response.ok) {
              this.props.dispatch(removeUser(selectedUser))
              //this.props.enqueueSnackbar(this.props.translate('userIsRemoved'), { autoHideDuration: 1000 });
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('userIsRemoved'),
                  autoDismiss: 10
                })
              )
              this.props.onCloseModal()
              this.setState({
                deleteProcess: false,
                selectedUser: '',
                attributeChangesMessage: '',
                isAttChanged: false
              })
            // } else {
            //   throw response
            // }
          })
          .catch(error => {errorHandler(error, this.props.dispatch)})
      }
    }
  }

  changeAttribute (name, value, selectedOption, updateAtt) {
    if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
      this.setState(
        {
          u_attriubtes: { ...updateAtt }
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      )
    } else {
      this.setState(
        {
          u_attriubtes: { ...this.state.selectedUser.attributes, [name]: value }
        },
        () => this.checkForAttrubutesChanged()
      )
    }
  }
  checkForAttrubutesChanged (selectedOption) {
    let changed = false
    if (this.state.selectedUser) {
      changed = !isEqual(
        this.state.u_attriubtes,
        this.state.selectedUser.attributes
      )
    } else {
      changed = !this.isEmpty(this.state.u_attriubtes)
    }
    let { name, email } = this.state.form
    let selectedUser = {}
    if (this.state.editOption) {
      if ((name && email) || (name && email && changed)) {
        selectedUser = this.state.u_attriubtes
        if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
          this.setState({
            isVisableUserBtn: changed,
            isAttChanged: changed,
            itemAttributes: { ...selectedUser }
          })
        } else {
          this.setState({
            isVisableUserBtn: changed,
            isAttChanged: changed,
            itemAttributes: { ...this.state.itemAttributes, ...selectedUser }
          })
        }
      } else {
        selectedUser = this.state.u_attriubtes
        if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
          this.setState({
            isVisableUserBtn: false,
            isAttChanged: false,
            itemAttributes: { ...selectedUser }
          })
        } else {
          this.setState({
            isVisableUserBtn: false,
            isAttChanged: false,
            itemAttributes: { ...this.state.itemAttributes, ...selectedUser }
          })
        }
      }
    } else {
      if ((name && email) || (name && email && changed)) {
        selectedUser = this.state.u_attriubtes
        if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
          this.setState(
            {
              isVisableUserBtn: true,
              isAttChanged: true,
              itemAttributes: { ...selectedUser }
            },
            () => this.checkRequiredFields()
          )
        } else {
          this.setState(
            {
              isVisableUserBtn: true,
              isAttChanged: true,
              itemAttributes: { ...this.state.itemAttributes, ...selectedUser }
            },
            () => this.checkRequiredFields()
          )
        }
      } else {
        selectedUser = this.state.u_attriubtes
        if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
          this.setState(
            {
              isVisableUserBtn: false,
              isAttChanged: false,
              itemAttributes: { ...selectedUser }
            },
            () => this.checkRequiredFields()
          )
        } else {
          this.setState(
            {
              isVisableUserBtn: false,
              isAttChanged: false,
              itemAttributes: { ...this.state.itemAttributes, ...selectedUser }
            },
            () => this.checkRequiredFields()
          )
        }
      }
    }

    if (changed) {
      this.setState({
        attributeChangesMessage: 'Attributes changed in pending'
      })
    } else {
      this.setState({
        attributeChangesMessage: ''
      })
    }
  }
  isEmpty = obj => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }
  modalControle () {
    this.setState({
      addOption: false,
      editOption: false,
      form: '',
      isVisableUserBtn: false,
      isAttChanged: false,
      attributeChangesMessage: ''
    })
    this.props.onCloseModal()
  }
  addUser () {
    this.setState({
      selectedUser: '',
      itemAttributes: {},
      addOption: true
    })
  }
  updateUser () {
    this.setState({
      editOption: true
    })
  }

  userSelection (item) {
    this.setState({
      selectedUser: item,
      u_attriubtes: item.attributes,
      form: { ...item }
    })
  }
  setExpirationTime = e => {
    e.stopPropagation()
    if (this.state.form.expirationTime) {
      this.setState(
        {
          form: {
            ...this.state.form,
            expirationTime: null
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  copyTokenToClipboard = () => {
    const { form } = this.state;
    const token = form.token;

    const hostUrl = this.props.ServerSetting?.attributes?.hostUrl || '';
    const tokenUrl = `${hostUrl}/loginasuser?&${btoa(token)}`;

    // Create a temporary textarea element to copy the token to clipboard
    const tempTextarea = document.createElement('textarea');
     
    tempTextarea.value = this.props.viewScreen === 'edit' ? tokenUrl : token;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);

    // Show a notification indicating that the token has been copied to clipboard
    this.props.enqueueSnackbar('Token copied to clipboard', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };
  generateToken () {
    let symbols =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i,
      newToken = ''
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          token: newToken
        }
      },
      () => this.checkRequiredFields()
    )
  }
  timeDateOnchange (date) {
    this.setState(preState => ({
      ...preState,
      form: {
        ...preState.form,
        timeDate: date
      }
    }))
  }
  handleChange = name => event => {
    const { target } = event
    if (target && target.type === 'checkbox') {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked
          }
        },
        () => this.checkRequiredFields()
      )
    } else if (name === 'expirationTime') {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString()
          }
        },
        () => this.checkRequiredFields()
      )
    } else if (name === 'deviceLimit' || name === 'userLimit') {
      let value = target.value
       if (value < -1) {
        value = -1
      }

      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.checkRequiredFields()
      )
    }else if(name === 'password' || name === "confirmPassword"){
      let value = target.value
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.verifyPassword()
      )

    }
    else {
      let value = target.value
      if (target.type === 'number') {
        value = parseFloat(value)
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  verifyPassword = () => {
    // Define regular expressions for password criteria
    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    // Check each criteria
    const isLengthValid = this.state.form.password?.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(this.state.form.password);
    const hasUpperCaseValid = hasUpperCase.test(this.state.form.password);
    const hasNumberValid = hasNumber.test(this.state.form.password);
    const hasSpecialCharValid = hasSpecialChar.test(this.state.form.password);

    // Check if all criteria are met
    if(isLengthValid){
      this.setState({lengthCheck :  true})
    }else{
      this.setState({lengthCheck :false})
    }
    if(hasLowerCaseValid){
      this.setState({lowerCaseCheck:  true})
    }else{
      this.setState({lowerCaseCheck:false })
    }
    if(hasUpperCaseValid){
      this.setState({upperCaseCheck :  true})
    }else{
      this.setState({upperCaseCheck :false })
    }
    if(hasNumberValid){
      this.setState({ numberCheck : true})
    }else{
      this.setState({numberCheck :false })
    }
    if(hasSpecialCharValid){
      this.setState({ characterCheck : true})
    }else{
      this.setState({ characterCheck : false})
    }
    if(this.state.form.password === this.state.form.confirmPassword){
      this.setState({ confirmPasswordCheck : true})
    }else{
      this.setState({ confirmPasswordCheck : false})
    }

    const isPasswordValid =
      isLengthValid && hasLowerCaseValid && hasUpperCaseValid && hasNumberValid && hasSpecialCharValid;
   

    this.setState({ 
      isValidPassword: isPasswordValid },
      () => this.checkRequiredFields());
  };

  checkRequiredFields () {
    let { zoom } = this.state.form
    let { name, email, password, confirmPassword, userType } = this.state.form
    let value = !isEqual(this.state.form, this.state.selectedUser)
    let valid = true

    if (this.state.editOption) {
      if(
        password &&
        confirmPassword &&
        password === confirmPassword)
        {
          this.setState({
            isVisableUserBtn: valid,
            duplicateEntry: !valid
          })
      }
      else if (name && email && userType && value && password === confirmPassword) {
        if (!validEmailPattern.test(email)) {
          valid = false
        }
        this.setState({
          isVisableUserBtn: valid,
          duplicateEntry: !valid
        })
      } else {
        this.setState({
          isVisableUserBtn: !this.state.isAttChanged,
          duplicateEntry: false
        })
      }
    } else {
      if (
        name &&
        email &&
        userType &&
        password &&
        confirmPassword &&
        password === confirmPassword &&
        value && zoom > 2 && zoom < 19
      ) {
        if (!validEmailPattern.test(email)) {
          valid = false
        }
        this.setState({
          isVisableUserBtn: valid,
          duplicateEntry: !valid
        })
      } else {
        this.setState({
          isVisableUserBtn: this.state.isAttChanged,
          duplicateEntry: false
        })
      }
    }
  }

  /*   fatchUsers = id => {
    if (id) {
      fetch(`/api/users?userId=${id}`).then(response => {
        if (response.ok) {
          response.json().then(users => {
            this.props.dispatch(getUsers(users))
            this.setState({
              usesId: this.props.logInUser.id,
              superUser: false
            })
          })
        }
      })
    }
  } */

  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false
    })
  }
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true
      })
    }
  }
  setSelectedLocation = () => {
    const { localPostionObj } = this.state
    if (localPostionObj) {
      this.setState(
        {
          form: {
            ...this.state.form,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
            zoom: localPostionObj.zoom
          },
          isMapModal: false
        },
        () => this.checkRequiredFields()
      )
    }
  }
  onCloseModal = () => {
    this.setState({
      isMapModal: false
      // afterCreateUserWizard: false
    })
  }
  shouldComponentUpdate (nextprops, nextstate) {
    return !isEqual(nextprops, this.props) || !isEqual(nextstate, this.state)
  }
  render () {
    const { selectedUser, loading } = this.state
    const { logInUser } = this.props
    return (
      <section style={{ width: '100%' }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {this.props.viewScreen === 'add' ? (
              <>
                {' '}
                {this.state.isUserRecivedList ? (
                  <AddUserModal
                    formSubmit={this.au_submit}
                    {...this.state}
                    {...this.props}
                    timeDateOnchange={this.timeDateOnchange}
                    generateToken={this.generateToken}
                    copyTokenToClipboard={this.copyTokenToClipboard}
                    modalControle={this.modalControle}
                    changeAttribute={this.changeAttribute}
                    handleChange={this.handleChange}
                    buttonText={this.props.translate('sharedCreate')}
                    handleClickShowPostion={this.handleClickShowPostion}
                    setExpirationTime={this.setExpirationTime}
                    btnCancelNotShow={false}
                    changeAttribute2={this.changeAttribute2}
                    isHaveAccess={'userCreate'}
                    canUpdate={checkPrivileges('userCreate')}
                    userType={logInUser.userType}
                    timezone_handleChange={this.timezone_handleChange}
                  />
                ) : (
                  <Loader />
                )}
              </>
            ) : null}
            {this.props.viewScreen === 'edit' ? (
              <>
              <AddUserModal
                 {...this.state}
                 lengthCheck={this.state.lengthCheck}
                lowerCaseCheck={this.state.lowerCaseCheck}
                upperCaseCheck={this.state.upperCaseCheck}
                numberCheck={this.state.numberCheck}
                characterCheck={this.state.characterCheck}
                isValidPassword={this.state.isValidPassword}
                confirmPasswordCheck={this.state.confirmPasswordCheck}
                viewScreen={this.props.viewScreen}
                selectedUser={selectedUser}
                activeOperation={this.props.activeOperation}
                formSubmit={this.uu_submit}
                token={this.state.token}
                timeDate={this.state.timeDate}
                timeDateOnchange={this.timeDateOnchange}
                generateToken={this.generateToken}
                copyTokenToClipboard={this.copyTokenToClipboard}

                modalControle={this.modalControle}
                changeAttribute={this.changeAttribute}
                handleChange={this.handleChange}
                form={this.state.form}
                editOption={this.state.editOption}
                buttonText={this.props.translate('sharedUpdate')}
                isVisableUserBtn={this.state.isVisableUserBtn}
                handleClickShowPostion={this.handleClickShowPostion}
                duplicateEntry={this.state.duplicateEntry}
                setExpirationTime={this.setExpirationTime}
                itemAttributes={this.state.itemAttributes}
                attributeChangesMessage={this.state.attributeChangesMessage}
                btnCancelNotShow={true}
                changeAttribute2={this.changeAttribute2}
                isHaveAccess={'userUpdate'}
                canUpdate={checkPrivileges('userUpdate')}
                renderForm={this.props.renderForm}
                ServerSetting={this.props.ServerSetting}
                userType={logInUser.userType}
                timezone_handleChange={this.timezone_handleChange}
              />
              </>
            ) : null}
            {this.state.viewScreen === 'view' ? (
              <EditItem
                selectedItem={this.state.selectedUser}
                handleClose={this.modalControle}
                form={this.state.form}
              />
            ) : null}
            {this.state.isMapModal && (
              <CustomDialog
                title={this.props.translate('setLocation')}
                themecolors={this.props.themecolors}
                visable={true}
                onClose={this.onCloseModal}
                isVisableBtn={true}
                draggable={true}
                CancelText={this.props.translate('sharedBack')}
                headerActions={
                  <Button
                    size='small'
                    variant='contained'
                    color='secondary'
                    disabled={!this.state.isVisableAddlocBtn}
                    onClick={() => this.setSelectedLocation()}
                  >
                    {' '}
                    {this.props.translate('sharedAdd')}
                  </Button>
                }
                bodyPadding={0}
              >
                <MarkerMap
                  getPostion={this.getPostion}
                  minHeight={500}
                  zoom={this.state.selectedUser.zoom || 0}
                  lat={this.state.selectedUser.latitude || 0}
                  lng={this.state.selectedUser.longitude || 0}
                  height={'100%'}
                />
              </CustomDialog>
            )}
          </>
        )}
      </section>
    )
  }
}

const mapState = state => {
  return {
    users: state.users,
    logInUser: state.logInUsers
  }
}
const mapStateToProps = connect(mapState)
export const UsersModal = mapStateToProps(
  withSnackbar(withLocalize(usersModal))
)
