import React, { Component } from 'react'
import TextField from './TextField'
import SearchField from './SearchField'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import 'bootstrap-daterangepicker/daterangepicker.css'
import Style from 'style-it'
import { connect } from 'react-redux'

class CustomDateRangePicker extends Component {
  render () {

    const singleDay = {
      Today: [moment().startOf('day'), moment().endOf('day')],
    }
    const ranges = {
      Yesterday: [
        moment()
          .subtract(1, 'days')
          .startOf('day'),
        moment()
          .subtract(1, 'days')
          .endOf('day')
      ],
      'Last 7 Days': [
        moment()
          .subtract(7, 'days')
          .startOf('day'),
        moment().startOf('day')
      ],
      'Last 30 Days': [
        moment()
          .subtract(30, 'days')
          .startOf('day'),
        moment().startOf('day')
      ],
      'This Month': [
        moment()
          .startOf('month')
          .startOf('day'),
        moment()
          .endOf('month')
          .endOf('day')
      ],
      'Last Month': [
        moment()
          .subtract(1, 'month')
          .startOf('month')
          .startOf('day'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
          .endOf('day')
      ]
    }

    const rangesReport = {
      Today: [moment().startOf('day'), moment().endOf('day')],
      Yesterday: [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day"),
      ],
      "Previous 7 Days": [
        moment().subtract(7, "days").startOf("day"),
        moment()
          .subtract(1, "days")
          .endOf("day")
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
      ],
      "Previous 30 Days": [
        moment().subtract(30, "days").startOf("day"),
        moment()
          .subtract(1, "days")
          .endOf("day")
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
      ],
      // 'This Month': [
      //   moment()
      //     .startOf('month')
      //     .startOf('day'),
      //   moment()
      //     .endOf('month')
      //     .endOf('day')
      // ],
      "Previous Month": [
        moment().subtract(1, "month").startOf("month").startOf("day"),
        moment().subtract(1, "month").endOf("month").endOf("day"),
      ],
    };
    if (this.props.fill) {
      return (
        <>
          <Style>
            {`
            .daterangepicker td.in-range {
               background: ${this.props.themecolors[100]};
            }
            .daterangepicker td.active, .daterangepicker td.active:hover,
            .daterangepicker .ranges li.active {
               background: ${this.props.themecolors.themeLightColor};
               color: ${this.props.themecolors.themeLightInverse};
            }
            `}
          </Style>
          <DateRangePicker
            containerStyles={{}}
            {...this.props}
            key={1}
            ranges={this.props.todayOnly ? singleDay : this.props.reportsRanges ? rangesReport : ranges}
            // ranges={this.props.trip ?[]: ranges}
            showCustomRangeLabel={!this.props.todayOnly}

            timePicker={this.props.datepickerOnly !== false}
            timePicker24Hour={this.props.datepickerOnly !== false}
            // maxDate={!this.props.trip && ( moment(new Date()) )}
            // minDate={this.props.trip && ( moment(new Date()))}
          >
            <SearchField
              error={this.props.error}
              fullWidth
              margin='dense'
              label={this.props.label}
              value={this.props.selectedDate}
            />
          </DateRangePicker>
        </>
      )
    } else {
      return (
        <>
          <Style>
            {`
            .daterangepicker td.in-range {
               background: ${this.props.themecolors[100]};
            }
            .daterangepicker td.active, .daterangepicker td.active:hover,
            .daterangepicker .ranges li.active {
               background: ${this.props.themecolors.backgroundColor};
               color: ${this.props.themecolors.textColor};
            }
            `}
          </Style>

          <DateRangePicker
            containerStyles={{}}
            {...this.props}
            ranges={ranges}
            timePicker={this.props.datepickerOnly !== false}
            timePicker24Hour={this.props.datepickerOnly !== false}
          >
            <TextField
              fullWidth
              error={this.props.error}
              margin='dense'
              label={this.props.label || 'Select Date'}
              value={this.props.selectedDate}
            />
          </DateRangePicker>
        </>
      )
    }
  }
}

const mapStateToProps = state => ({
  themecolors: state.themeColors
})

export default connect(mapStateToProps)(CustomDateRangePicker)
