function parepareResponse (arr, old) {
  let gf = []
  arr.map(item => {
    let circle = []
    let polygon = []
    let polyline = []
    let kmlFile =[]

    const d = old.find(e => e.id === item.id)

    const common = {
      ...item,
      visible: d && d.visible !== undefined ? d.visible : false,
      attributes: { ...item.attributes, latlng: [] }
    }

    polyline = item.area.split('LINESTRING ')

    if (polyline.length > 1) {
      let p = polyline[1]
        .trim()
        .replace('(', '')
        .replace(')', '')
        .split(', ')


      let positions = []
      if (p.length) {
        p.map(pos => {
          positions.push(pos.split(' ').map(Number))
          return null
        })
      }

      gf.push({
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#000',
          type: 'polyline',
          latlng: positions
        }
      })
    }

    kmlFile = item.area.split('KML ')
    if (kmlFile.length > 1) {
      let p = kmlFile[1]
        .trim()
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .replace(/[()]/g, '') // Remove parentheses
        .split(', ')
        
      let positions = []
      if (p.length) {
        p.forEach(pos => {
          let [lat, lng] = pos.split(' '); // Split each pair by space
          positions.push([parseFloat(lat), parseFloat(lng)]);
        });
      }
    
      gf.push({
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#0a83a1',
          type: 'kmlFile',
          latlng: positions
        }
      })
    }
    
    
    
    
    
    circle = item.area.split('CIRCLE')

    if (circle.length > 1) {
      let c = circle[1]
        .trim()
        .replace('(', '')
        .replace(')', '')
      let a = c.split(', ')
      let b = a[0].split(' ')

      gf.push({
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#000',
          type: 'circle',
          latlng: { lat: b[0], lng: b[1] },
          radius: parseFloat(a[1])
        }
      })
    }

    polygon = item.area.split('POLYGON')

    if (polygon.length > 1) {
      let poly = {
        ...common,
        attributes: {
          ...common.attributes,
          color: common.attributes.color || '#000',
          type: 'polygon'
        }
      }

      let a = polygon[1].replace('((', '').replace('))', '')
      let b = a.split(', ')

      b.forEach(obj => {
        let c = obj.split(' ')
        poly.attributes.latlng.push([parseFloat(c[0]), parseFloat(c[1])])
      })

      gf.push(poly)
    }
    return item
  })
  return gf
}


export const geoFence = (state = [], action) => {
  const items = []

  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'UPDATE_GEOFANEC':
      state.map(geo => {
        if (action.geofence.id === geo.id) {
          items.push(action.geofence)
        } else {
          items.push(geo)
        }
        return ''
      })

      return items

    case 'DELETE_GEOFENCE':
      var array = []
      state.map((geofence, index) => {
        if (geofence.id !== action.geofence.id) {
          array.push(geofence)
        }
        return ''
      })

      return array

    case 'ADD_GEOFANEC':
      return state.concat(parepareResponse(action.geofence, state))

    case 'GET_GEOFANEC':
      return parepareResponse(action.geofence, state)

    case 'GEO_FENSE_VISIBLE':
      state.map(obj => {
        const o = { ...obj }
        if (o.id === action.payload.id) {
          o.visible = action.payload.visible
        }
        items.push(o)
        return obj
      })
      return items

    case 'TOGGLE_GEOFENCES':
        const { obj, type, arr } = action.payload;
  
        // Create a map for quick lookup of geofence IDs in arr
        const arrMap = new Map(arr?.map(geofence => [geofence.id, geofence]));
        // Update the visibility of geofences based on presence in arr
        return state?.map(geofence => {
          let isVisible = geofence.visible; // Default to current visibility
          if (arrMap.has(geofence.id)) {
            if (type === "geofences") {
              isVisible = geofence.type !== "kmlFile" ? obj : false;
            } else if (type === "tracks") {
              isVisible = geofence.type === "kmlFile" ? obj : false;
            }
          }
          return { ...geofence, visible: isVisible };
        });

    default:
      return state
  }
}
export const isGeofenceLoad = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_GEOFENCE':
    return action.loadGeofence
    default:
      return state
  }
}
