import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Layout from '../../Layout'
import { withSnackbar } from 'notistack'
import { removeUser } from './../../Actions/Users'
import { getGroups } from './../../Actions/Groups'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import Notifications from 'react-notification-system-redux'
import MainUnitModal from './MainUnitModal'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'

import EmptyState from '../../Components/common/EmptyState'
import { UsersModal } from './../../Components/Users/usersModal'
import { checkPrivileges, errorHandler } from '../../Helpers/index'
import './Style.scss'
import instance from '../../axios'
import { getAreas } from '../../Actions/Areas'

const deviceDataFormat = {
  id: '',
  attributes: '',
  name: '',
  uniqueId: '',
  status: '',
  disabled: '',
  lastUpdate: '',
  positionId: '',
  groupId: '',
  phone: '',
  model: '',
  contact: '',
  category: '',
  geofenceIds: '',
  devicePassword: ''
}

class Units extends Component {
  constructor (props) {
    super(props)
    this.state = {
      viewScreen: null,
      // new
      userLimit: false,
      resFormData: { ...deviceDataFormat },
      selecteditem: '',
      remainings: {},
      remainingsDevice: {},
      limitFetech: false,
      resourceList: false,
      editResource: false,
      areas: '',
      users: [],
      searchText: ''

      /* initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      } */
    }
    // new
    this.removedItem = this.removedItem.bind(this)
    this.addNewUser = this.addNewUser.bind(this)
    this.fetchUserLimit = this.fetchUserLimit.bind(this)
  }

  // General From

  addNewUser () {
    this.props.history.push('/addUser')
  }
  removedItem = () => {
    const { selectedItem } = this.state
    if (selectedItem.id) {
      // fetch(`/api/users/${selectedItem.id}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...selectedItem
      //   })
      // })
      instance({
        url: `/api/users/${selectedItem.id}`,
        method: 'DELETE'
      })
        .then(response => {
          // if (response.ok) {
            this.props.dispatch(removeUser(selectedItem))
            //this.props.enqueueSnackbar(this.props.translate('userIsRemoved'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('userIsRemoved'),
                autoDismiss: 10
              })
            )
            this.setState({
              onDeleteConfirmation: false
            })
            this.props.history.push('/users')
            this.fetchUsers(this.state.searchText,this.props.users2)
            this.onCancel()
          // } else {
          //   throw response
          // }
        })
        .catch(error => {errorHandler(error,this.props.dispatch)})
          // if (e && e.text) {
          //   e.text().then(err => {
          //       var array = err.split("-")
          //       if(array.length > 1){
          //             this.props.dispatch(
          //               Notifications.error({
          //                 message: this.props.translate(array[0]),
          //                 autoDismiss: 5,
          //                 location: this.props.location
          //               })
          //             )             
          //       }
          //       else {
          //           this.props.dispatch(
          //             Notifications.error({
          //               message: this.props.translate('somthingWentWrongMessage'),
          //               autoDismiss: 5,
          //               location: this.props.location
          //             })
          //           )
          //       }
          //       this.setState({
          //         onDeleteConfirmation: false
          //       })
          //   })
          // }
        
    }
  }
  showResources = () => {
      this.setState({
        resourceList: !this.state.resourceList
      })
      if(this.state.resourceList){
        this.setState({
          editResource: false
        })
      }
    }
    addResource = () =>{
      this.setState({
        editResource: true,
        selectedResourse: '',
        viewScreen: null
      })
    }
    onEditResource = (item) =>{
      this.setState({
        editResource: true,
        selectedResourse: item
      })
    }
    onCloseResource = () =>{
      this.setState({
        editResource: false
      })
    }

  onCloseModal = () => {
    this.setState({
      viewScreen: ''
    })
    this.props.history.push('/users')
  }

  fetchUserLimit (value) {
    this.setState({ limitFetech: value, remainings: {} }, () => {
      this.checkUserLimit()
    })
  }

  // this call from render where check the limit of users
  checkUserLimit = () => {
    if (checkPrivileges('userCreate')) {
      if (
        this.props.logInUsers &&
        this.props.logInUsers.id &&
        !Object.keys(this.state.remainings).length &&
        !this.state.limitFetech
      ) {
        if (this.props.logInUsers.userLimit !== -1) {
          this.setState({ limitFetech: true }, () => {
            // fetch(`/api/users/count?userId=${this.props.logInUsers.id}`, {
            //   method: 'GET',
            //   headers: {
            //     Accept: 'application/json',
            //     'Content-Type': 'application/json'
            //   }
            // })
            instance({
              url: `/api/users/count`,
              method: 'GET',
              params:{
                userId: this.props.logInUsers.id
              }
            })
            // .then(response => {
            //   if (response.ok) {
            //     response.json()
                .then(params => {
                  this.setState({
                    remainings: params,
                    userLimit: params.remaningLimit
                  })
              //   })
              // }
              // else{
              //   throw response
              // }
            }).catch(error => {
              // errorHandler(error,this.props.dispatch)
            })
          })
        } else {
          this.setState({
            userLimit: true
            //remainings: {'allset': true}
          })
        }
      } else if (
        this.props.logInUsers &&
        this.props.logInUsers.userLimit === -1
      ) {
        this.setState({
          userLimit: true
          //remainings: {'allset': true}
        })
      }
    } else {
      this.setState({
        userLimit: false,
        limitFetech: true
      })
    }
  }

  /* fetchRoles = () => {
    if (checkPrivileges('role') && !this.state.userRolePropess) {
      this.setState(
        {
          userRolePropess: true
        },
        () => {
          if (this.props.logInUsers.userType === -1) {
            fetch(`/api/roles?all=true`).then(response => {
              if (response.ok) {
                response.json().then(role => {
                  this.setState({
                    roleTypes: role
                  })
                })
              }
            })
          } else if (this.props.logInUsers.userType !== 1) {
            fetch(`/api/roles?userId=${this.props.logInUsers.id}`).then(
              response => {
                if (response.ok) {
                  response.json().then(role => {
                    this.setState({
                      roleTypes: role
                    })
                  })
                }
              }
            )
          }
        }
      )
    }
  } */

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
      selectedItem: '',
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationCommand: false
    })
  }
  onRemovedUser = item => {
    this.setState({
      onDeleteConfirmation: true,
      selectedItem: item
    })
  }

  onRemovedNotification = item => {
    this.setState({
      notificationForm: { ...item[0] },
      onDeleteConfirmationNotification: true
    })
  }
  onRemovedAttribute = item => {
    this.setState({
      computedAttForm: { ...item[0] },
      onDeleteConfirmationAttributes: true
    })
  }
  onRemovedCommand = item => {
    this.setState({
      savedCommandsForm: { ...item[0] },
      onDeleteConfirmationCommand: true
    })
  }
  onRemovedMaintenance = item => {
    this.setState({
      maintenanceForm: { ...item[0] },
      onDeleteConfirmationMaintenance: true
    })
  }
  componentWillMount () {
    this.setState({ users: this.props.users2 })
    this.checkUserLimit()
    if (checkPrivileges('group') && (this.props.logInUsers.id)) {
      this.fetchResources(this.props.logInUsers.id)
    }
    if (checkPrivileges('area')) {
      // this.getAreaList()
    }
    
  }
  getAreaList =() =>{
    instance({
      url: `/api/areas/get`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
        .then(res => {
          let List = (res&&res.data).map(item =>{ 
            return (
               {
                id:item.id,
                name:item.name,
              }
             )
          })
          this.setState({areaList:List, areas:res})
          this.props.dispatch(getAreas(List))
 
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  componentWillReceiveProps (n) {
    let s = this.state
    if (n.match.params.id) {
      this.setState({ viewScreen: 'edit' })
    }

    if (n.addUserScreen) {
      this.setState({ viewScreen: 'add' })
    }
    if (!n.addUserScreen && !n.match.params.id) {
      this.setState({ viewScreen: null })
    }
    if (!s.searchText && n.users2.length) {
      this.fetchUsers('', n.users2)
    }
    if (checkPrivileges('group') &&((n.logInUsers&&n.logInUsers.id) !== this.props.logInUsers.id)) {
      this.fetchResources(n.logInUsers.id)
    }
    this.checkUserLimit()
  }

  fetchUsers = (text, users) => {
    const u = users.filter(u =>
      (u.id + u.name + u.email).toLowerCase().includes(text.toLowerCase())
    )
    this.setState({ users: u })
  }
  fetchResources = (id) => {
    instance({
      method: 'GET',
      url: `/api/groups/get?userId=${id}&page=${1}&limit=${500}`,
      // cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      // if (response.status === 200) {
        response.data&&response.data.map(item => {
          item.check = false
          return null
        })
        this.props.dispatch(getGroups(response.data))
      // }
    })
    .catch(error => {
      // errorHandler(error,this.props.dispatch)
    })
  }
  searchItems = text => {
    this.setState(
      {
        searchText: text
      },
      () => {
        this.fetchUsers(this.state.searchText, this.props.users2)
      }
    )
  }

  componentWillUnmount () {
    this.setState({
      viewScreen: null,
      // new
      userLimit: false,
      resFormData: { ...deviceDataFormat },
      selecteditem: '',
      remainings: {},
      remainingsDevice: {},
      limitFetech: false,

      users: [],
      searchText: ''
    })
  }

  render () {
    if (checkPrivileges('user')) {
      
      const pp = {
        ...this.props,
        users: this.state.users
      }

      return (
        <Layout
          {...pp}
          addUser={this.addNewUser}
          removedItem={this.onRemovedUser}
          userLimit={this.state.userLimit}
          classFromChildren='has-padding'
          searchItems={this.searchItems}
          showResources={this.showResources}
          onEditResource={this.onEditResource}
          addResource={this.addResource}
          resourceList={this.state.resourceList}
        >
          {!this.state.editResource ? <div
            className='main-content-page'
            style={{
              background: this.props.themecolors.backgroundColor,
              color: this.props.themecolors.textColor,
              borderRadius: 6
            }}
          >
            {this.state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={this.removedItem}
                title={this.props.translate('areYouWantToDelete')}
                children={this.state.selectedItem.name}
              />
            )}
            {this.state.viewScreen === 'add' && this.state.userLimit ? (
              <UsersModal
                {...pp}
                viewScreen={this.state.viewScreen}
                onCloseModal={this.onCloseModal}
                activeOperation={'add'}
                selecteditem={this.state.resFormData}
                roleTypes={this.state.roleTypes}
                fetchUserLimit={this.fetchUserLimit}
                resourceList={this.state.resourceList}
              />
            ) : (
              <div>
                {this.state.viewScreen === 'edit' &&
                this.props.users2.length &&
                this.props.match.params.id ? (
                  <>
                  <MainUnitModal
                    id={this.props.match.params.id}
                    history={this.props.history}
                    hash={this.props.location.hash}
                    extra={{ fetchUserLimit: this.fetchUserLimit }}
                  />
                  </>
                ) : (
                  <EmptyState
                    text={this.props.translate('pleaseSelectedUser')}
                  />
                )}
              </div>
            )}
          </div>:null}
          {this.state.editResource ? <div className='main-content-page'>
             <div style={{ background: this.props.themecolors.backgroundColor, color: this.props.themecolors.textColor, borderRadius: 6 }}>
               {/* <Grid container spacing={1}>
                 <Grid item xs={12} md={12}> */}
                   <ResourceModal
                     changeResource={this.props.changeResource}
                     selectedResourse={this.state.selectedResourse}
                     fetchNestedItems={this.props.fetchNestedItems}
                     editResource={this.state.editResource}
                     onCloseResource={this.onCloseResource}
                     itemType='User'
                   />
                 {/* </Grid>
               </Grid> */}
             </div>
           </div> : null}
        </Layout>
      )
    } else {
      return null
    }
  }
}

const mapState = state => {
  return {
    users2: state.users,
    themecolors: state.themeColors,
    logInUsers: state.logInUsers
  }
}

const mapStateToProps = connect(mapState)
export default mapStateToProps(withSnackbar(withLocalize(withResources(Units, 'User'))))