import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import moment from 'moment'
import { withLocalize } from 'react-localize-redux'
import Layout from './../../Layout'
//import RouteMap from './../../Components/Maps/RouteMap';
import TimemachineMap from './../../Components/Maps/Timemachine'
import { updateGroups } from './../../Actions/Groups'
import 'moment-duration-format'
import { checkPrivileges, errorHandler, getDateTimeFormat, setAttributeFormat } from '../../Helpers'
import './Style.scss'
import $ from 'jquery'
import instance from '../../axios'
import ReactDOMServer from "react-dom/server";
import { ReactComponent as IdlingIcon } from '../../assets/monitoring/idling.svg'
import { ReactComponent as ParkingIcon } from '../../assets/monitoring/parking.svg'
import { ReactComponent as DefaultIcon } from '../../assets/monitoring/info.svg'
import { ReactComponent as AnalogCustomIcon } from '../../assets/icons-time-machine/analogCustom.svg'
import { ReactComponent as BinChangedIcon } from '../../assets/icons-time-machine/binChanged-01.svg'
import { ReactComponent as DeviceAverageFuelConsumptioIcon } from '../../assets/icons-time-machine/deviceAverageFuelConsumption-01.svg'
import { ReactComponent as DeviceBoardPowerIcon } from '../../assets/icons-time-machine/deviceBoardPower-01.svg'
import { ReactComponent as DeviceCarAlarmIcon } from '../../assets/icons-time-machine/deviceCarAlarm-01.svg'
import { ReactComponent as DeviceCounterIcon } from '../../assets/icons-time-machine/deviceCounter-01.svg'
import { ReactComponent as DeviceDoorsIcon } from '../../assets/icons-time-machine/deviceDoors-01.svg'
import { ReactComponent as DeviceEngineStatusIcon } from '../../assets/icons-time-machine/deviceEngineStatus-01.svg'
import { ReactComponent as GeoIcon } from '../../assets/icons-time-machine/deviceEnterGeofence-01.svg'
import { ReactComponent as DeviceExpireIcon } from '../../assets/icons-time-machine/deviceExpire-01.svg'
import { ReactComponent as DefviceExternalPowerIcon } from '../../assets/icons-time-machine/deviceExternalPower-01.svg'
import { ReactComponent as DeviceFlowMeterIcon } from '../../assets/icons-time-machine/deviceFlowMeter-01.svg'
import { ReactComponent as DeviceFuelIcon } from '../../assets/icons-time-machine/deviceFuel-01.svg'
import { ReactComponent as DeviceFuelDrainIcon } from '../../assets/icons-time-machine/deviceFuelDrain-01.svg'
import { ReactComponent as DeviceFuelFillingIcon } from '../../assets/icons-time-machine/deviceFuelFilling-01.svg'
import { ReactComponent as DeviceIgnitionIcon } from '../../assets/icons-time-machine/deviceIgnition-01.svg'
import { ReactComponent as DeviceInstantFuelConsumptionIcon } from '../../assets/icons-time-machine/deviceInstantFuelConsumption-01.svg'
import { ReactComponent as DeviceMileageIcon } from '../../assets/icons-time-machine/deviceMileage-01.svg'
import { ReactComponent as DeviceMovingIcon } from '../../assets/icons-time-machine/deviceMoving-01.svg'
import { ReactComponent as DeviceOfflineIcon } from '../../assets/icons-time-machine/deviceOffline-01.svg'
import { ReactComponent as DeviceOnlineIcon } from '../../assets/icons-time-machine/deviceOnline-01.svg'
import { ReactComponent as DeviceOverspeedIcon } from '../../assets/icons-time-machine/deviceOverspeed-01.svg'
import { ReactComponent as DevicePanicButtonIcon } from '../../assets/icons-time-machine/devicePanicButton-01.svg'
import { ReactComponent as DeviceParkingIcon } from '../../assets/icons-time-machine/deviceParking-01.svg'
import { ReactComponent as DevicePowerCutIcon } from '../../assets/icons-time-machine/devicePowerCut-01.svg'
import { ReactComponent as DeviceStoppedIcon } from '../../assets/icons-time-machine/deviceStopped-01.svg'
import { ReactComponent as DeviceTemperatureIcon } from '../../assets/icons-time-machine/deviceTemperature-01.svg'
import { ReactComponent as DeviceTowingIcon } from '../../assets/icons-time-machine/deviceTowing-01.svg'
import { ReactComponent as DeviceUnknownIcon } from '../../assets/icons-time-machine/deviceUnknown-01.svg'
import { ReactComponent as DeviceWorkModeIcon } from '../../assets/icons-time-machine/deviceWorkMode-01.svg'
import { ReactComponent as DigitalCustomIcon } from '../../assets/icons-time-machine/digitalCustom-01.svg'
import { ReactComponent as DriverChangedIcon } from '../../assets/icons-time-machine/driverChanged-01.svg'
import { ReactComponent as ExactValueIcon } from '../../assets/icons-time-machine/exactValue-01.svg'
import { ReactComponent as HarshAccelerationIcon } from '../../assets/icons-time-machine/harshAcceleration-01.svg'
import { ReactComponent as HarshBrakeIcon } from '../../assets/icons-time-machine/harshBrake-01.svg'
import { ReactComponent as HarshBumpIcon } from '../../assets/icons-time-machine/harshBump-01.svg'
import { ReactComponent as HumidityIcon } from '../../assets/icons-time-machine/humidity-01.svg'
import { ReactComponent as ParkingSecurityIcon } from '../../assets/icons-time-machine/parkingSecurity-01.svg'
import { ReactComponent as PassengerChangedIcon } from '../../assets/icons-time-machine/passengerChanged-01.svg'
import { ReactComponent as TrailerChangedIcon } from '../../assets/icons-time-machine/trailerChanged-01.svg'
import { ReactComponent as UnknownDriverIcon } from '../../assets/icons-time-machine/unknownDriver-01.svg'
import { ReactComponent as WeightIcon } from '../../assets/icons-time-machine/weight-01.svg'

 

const ReportData = []
let trips = {}

class Timemachine extends Component {
  constructor (props) {
    super(props)
    this.state = {
      groupsCall: false,
      view: 'displayForm',
      from: '',
      trackLoading: false,
      mainView: '',
      to: '',
      selectedDate: '',
      selectedDay: '',
      groupId: [],
      reportsData: ReportData,
      tripsDialog: false,
      loading: false,
      validatedForm: false,
      detail: false,
      detail1: false,
      reportUnits: true,
      notificationId: [],
      minimalNoDataDuration: 0,
      minimalParkingDuration: 0,
      minimalTripDuration: 0,
      minimalTripDistance: 0,
      speedThreshold: 0,
      shownRows: 0,
      processInvalidPositions: false,
      useIgnition: true,
      timelineRanges: {},
      advanceSettingsForm: {
        // minimalNoDataDuration: 600,
        // minimalParkingDuration: 300,
        // minimalTripDuration: 60,
        // minimalTripDistance: 0.1,
        // speedThreshold: 1.61987,
        // advanceSettings: false,
        // processInvalidPositions: false,
        // useIgnition: true
      },
      routes: {},
      trips: trips,
      routesLoading: {},
      fetchAll: false,
      timemachineArray: {},
      timemachinePositions: [],
      items: [],
      groups: [],
      options: {},
      controlTime: [0, 1439],
      lat:'', 
      lon:'',
      radius:'',
      rawLatLng:'',
      isCheckRaw:false


    }

    this.openForm = this.openForm.bind(this)
    this.showDates = this.showDates.bind(this)
    this.onDismiss = this.onDismiss.bind(this)
    this.selectedDevices = this.selectedDevices.bind(this)
    this.selectedGroups = this.selectedGroups.bind(this)
    this.createReport = this.createReport.bind(this)
    this.advanceFormChange = this.advanceFormChange.bind(this)
    this.saveData = this.saveData.bind(this)
    this.reportTypeSelection = this.reportTypeSelection.bind(this)
    this.changeCheckbox = this.changeCheckbox.bind(this)
    this.updateColor = this.updateColor.bind(this)
    this.pageLoading = this.pageLoading.bind(this)
    this.changeMainView = this.changeMainView.bind(this)
    this.changeMainView1 = this.changeMainView1.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeRaw=this.handleChangeRaw.bind(this)

  }
  handleChangeRaw=(event)=>{
    this.setState({
      isCheckRaw:event.target.checked,
      lat:"",
      lon:"",
      rawLatLng:""
    })
  }
  pageLoading (action) {
    this.setState({ trackLoading: action })
  }
  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  changeCheckbox (groupDate, groupId, rowId, check) {
    let devices = Object.assign({ ...this.state.trips })
    devices[groupId]['data'][groupDate].map(row => {
      if (row.startPositionId === rowId) {
        row.checked = check
      }
      return null
    })

    this.setState({ trips: devices })
  }

  updateColor (groupDate, groupId, rowId, color) {
    let devices = Object.assign({ ...this.state.trips })
    let r = {}

    devices[groupId]['data'][groupDate].map(row => {
      if (row.startPositionId === rowId) {
        row.color = color
        r = row
      }
      return null
    })

    this.setState({ trips: devices }, () => {
      this.cancelRoute(r)
      this.drawRoute(r)
    })
  }

  calcTime = (date, offset) => {
    let d = new Date(date)

    let utc = d.getTime() + d.getTimezoneOffset() * 60000

    let nd = new Date(utc + 3600000 * offset)

    return nd.toISOString()
  }

  changeMainView(mainView) {
    let timezone = 'Asia/Dubai';
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }

    if (mainView === 'timemachine') {
      this.setState({ mainView }, () => {
        if (this.state.fetchAll === false) {
          this.setState({ fetchAll: true, loading: true }, async () => {
            let deviceId = this.state.deviceId?.id || '';
            let groupId = '';
            this.state.groupId.map(id => (groupId += 'groupId=' + id + '&'));

            let to = this.state.to;
            let from = this.state.from;
            let datetimeformat = getDateTimeFormat();
            let arr = [];
            let items = [];
            let groups = [];
            let startDates = [];
            let endDates = [];
            let options = {};

            try {
              const response = await instance({
                url: `/api/reports/timelapse?deviceId=${deviceId}${groupId}`,
                method: 'GET',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Accept: 'application/json'
                },
                params: {
                  type: 'allEvents',
                  from: from,
                  to: to
                }
              });

              let data = response;
              let positions = [];

              await Promise.all(Object.keys(data).map(async (k) => {

                if (data[k].length) {
                  positions.push([data[k][0].lat, data[k][0].lng]);

                  startDates.push(data[k][0].t);
                  endDates.push(data[k][data[k].length - 1].t);

                  const deviceResponse = await this.fetchDevice(k);
                  // const minimalParkingDuration = deviceResponse[0].attributes.minimalParkingDuration || 0;
                  const minimalParkingDuration = deviceResponse[0].attributes.minimalParkingDuration * 1000 || 0; // convert to milliseconds

                  let device = this.props.devices2.find(
                    d => d.id.toString() === k.toString()
                  );
                  groups.push({ content: device.label, id: parseInt(k) });

                  let c = data[k].map(l => {
                    items.push({
                      className: 'custom-timemachine-item',
                      group: parseInt(k),
                      start: l.t,
                      title: 'Speed: ' + setAttributeFormat('speed', l.s),
                      type: 'point',
                    });

                    let event = l?.e || "";
                    let findEvent = event;
                    let icon = findEvent.includes("geo") ?
                      <GeoIcon fill="white" width={21} height={21} />
                      : findEvent.includes("idling") ?
                      <IdlingIcon fill="white" width={21} height={21} />
                      : findEvent.includes("parking") ?
                      <ParkingIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceEnterGeofence") ?
                      <GeoIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceExitGeofence") ?
                      <GeoIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceOverspeed") ?
                      <DeviceOverspeedIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceStopped") ?
                      <DeviceStoppedIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceMoving") ?
                      <DeviceMovingIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceTowing") ?
                      <DeviceTowingIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceIdling") ?
                      <IdlingIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceParking") ?
                      <DeviceParkingIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceOnline") ?
                      <DeviceOnlineIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceUnknown") ?
                      <DeviceUnknownIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceOffline") ?
                      <DeviceOfflineIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceIgnition") ?
                      <DeviceIgnitionIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceExternalPower") ?
                      <DefviceExternalPowerIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceEngineStatus") ?
                      <DeviceEngineStatusIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceCarAlarm") ?
                      <DeviceCarAlarmIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceDoors") ?
                      <DeviceDoorsIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceWorkMode") ?
                      <DeviceWorkModeIcon fill="white" width={21} height={21} />
                      : findEvent.includes("digitalCustom") ?
                      <DigitalCustomIcon fill="white" width={21} height={21} />
                      : findEvent.includes("devicePowerCut") ?
                      <DevicePowerCutIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceFuel") ?
                      <DeviceFuelIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceTemperature") ?
                      <DeviceTemperatureIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceBoardPower") ?
                      <DeviceBoardPowerIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceMileage") ?
                      <DeviceMileageIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceCounter") ?
                      <DeviceCounterIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceAverageFuelConsumption") ?
                      <DeviceAverageFuelConsumptioIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceInstantFuelConsumption") ?
                      <DeviceInstantFuelConsumptionIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceFlowMeter") ?
                      <DeviceFlowMeterIcon fill="white" width={21} height={21} />
                      : findEvent.includes("humidity") ?
                      <HumidityIcon fill="white" width={21} height={21} />
                      : findEvent.includes("weight") ?
                      <WeightIcon fill="white" width={21} height={21} />
                      : findEvent.includes("analogCustom") ?
                      <AnalogCustomIcon fill="white" width={21} height={21} />
                      : findEvent.includes("driverChanged") ?
                      <DriverChangedIcon fill="white" width={21} height={21} />
                      : findEvent.includes("passengerChanged") ?
                      <PassengerChangedIcon fill="white" width={21} height={21} />
                      : findEvent.includes("trailerChanged") ?
                      <TrailerChangedIcon fill="white" width={21} height={21} />
                      : findEvent.includes("binChanged") ?
                      <BinChangedIcon fill="white" width={21} height={21} />
                      : findEvent.includes("unknownDriver") ?
                      <UnknownDriverIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceFuelFilling") ?
                      <DeviceFuelFillingIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceFuelDrain") ?
                      <DeviceFuelDrainIcon fill="white" width={21} height={21} />
                      : findEvent.includes("harshBrake") ?
                      <HarshBrakeIcon fill="white" width={21} height={21} />
                      : findEvent.includes("harshAcceleration") ?
                      <HarshAccelerationIcon fill="white" width={21} height={21} />
                      : findEvent.includes("harshBump") ?
                      <HarshBumpIcon fill="white" width={21} height={21} />
                      : findEvent.includes("deviceExpire") ?
                      <DeviceExpireIcon fill="white" width={21} height={21} />
                      : findEvent.includes("parkingSecurity") ?
                      <ParkingSecurityIcon fill="white" width={21} height={21} />
                      : findEvent.includes("exactValue") ?
                      <ExactValueIcon fill="white" width={21} height={21} />
                      : <DefaultIcon fill="white" width={21} height={21} />;

                    let stopTimeIcon = <ParkingIcon width={20} height={20} fill="red" />;

                    return {
                      id: k,
                      lat: l.lat,
                      lng: l.lng,
                      time: parseInt(moment(l['t']).format('x') || 0),
                      info: l.e !== "" ? [
                        { key: 'Name', value: device.label },
                        { key: 'Speed', value: setAttributeFormat('speed', l.s) },
                        { key: 'Time', value: moment.utc(l.t).tz(timezone).format(datetimeformat) },
                        ...(l.st > minimalParkingDuration ? [
                          {
                            key: 'Stop Time',
                            value: this.convertMilliseconds(l.st),
                            data: ReactDOMServer.renderToString(stopTimeIcon)
                          }
                        ] : []),
                        { key: "Event", value: l?.e || "-", data: ReactDOMServer.renderToString(icon) },

                      ] : [
                        { key: 'Name', value: device.label },
                        { key: 'Speed', value: setAttributeFormat('speed', l.s) },
                        { key: 'Time', value: moment.utc(l.t).tz(timezone).format(datetimeformat) },
                        ...(l.st > minimalParkingDuration ? [
                          {
                            key: 'Stop Time',
                            value: this.convertMilliseconds(l.st),
                            data: ReactDOMServer.renderToString(stopTimeIcon)
                          }
                        ] : []),
                      ]
                    };
                  });
                  if (c.length) {
                    arr.push(c);
                  }
                }
              }));

              if (startDates.length) {
                endDates.sort(function (a, b) {
                  return moment(b).unix() - moment(a).unix();
                });
                startDates.sort(function (a, b) {
                  return moment(a).unix() - moment(b).unix();
                });
                let s = moment(startDates[0])
                  .subtract(60, 'm')
                  .valueOf();
                let e = moment(endDates[0])
                  .add(60, 'm')
                  .valueOf();
                options.start = s;
                options.end = e;
                options.min = s;
                options.max = e;
              }

              this.setState({
                shownRows: Object.keys(groups).length,
                timemachineArray: arr,
                timemachineBounds: positions,
                items,
                groups,
                options,
                loading: false,
                trackLoading: false,
                fetchAll: false
              });
            } catch (error) {
              // errorHandler(error, this.props.dispatch);
            }
          });
        }
      });
    } else {
      this.setState({ mainView });
    }
  }

async fetchDevice(id) {
    try {
      const response = await instance({
        method: 'GET',
        url: `/api/devices?id=${id}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
      return response;
    } catch (error) {
      errorHandler(error, this.props.dispatch);
      throw error; // rethrow error for further handling if needed
    }
}

   convertMilliseconds =(milliseconds)=> {
    // Convert milliseconds to hours, minutes, and seconds
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return `${hours}:${minutes}:${seconds}`;
}
handleChange(name, value){
  if(name==="rawLatLng"){
    const inputValue = value;
    this.setState({["rawLatLng"]:value},()=>this.checkRequiredFields()); 
    const match = inputValue.match(/^(\d+(\.\d+)?),(\d+(\.\d+)?)$/);
    if (match) {
      const lat = parseFloat(match[1])
      const lng = parseFloat(match[3])
    this.setState({
      lat:lat,
      lon:lng,
    },()=>this.checkRequiredFields())
  }else {
    this.setState({ lat: "", lon: "" });
  }
  }else{
    let val = parseFloat(value)
    this.setState({
      [name]:val
    },()=>this.checkRequiredFields())
  }
}
  // handleChange(name, value){
  //   let val = parseFloat(value)
  //   this.setState({
  //     [name]:val
  //   },()=>this.checkRequiredFields())
  // }
  checkRequiredFields =()=>{
    const {lat,lon, radius}=this.state;
    if(lat && lon && radius){
        this.setState({validatedReportForm:true})
    }else{
      this.setState({validatedReportForm:false})
    }
  }


  changeMainView1(mainView) {
    let timezone = 'Asia/Dubai';
    if (
        this.props.ServerSetting &&
        this.props.ServerSetting.attributes &&
        this.props.ServerSetting.attributes.timezone
    ) {
        timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
        this.props.logInUser &&
        this.props.logInUser.attributes &&
        this.props.logInUser.attributes.timezone
    ) {
        timezone = this.props.logInUser.attributes.timezone;
    }

    if (mainView === 'timemachine') {
        this.setState({ mainView }, () => {
            if (this.state.fetchAll === false) {
                this.setState({ fetchAll: true, loading: true },async () => {
                    let deviceId = this.state.deviceId?.id || '';
                    let groupId = '';
                    this.state.groupId.map(id => (groupId += 'groupId=' + id + '&'));

                    let to = this.state.to;
                    let from = this.state.from;
                    let radius = this.state.radius;
                    let lat = this.state.lat;
                    let lon = this.state.lon;
                    let datetimeformat = getDateTimeFormat();
                    let arr = [];
                    let items = [];
                    let groups = [];
                    let startDates = [];
                    let endDates = [];
                    let options = {};
                    try {
                      const response = await instance({
                        url: `/api/reports/geopoint/timelapses?`,
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Accept: 'application/json'
                        },
                        params: {
                            radius: radius,
                            from: from,
                            to: to,
                            latitude: lat,
                            longitude: lon
                        }
                    })
                    // .then(response => {
                      let data = response;
                        let positions = [];
                        await Promise.all(Object.keys(data).map(async (k) => {
                            if (data[k].length) {
                                positions.push([data[k][0].lat, data[k][0].lng]);

                                startDates.push(data[k][0].t);
                                endDates.push(data[k][data[k].length - 1].t);

                                let device = this.props.devices2.find(
                                    d => d.id.toString() === k.toString()
                                );
                                groups.push({ content: device.label, id: parseInt(k) });

                                const deviceResponse = await this.fetchDevice(k);
                                const minimalParkingDuration = deviceResponse[0].attributes.minimalParkingDuration * 1000 || 0; // convert to milliseconds

                                let c = data[k].map(l => {
                                    items.push({
                                        className: 'custom-timemachine-item-2',
                                        group: parseInt(k),
                                        start: l.t,
                                        title: 'Speed: ' + setAttributeFormat('speed', l.s),
                                        type: 'point',
                                    });

                                    let event = l?.e?.trim() || "";
                                    let findEvent = event;
                                    let icon = findEvent.includes("geo") ?
                                        <GeoIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("idling") ?
                                        <IdlingIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("parking") ?
                                        <ParkingIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceEnterGeofence") ?
                                        <GeoIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceExitGeofence") ?
                                        <GeoIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceOverspeed") ?
                                        <DeviceOverspeedIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceStopped") ?
                                        <DeviceStoppedIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceMoving") ?
                                        <DeviceMovingIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceTowing") ?
                                        <DeviceTowingIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceIdling") ?
                                        <IdlingIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceParking") ?
                                        <DeviceParkingIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceOnline") ?
                                        <DeviceOnlineIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceUnknown") ?
                                        <DeviceUnknownIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceOffline") ?
                                        <DeviceOfflineIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceIgnition") ?
                                        <DeviceIgnitionIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceExternalPower") ?
                                        <DefviceExternalPowerIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceEngineStatus") ?
                                        <DeviceEngineStatusIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceCarAlarm") ?
                                        <DeviceCarAlarmIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceDoors") ?
                                        <DeviceDoorsIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceWorkMode") ?
                                        <DeviceWorkModeIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("digitalCustom") ?
                                        <DigitalCustomIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("devicePowerCut") ?
                                        <DevicePowerCutIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceFuel") ?
                                        <DeviceFuelIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceTemperature") ?
                                        <DeviceTemperatureIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceBoardPower") ?
                                        <DeviceBoardPowerIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceMileage") ?
                                        <DeviceMileageIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceCounter") ?
                                        <DeviceCounterIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceAverageFuelConsumption") ?
                                        <DeviceAverageFuelConsumptioIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceInstantFuelConsumption") ?
                                        <DeviceInstantFuelConsumptionIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceFlowMeter") ?
                                        <DeviceFlowMeterIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("humidity") ?
                                        <HumidityIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("weight") ?
                                        <WeightIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("analogCustom") ?
                                        <AnalogCustomIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("driverChanged") ?
                                        <DriverChangedIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("passengerChanged") ?
                                        <PassengerChangedIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("trailerChanged") ?
                                        <TrailerChangedIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("binChanged") ?
                                        <BinChangedIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("unknownDriver") ?
                                        <UnknownDriverIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceFuelFilling") ?
                                        <DeviceFuelFillingIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceFuelDrain") ?
                                        <DeviceFuelDrainIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("harshBrake") ?
                                        <HarshBrakeIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("harshAcceleration") ?
                                        <HarshAccelerationIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("harshBump") ?
                                        <HarshBumpIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("deviceExpire") ?
                                        <DeviceExpireIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("parkingSecurity") ?
                                        <ParkingSecurityIcon fill="white" width={21} height={21} />
                                        : findEvent.includes("exactValue") ?
                                        <ExactValueIcon fill="white" width={21} height={21} />
                                        : <DefaultIcon fill="white" width={21} height={21} />;

                                    let stopTimeIcon = <ParkingIcon width={20} height={20} fill="red" />;

                                    return {
                                        id: k,
                                        lat: l.lat,
                                        lng: l.lng,
                                        time: parseInt(moment(l['t']).format('x') || 0),
                                        info: !l.e.includes(" ") ? [
                                            { key: 'Name', value: device.label },
                                            { key: 'Speed', value: setAttributeFormat('speed', l.s) },
                                            { key: 'Time', value: moment.utc(l.t).tz(timezone).format(datetimeformat) },
                                            // { key: 'Stop Time', value: l.st > 0 ? this.convertMilliseconds(l.st) : '0:00:00' },
                                            ...(l.st > minimalParkingDuration ? [
                                              {
                                                key: 'Stop Time',
                                                value: this.convertMilliseconds(l.st),
                                                data: ReactDOMServer.renderToString(stopTimeIcon)
                                              }
                                            ] : []),
                                            { key: "Event", value: l?.e || "-", data: ReactDOMServer.renderToString(icon) },
                                            // { key: "Stop Time", value: l.st >= minimalParkingDuration ? this.convertMilliseconds(l.st) : '0:00:00', data: l.st >= minimalParkingDuration ? ReactDOMServer.renderToString(stopTimeIcon) : null }
                                        ] :
                                            [
                                                { key: 'Name', value: device.label },
                                                { key: 'Speed', value: setAttributeFormat('speed', l.s) },
                                                { key: 'Time', value: moment.utc(l.t).tz(timezone).format(datetimeformat) },
                                                // { key: 'Stop Time', value: l.st > 0 ? this.convertMilliseconds(l.st) : '0:00:00' },
                                                ...(l.st > minimalParkingDuration ? [
                                                  {
                                                    key: 'Stop Time',
                                                    value: this.convertMilliseconds(l.st),
                                                    data: ReactDOMServer.renderToString(stopTimeIcon)
                                                  }
                                                ] : []),
                                            ]
                                    };
                                });

                                if (c.length) {
                                    arr.push(c);
                                }
                            }
                            return null;
                        }));

                        if (startDates.length) {
                            endDates.sort(function (a, b) {
                                return moment(b).unix() - moment(a).unix();
                            });
                            startDates.sort(function (a, b) {
                                return moment(a).unix() - moment(b).unix();
                            });
                            let s = moment(startDates[0])
                                .subtract(60, 'm')
                                .valueOf();
                            let e = moment(endDates[0])
                                .add(60, 'm')
                                .valueOf();
                            options.start = s;
                            options.end = e;
                            options.min = s;
                            options.max = e;
                        }
                        this.setState({
                            shownRows: Object.keys(groups).length,
                            timemachineArray: arr,
                            timemachineBounds: positions,
                            items,
                            groups,
                            options,
                            loading: false,
                            trackLoading: false,
                            fetchAll: false
                        });
                    }catch(error)  {
                        // errorHandler(error, this.props.dispatch)
                    };
                });
            }
        });
    } else {
        this.setState({ mainView });
    }
}
  




  componentWillReceiveProps (NextProps) {
    if (
      NextProps.logInUser.id &&
      NextProps.groups &&
      !NextProps.groups.length &&
      this.state.groupsCall === false
    ) {
      this.setState({ groupsCall: true }, () => {
        // fetch('/api/groups?userId=' + NextProps.logInUser.id)
        instance({
          url: `/api/groups`,
          method: 'GET',
          params: {
            userId: NextProps.logInUser.id
          }
        })
              .then(groups => {
                NextProps.dispatch(updateGroups(groups))
              })
          .catch(error => {})
      })
    }
  }

  // componentWillMount () {

  //   this.setState({
  //     controlTime: [
  //       moment
  //         .duration(
  //           controlTimeStart || '00:00'
  //         )
  //         .asMinutes(),
  //       moment
  //         .duration(
  //           controlTimeEnd || '23:59'
  //         )
  //         .asMinutes()
  //     ]
  //   })
  // }
  advanceFormChange (event, name) {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value

    this.setState({
      advanceSettingsForm: { ...this.state.advanceSettingsForm, [name]: value }
    })
  }

  reportTypeSelection () {
    let v = this.state.reportUnits
    this.setState({
      reportUnits: !v
    })
  }

  onCloseModal (e, name) {
    this.setState({ [name]: false })
  }

  openForm () {
    this.setState({ view: 'displayForm', reportsData: [], routes: {} })
  }

  saveData (blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
    }
  }
  getRandomColor = () => {
    let letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  createReport (type) {
    
    if(type ==='default'){
      this.changeMainView('timemachine')
    }else if(type === 'report'){
      this.changeMainView1('timemachine')
    }
  }

  showDates (e, date) {
    let timezone = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    let endDate = null

    if (e.type === 'apply') {
      let date1 = moment(date.startDate.format('YYYY-MM-DD')).startOf('day');  // Change: Added .startOf('day')
      let date2 = moment(date.startDate.format('YYYY-MM-DD')).startOf('day');  // Change: Added .startOf('day')
      let date3 = moment(date.endDate.format('YYYY-MM-DD')).endOf('day');  // Change: Added .endOf('day')
      // let date1 = moment(date.startDate.format('YYYY-MM-DD HH:mm'))
      // let date2 = moment(date.startDate.format('YYYY-MM-DD HH:mm'))
      // let date3 = moment(date.endDate.format('YYYY-MM-DD HH:mm'))
      let end = date1.add(1, 'month')
      let from = date2.subtract(1, 'month')

      if (
        end.valueOf() >= date3.valueOf() &&
        from.valueOf() <= date3.valueOf()
      ) {
        endDate = moment(date.endDate.format('YYYY-MM-DD')).toISOString()
      } else {
        endDate = end.toISOString()
      }
    }

    // var formatedDate = {
    //   from: moment(date.startDate.format('YYYY-MM-DD')).startOf('day').format('YYYY-MM-DD'),
    //   to: moment(date.endDate.format('YYYY-MM-DD')).endOf('day').format('YYYY-MM-DD')
    // }

    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD')).startOf('day').toISOString(),  
      // from: moment(date.startDate.format('YYYY-MM-DD')).tosISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD')).endOf('day').toISOString()
    }

    let timeFormat = getDateTimeFormat()

    this.setState(
      {
        selectedDay:moment(formatedDate.from).format("YYYY-MM-DD"),
        // selectedDate:
        //   moment(formatedDate.from).format(timeFormat) +
        //   ' - ' +
        //   moment(formatedDate.to).format(timeFormat),
        ...formatedDate
      },
      () => {
      
        if (
          (this.state.groupId.length || this.state.deviceId) &&
          (this.state.selectedDate || this.state.selectedDay)
        ) {
          this.setState({ validatedForm: true })
        } else {
          this.setState({ validatedForm: false })
        }
      }
    )
  }


  handleTimeControl = (event, controlTime) => {

    this.setState({ controlTime }, () => {
      // Assuming this.state.from and this.state.to are date strings
      const fromDate = moment(this.state.from); // Convert fromDate string to moment object
      const datePart = fromDate.format('YYYY-MM-DD');
      const toDate = moment(this.state.to);
  
      // Extracting the date part and time part
      // const datePart = fromDate.format('YYYY-MM-DD');
      const timePartFrom = moment()
        .startOf('day')
        .add(this.state.controlTime[0], 'minutes')
        .format('HH:mm:ss');
      const timePartTo = moment()
        .startOf('day')
        .add(this.state.controlTime[1], 'minutes')
        .format('HH:mm:ss');
  
      // Updating the state with the combined date and time parts
      this.setState(
        {
          from: moment(`${datePart}${timePartFrom}`, 'YYYY-MM-DDHH:mm:ss').toISOString(),
          to: moment(`${datePart}${timePartTo}`, 'YYYY-MM-DDHH:mm:ss').toISOString(),
        }
      );
    });
  };
  
  onDismiss () {
    this.setState({
      shownRows: 0,
      timemachineArray: {},
      timemachinePositions: [],
      loading: false,
      trackLoading: false,
      fetchAll: false
    })
  }

  selectedNotifications = notificationId => {
    var notif = []
    if (notificationId && notificationId.length) {
      notificationId.map(d => {
        notif.push(d.value)
        return null
      })
    }
    this.setState({ notificationId: notif }, () => {
      if (
        (this.state.groupId.length || this.state.deviceId) &&
        (this.state.selectedDate || this.state.selectedDay)
      ) {
        this.setState({ validatedForm: true })
      } else {
        this.setState({ validatedForm: false })
      }
    })
  }

  selectedDevices (data) {
      this.setState({ deviceId:data }, () => {
         if (
          (this.state.deviceId) &&
          (this.state.selectedDate ||this.state.selectedDay)
        ) {
          this.setState({ validatedForm: true })
        } else {
          this.setState({ validatedForm: false })
        }
      })
   }

  selectedGroups (groupId) {
    var groups = []
    if (groupId && groupId.length) {
      groupId.map(d => {
        groups.push(d.value)
        return null
      })
    }
    this.setState({ groupId: groups }, () => {
      if (
        (this.state.groupId.length || this.state.deviceId) &&
        (this.state.selectedDate ||this.state.selectedDay)
      ) {
        this.setState({ validatedForm: true })
      } else {
        this.setState({ validatedForm: false })
      }
    })
  }

  render () {
    return (
      <div className='track-wrapper'>
        {checkPrivileges('timeLapse') ? (
          <Layout
            {...this.props}
            timelineRanges={this.state.timelineRanges}
            classFromChildren={
              (this.state.mainView === 'timemachine' ? 'with-timeline' : '') +
              (this.state.shownRows
                ? ' rows-shown-' + this.state.shownRows
                : '')
            }
            {...this.state}
            openForm={this.openForm}
            pageLoading={this.pageLoading}
            drawRoute={this.drawRoute}
            cancelRoute={this.cancelRoute}
            createReport={this.createReport}
            advanceSettings={this.state.advanceSettingsForm.advanceSettings}
            advanceSettingsForm={this.state.advanceSettingsForm}
            advanceFormChange={this.advanceFormChange}
            reportTypeSelection={this.reportTypeSelection}
            showDates={this.showDates}
            handleTimeControl={this.handleTimeControl}
            selectedDevices={this.selectedDevices}
            selectedNotifications={this.selectedNotifications}
            selectedGroups={this.selectedGroups}
            changeCheckbox={this.changeCheckbox}
            updateColor={this.updateColor}
            timemachineDevices={this.state.deviceId}
            changeMainView={this.changeMainView}
            changeMainView1={this.changeMainView1}
            handleChange={this.handleChange}
            handleChangeRaw={this.handleChangeRaw}
            isCheckRaw={this.state.isCheckRaw}
          >
            {this.state.mainView === 'timemachine' && (
              <TimemachineMap
                routes={this.state.routes}
                timemachineArray={this.state.timemachineArray}
                timemachineBounds={this.state.timemachineBounds}
                options={this.state.options}
                items={this.state.items}
                groups={this.state.groups}
                reportsData={this.state.reportsData}
                loading={this.state.loading}
                onDismiss={this.onDismiss}
              />
            )}
          </Layout>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  devices: state.devices.data,
  devices2: state.devices2,
  logInUser: state.logInUsers,
  groups: state.groups,
  ServerSetting: state.ServerSetting,
  themecolors: state.themeColors
})

export default connect(mapStateToProps)(withLocalize(Timemachine))
