import React, { Component } from 'react'
import Icon from '@material-ui/core/Icon'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { ReactComponent as TrailerIcon } from './../../assets/nav/trailer.svg'
import { ReactComponent as BinIcon } from './../../assets/nav/bin.svg'
import { ReactComponent as PassengerIcon } from './../../assets/nav/passenger.svg'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { checkPrivileges } from '../../Helpers'
import { withLocalize } from 'react-localize-redux'
import Style from 'style-it'
library.add(faSatelliteDish)

class Trailer extends Component {
  render () {
    let data = ''
    if (this.props.items.length) {
      data = this.props.items.map(trailer => (
        <ListRow
          key={trailer.id}
          item={trailer}
          {...this.props}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
          selecteItem={this.props.selecteItem}
        />
      ))
    }
    return (
      <Style>
        {`
  .material-icons {vertical-align: middle; color: inherit}
`}
        <div>
          {data && (
            <div className='individual-view'>
              <ul className='list-view with-padding-right'>{data}</ul>
            </div>
          )}
        </div>
      </Style>
    )
  }
}

export class ListRow extends Component {
  render () {
    // const device = Object.values(this.props.allTrailerLocation).find(({binder, id}) =>binder&& binder.uniqueId === this.props.item.uniqueId)
     const device = this.props.allTrailerLocation && this.props.allTrailerLocation[this.props.item.uniqueId] || null
   return (
      <li>
        <Link
          onClick={this.handleClick}
          className={
            'clearfix list-row ' +
            (parseInt(this.props.match.params.id) ===
            parseInt(this.props.item?.id)
              ? 'active'
              : '')
          }
          to={'/binders/' + this.props.item?.id}
        >
          <label
            className='checkbox'
            onClick={() => {this.props.selecteItem(this.props.item)
            }}
          >
            <span
              className='unit-name'
              style={{ minWidth: 190, maxWidth: 190, marginLeft: 0 }}
            >
              <span
                style={{
                  marginRight: '5px',
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
              >
                {
                  this.props.item?.type ==="trailer" ?
                    <TrailerIcon style={{
                      verticalAlign: 'middle',
                      marginRight: 5,
                      marginTop: -2,
                      height: 20,
                      width: 20,
                      fill: 'currentColor',
                      display: 'inline-block'
                    }} />
                  : this.props.item?.type ==="bin" ?
                    <BinIcon style={{
                      verticalAlign: 'middle',
                      marginRight: 5,
                      marginTop: -2,
                      height: 20,
                      width: 20,
                      fill: 'currentColor',
                      display: 'inline-block'
                    }} />
                  : 
                    <PassengerIcon style={{
                      verticalAlign: 'middle',
                      marginRight: 5,
                      marginTop: -2,
                      height: 20,
                      width: 20,
                      fill: 'currentColor',
                      display: 'inline-block'
                    }} />
                }
                
              </span>

              {this.props.item?.name}
            </span>
          </label>
          <div className='pull-right'>
            {checkPrivileges('binderDelete') && (
              <span
                className='action-item hovered'
                title={this.props.translate('delete')}
                onClick={() => this.props.removedItem(this.props.item)}
              >
                <DeleteIcon style={{fontSize: 16}}/>
              </span>
            )}            
            {checkPrivileges('binderUpdate') && (
              <span
                className='action-item hovered'
                title={this.props.translate('edit')}
                onClick={() => this.props.editItem(this.props.item)}
              >
                <EditIcon style={{fontSize: 16}}/>
              </span>
            )}
            {device ? (
              <span>
                <img
                    width={24}
                    height={24}
                    src={
                      '/assets/category/default/'+(device.category ||
                        'default')+'.svg'}
                    
                    alt=''
                  />
              </span>
            ) : <span
            className='action-item'
          >
            <LinkOffIcon style={{fontSize: 16}}/>
          </span>}
          </div>
        </Link>
      </li>
    )
  }
}

export const TrailerShortList = withLocalize(Trailer)
