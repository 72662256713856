import React, { Component, Fragment, useState } from 'react'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { getNotificationType } from '../../../Actions/Notifications'
import Stepper from './../../../Components/common/Stepper'
import Button from './../../../Components/common/Button'
import Checkbox from './../../../Components/common/Checkbox'
import TextField from './../../../Components/common/SearchField'
import MenuItem from './../../../Components/common/MenuItem'
import Grid from '@material-ui/core/Grid'
// import DateRangePicker from './../../../Components/common/DateRangePicker'
import DateRangePicker from './../../../Components/common/DateRangeHistoryPicker'

import Loader from '../../Loader'
import Scrollbar from 'react-scrollbars-custom'
import DeviceSelector from '../../../Components/Devices/DeviceSelector'
import GroupSelector from '../../../Components/Devices/GroupSelector'
import { connect } from 'react-redux'
import DriverSelector from '../../../Components/Devices/DriverSelector'
import NotificationSelector from '../../../Components/Devices/NotificationSelector'
import NotificationTypeSelector from '../../../Components/Devices/NotificationTypeSelector'
import Slider from '@material-ui/core/Slider'
import moment from 'moment'
import GeofenceSelector from '../../../Components/Devices/GeofenceSelector'
import Autocomplete from '../../../Components/common/Autocomplete'
import { Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ItemGroupSelector from '../../../Components/Devices/ItemGroupSelector'
import { RemoteItems } from '../../../Hooks/useRemoteItems'
import VehicleSelector from '../../../Components/Devices/VehicleSelector'
import instance from '../../../axios'
import { errorHandler } from '../../../Helpers'

class ReportsSidebar extends Component {
  constructor (props) {
    super(props)
    this.reportDetail = this.reportDetail.bind(this)
  }

  reportDetail (e) {
    this.props.reportDetail(e.target.checked)
  }

  createReport = type => {
    this.props.collapseSidebar()
    this.props.createReport(type)
  }

  openReportGenerator = type => {
    this.props.collapseSidebar()
    this.props.openReportGenerator()
  }

  componentDidMount () {
    if (!this.props.notificationType.length) {
      // fetch(`/api/notifications/types/`, {
      //   method: 'GET',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/notifications/types/`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(notifications => {
              this.props.dispatch(getNotificationType(notifications))
            })
        //   } else {
        //     throw response
        //   }
        // })
        .catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
    }
  }

  getNotificationType = () => {
    let array = []
    if (this.props.notificationType.length) {
      this.props.notificationType.map((item, i) => {
        array.push({
          name: this.props.translate('notification.' + item.type),
          id: item.type
        })
        return null
      })
      return array
    } else {
      return array
    }
  }

  valuetext = value => {
    return moment('2016-03-13')
      .startOf('day')
      .add(value, 'minutes')
      .format('HH:mm')
  }

  getStepContent = (id) => {
    switch (id) {
      case 1:
        return (<Grid container className='form-group'>
      <Grid item xs={12}>
        <TextField
          select
          label={this.props.translate('selectTemplate')}
          value={this.props.reportType || ''}
          variant='outlined'
          onChange={this.props.reportTypeChange}
          margin='dense'
          fullWidth
        >
          {this.props.templates.map(option => (
            (option.rpTmpl.active ? <MenuItem key={option.rpTmpl.id} value={option.rpTmpl.id}>
              {option.rpTmpl.name}
            </MenuItem>: null)
          ))}
        </TextField>
      </Grid>
      {this.props.allowFields.includes('driver') ||
      this.props.allowFields.includes('device') ||
      this.props.allowFields.includes('vehicle') ? (
        <Grid item xs={12} style={{ padding: '8px 0 0' }}>
          <div className='report-sidebar-switch'>
            {this.props.allowFields.includes('device') ? (
              <Button
                onClick={e =>
                  this.props.reportTypeSelection('device')
                }
                className={
                  (this.props.reportUnits === 'device'
                    ? 'active'
                    : '') + ' button-inactive'
                }
              >
                {this.props.translate('trackers')}
              </Button>
            ) : null}
            {this.props.allowFields.includes('vehicle') ? (
              <Button
                onClick={e =>
                  this.props.reportTypeSelection('vehicle')
                }
                className={
                  (this.props.reportUnits === 'vehicle'
                    ? 'active'
                    : '') + ' button-inactive'
                }
              >
                {this.props.translate('sharedVehicle')}
              </Button>
            ) : null}
            {this.props.allowFields.includes('driver') ? (
              <Button
                onClick={e =>
                  this.props.reportTypeSelection('driver')
                }
                className={
                  (this.props.reportUnits === 'driver'
                    ? 'active'
                    : '') + ' button-inactive'
                }
              >
                {this.props.translate('sharedDrivers')}
              </Button>
            ) : null}
          </div>
          <div className='report-sidebar-switch'>
            <a className={"btn-link "+(this.props.groupView === 1 ? 'active': '')} href={null} onClick={() => this.props.switchGroupView(1)}>
              {this.props.reportUnits === 'device' ? this.props.translate('trackers') : this.props.reportUnits === 'driver' ? this.props.translate('driver') : this.props.reportUnits === 'vehicle' ? this.props.translate('sharedVehicle') : ''}
            </a>
            <a className={"btn-link "+(this.props.groupView === 2 ? 'active': '')} href={null} onClick={() => this.props.switchGroupView(2)}>
              {this.props.reportUnits === 'device' ? this.props.translate('groups') : this.props.reportUnits === 'driver' ? this.props.translate('driverGroups') : this.props.reportUnits === 'vehicle' ? this.props.translate('vehicleGroups') : ''}
            </a>
          </div>
        </Grid>
      ) : null}
      {this.props.reportUnits === 'device' &&
      this.props.allowFields.includes('device') ? (
        <div>
          {this.props.groupView === 2 ? 
            <RemoteItems type="Device">
              {({data, isLoaded}) => isLoaded ? <ItemGroupSelector
              groupList={data}
              value={this.props.groupId}
              onChange={this.props.selectedGroups}
              rows={8}
            /> : <Loader defaultStyle />}
            </RemoteItems>
            :   
            <DeviceSelector
              value={this.props.deviceId}
              onChange={this.props.selectedDevices}
              rows={8}
            />}
        </div>
      ) : this.props.reportUnits === 'driver' && this.props.allowFields.includes('driver') ? (
        <div>
          {this.props.groupView === 2 ? 
            <RemoteItems type="Driver">
              {({data, isLoaded}) => isLoaded ? <ItemGroupSelector
                groupList={data}
                value={this.props.groupId}
                onChange={this.props.selectedGroups}
                rows={8}
              /> : <Loader defaultStyle />}
            </RemoteItems>
            : <DriverSelector
            value={this.props.driverId}
            disableModal={true}
            onChange={this.props.selectedDrivers}
            rows={8}
          />}
        </div>
        
      ) : this.props.reportUnits === 'vehicle' && this.props.allowFields.includes('vehicle') ? (
        <div>
          {this.props.groupView === 2 ? 
            <RemoteItems type="Vehicle">
              {({data, isLoaded}) => isLoaded ? <ItemGroupSelector
                groupList={data}
                value={this.props.groupId}
                onChange={this.props.selectedGroups}
                rows={8}
              /> : <Loader defaultStyle />}
            </RemoteItems>
            : <VehicleSelector
            value={this.props.vehicleId}
            onChange={this.props.selectedVehicles}
            rows={8}
          />}
        </div>
        
      ) : null }
    </Grid>)
    case 2: 
    return (<Grid container className='form-group'>
        <Grid item xs={12}>
          <DateRangePicker
            fill
            todayOnly={this.props.reportType ===21 ? true : false}
            error={
              this.props.timeError
            }
            selectedDate={this.props.selectedDate}
            label={
              this.props.translate('reportFrom') +
              ' ' +
              this.props.translate('reportTo')
            }
            onEvent={this.props.showDates}
            reportsRanges
          />
        </Grid>

        {this.props.reportType !==21 && ( <>
        <Grid item xs={12}>
          <h4 style={{ marginTop: 15, marginBottom: 10 }}>
            {this.props.translate('reportForDays')}
          </h4>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              border: `1px solid ${this.props.themecolors.textColor}15`,
              display: 'inline-flex'
            }}
          >
            {day.map(option => (
              <div
                key={option.id}
                style={{
                  display: 'inline-flex',
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  marginRight: option.id === '7' ? 0 : 1,

                  backgroundColor: this.props.controlDays.includes(
                    parseInt(option.id)
                  )
                    ? this.props.themecolors.themeLightColor
                    : 'transparent',
                  color: this.props.controlDays.includes(
                    parseInt(option.id)
                  )
                    ? this.props.themecolors.themeInverse : this.props.themecolors.textColor
                }}
              >
                <label
                  style={{
                    padding: 5,
                    minWidth: 30,
                    cursor: 'pointer'
                  }}
                >
                  <input
                    type='checkbox'
                    key={option.id}
                    checked={this.props.controlDays.includes(
                      parseInt(option.id)
                    )}
                    onChange={e =>
                      this.props.onDayChange(e.target.checked, option)
                    }
                    style={{ display: 'none' }}
                  />
                  {this.props.translate(option.name)}
                </label>
              </div>
            ))}
          </div>
        </Grid>
        <Grid item xs={12}>
          <h4 style={{ marginBottom: 30 }}>
            {this.props.translate('reportBetweenTime')}
          </h4>
        </Grid>
        <Grid item xs={12}>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Slider
              value={this.props.controlTime}
              onChange={this.props.handleTimeControl}
              valueLabelDisplay='on'
              aria-labelledby='range-slider'
              valueLabelFormat={this.valuetext}
              max={1439}
              classes={{
                root: 'theme-range-slider',
                thumb: 'theme-range-slider-thumb',
                track: 'theme-range-slider-track',
                rail: 'theme-range-slider-rail',
                valueLabel: 'theme-range-slider-label'
              }}
              step={5}
            />
          </div>
        </Grid>
        </>)}


      </Grid>)
    case 3: 
      return (<Grid container className='form-group'>
                
    {this.props.allowFields.includes('eventType') ||
    this.props.allowFields.includes('notification') ? (
      <Grid item xs={12}>
        <div className='report-sidebar-switch'>
          {this.props.allowFields.includes('notification') ? (
            <Button
              onClick={e =>
                this.props.handleChangeType('notification')
              }
              className={
                this.props.eventType === 'notification'
                  ? ''
                  : 'button-inactive'
              }
            >
              {this.props.translate("event")}
            </Button>
          ) : null}
          {this.props.allowFields.includes('eventType') ? (
            <Button
              onClick={e =>
                this.props.handleChangeType('eventType')
              }
              className={
                this.props.eventType === 'eventType'
                  ? ''
                  : 'button-inactive'
              }
            >
              {this.props.translate("type")}
            </Button>
          ) : null}
        </div>
        {this.props.eventType === 'eventType' &&
        this.props.allowFields.includes('eventType') ? (
          <NotificationTypeSelector
            onChange={this.props.selectedNotifications}
          />
        ) : null}
        {this.props.eventType === 'notification' &&
        this.props.allowFields.includes('notification') ? (
          <NotificationSelector
            onChange={this.props.selectedEvents}
          />
        ) : null}
      </Grid>
    ) : null}
    </Grid>)

    case 4: 
      return (<Grid container className='form-group' spacing={2}>
        {this.props.allowFields.includes('geofence') ? <>
        <Grid item xs={12}><GeofenceSelector
          value={this.props.geofenceId}
          onChange={this.props.selectedGeofences}
          rows={8}
        />
        </Grid>
        <Grid item xs={12}>
          <TextField type='number' onChange={e => this.props.handleChange('geoTimeThreshold', e)} label={this.props.translate("geoTimeThreshold") +" ("+this.props.translate("sharedMinutes")+")"} value={this.props.geoTimeThreshold} fullWidth/>
        </Grid>
        </> : null}
      </Grid>)
    
    case 5: 
      return (<Grid container className='form-group'>
        {this.props.allowFields.includes('sensor') || this.props.allowFields.includes('digitalSensor') ? 
          <SensorSelector digital={this.props.allowFields.includes('digitalSensor')} allSensors={this.props.allSensors} value={this.props.sensorValues} handleChange={this.props.selectedSensors} translate={this.props.translate} />
        : null}
      </Grid>)

    
    default:
        return null
    }
  }

  render () {

    
      const viewScreen = (
        <form style={{ padding: "16px 0 16px 10px" }}>
          {this.props.loading ? (
            <Loader />
          ) : (
            <>
              <h3 className='page-title'>
                {this.props.translate('reportForm')}
              </h3>
              <Stepper download emails translate={this.props.translate} activeStep={this.props.activeStep} reportType={this.props.reportType} getStepContent={this.getStepContent} steps={this.props.steps} handleNext={this.props.goToNextStep} handleBack={this.props.goToBackStep} />
            </>
          )}
        </form>
      );

      return (
        <aside className='fms-sidebar sidebar-bg-layer'>
          <Scrollbar>{viewScreen}</Scrollbar>
        </aside>
      )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    notificationType: state.notificationType
  }
}

const day = [
  {
    id: '1',
    name: 'su'
  },
  {
    id: '2',
    name: 'mo'
  },
  {
    id: '3',
    name: 'tu'
  },
  {
    id: '4',
    name: 'we'
  },
  {
    id: '5',
    name: 'th'
  },
  {
    id: '6',
    name: 'fr'
  },
  {
    id: '7',
    name: 'sa'
  }
]

export default connect(mapStateToProps)(ReportsSidebar)


const SensorSelector = (props) => {
  const [scale, setScale] = useState(props.value)

  const addMore = () => {
      const newscale = [...scale]
      newscale.push({ input: "", index: "", value: ""})
      setScale(newscale)
  }

  const deleteRow = (index) => {
    let newscale = [...scale]
    newscale.splice(index, 1)
    setScale(newscale)

    props.handleChange(newscale)
  }
  
  const handleChange = (name, value, row) => {
    if(name === 'allSensors') {
      if(value.target.checked) {
        const reset = [{input: '', index: '', value: 'allSensors'}];
        setScale(reset)
        props.handleChange(reset)
      }
      else {
        const reset = [{input: '', index: '', value: ''}];
        setScale(reset)
        props.handleChange(reset)
      }
    } else {
      row[name] = value
      row.value = row.input+(row.index !== undefined ? row.index : "")

      const newscale = [...scale];
      setScale(newscale);

      props.handleChange(newscale)
    }
   
  }

  const suggestions = props.digital ? [
    'ignition',
    'panicButton',
    'externalPower',
    'enginStatus',
    'carAlarm',
    'doors',
    'customDiscrete',
    'devicePowerCut',
    ] : [
    "driverUniqueId",
    "passengerUniqueId",
    "binUniqueId",
    "trailerUniqueId",
    "ignition",
    "panicButton",
    "externalPower",
    "enginStatus",
    "carAlarm",
    "doors",
    "fuel",
    "temperature",
    "rpm",
    "boardPower",
    "mileage",
    "speed",
    "counter",
    "averagFuelConsumption",
    "instantFuelConsumption",
    "flowMeter",
    "humidity",
    "custom",
    "weight"
  ];
  
  return <div style={{marginBottom: 16, width: '100%'}}>
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 0, marginTop: -22 }}>
        <div className="pull-right" style={{display: 'inline-flex', alignItems: 'center'}}>
          {!props.allSensors && <Tooltip title={props.translate('addMore')} classes={{
            popper: 'menu-popper',
            tooltip: 'menu-popper-tooltip'
          }}>
          <a
            href={null}
            onClick={addMore}
            style={{ color: 'inherit' }}
          >
            <AddBoxIcon/>
          </a>
        </Tooltip>}
        <Checkbox checked={props.allSensors} onChange={e => handleChange('allSensors', e)} label={props.translate('selectAll')} />
        </div>
        
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
      {!props.allSensors && scale.map((row, index) => (
          <Grid
            key={index}
            container
            spacing={0}
            className='sensors-rows'
          >
            <Grid item xs={scale.length > 1 ? 11 : 12}>
              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Autocomplete
                    value={row.input || ''}
                    suggestions={suggestions}
                    handleChange={(e, v) => handleChange(e, v, row)}
                    fieldName='input'
                    label={props.translate('sensorType')}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    type='number'
                    value={row.index}
                    onChange={e => handleChange('index', e.target.value, row)}
                    variant='outlined'
                    margin='dense'
                    label={props.translate('index')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            {scale.length > 1 ? (
              <Grid
                item
                xs={1}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex'
                }}
              >
                <DeleteIcon
                  onClick={e => deleteRow(index)}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </div>
}