import React, { Component } from 'react'
import { useLocation } from 'react-router-dom'
import Notifications from 'react-notification-system-redux'
import './../Login/Style.scss'
import TextField from './../../Components/common/TextField'
import { withLocalize } from 'react-localize-redux'
import Button from '../../Components/common/Button'
import { connect } from 'react-redux'
import Loader from '../../Layout/Loader';
import Style from 'style-it'
import { themes } from '../../Reducers/Theme'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'

import { FormHelperText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';


function useQuery () {
  return new URLSearchParams(useLocation().search)
}

export const ResetPassword = fn => {
  let query = useQuery()
  let key = 'dG9rZW5Gb3JQYXNzd29yZFJlc2V0'
  return <Params {...fn} tokenKey={key} token={query.get(key)} />
}

class Params1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {
        loading: true,
        npass: ''
      }
    }
    this.checkRequiredFields = this.checkRequiredFields.bind(this)

  }

  componentWillMount () {
    this.setState(
      {
        form: { ...this.state.form, [this.props.tokenKey]: this.props.token }
      },
      () => {
        this.verifyToken()
      }
    )
  }

  verifyToken = () => {
    // fetch('/api/password/validate', {
    //   method: 'POST',
    //   body: new URLSearchParams(`${this.props.tokenKey}=${this.props.token}`)
    // })

    instance({
      url: `/api/password/validate`,
      method: `POST`,
      data: (`${this.props.tokenKey}=${this.props.token}`)
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(res => {
          // if (res.status === 'success') {
            this.setState({ loading: false })
          // } else {
          //   this.props.dispatch(
          //     Notifications.error({
          //       message: this.props.translate('tokenExpired'),
          //       autoDismiss: 10
          //     })
          //   )

          //   this.props.history.push('/logout')
          // }
      //   })
      // } else {
      //   this.props.dispatch(
      //     Notifications.error({
      //       message: this.props.translate('invalid token'),
      //       autoDismiss: 10
      //     })
      //   )

      //   this.props.history.push('/logout')
      //     throw response
      //   }
      })
      .catch(error => {errorHandler(error,this.props.dispatch,this.errorCallBack)})
      // .catch(e => {
      //   this.props.dispatch(
      //     Notifications.error({
      //       message: this.props.translate('invalid token'),
      //       autoDismiss: 10
      //     })
      //   )

      //   this.props.history.push('/logout')
      // })
  }
  errorCallBack = (data) =>  {
    this.props.history.push('/logout')
  }

  switchView = view => {
    this.setState({ view })
  }

  drawParams = json => {
    console.log(json)
  }

  handleChange = event => {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value
      }
    }, ()=> this.verifyPassword())
  }

  verifyPassword = () => {
    // Define regular expressions for password criteria
    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    // Check each criteria
    const isLengthValid = this.state.form.npass.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(this.state.form.npass);
    const hasUpperCaseValid = hasUpperCase.test(this.state.form.npass);
    const hasNumberValid = hasNumber.test(this.state.form.npass);
    const hasSpecialCharValid = hasSpecialChar.test(this.state.form.npass);

    // Check if all criteria are met
    if(isLengthValid){
      this.setState({lengthCheck :  true})
    }else{
      this.setState({lengthCheck :false})
    }
    if(hasLowerCaseValid){
      this.setState({lowerCaseCheck:  true})
    }else{
      this.setState({lowerCaseCheck:false })
    }
    if(hasUpperCaseValid){
      this.setState({upperCaseCheck :  true})
    }else{
      this.setState({upperCaseCheck :false })
    }
    if(hasNumberValid){
      this.setState({ numberCheck : true})
    }else{
      this.setState({numberCheck :false })
    }
    if(hasSpecialCharValid){
      this.setState({ characterCheck : true})
    }else{
      this.setState({ characterCheck : false})
    }
   

    const isPasswordValid =
      isLengthValid && hasLowerCaseValid && hasUpperCaseValid && hasNumberValid && hasSpecialCharValid;
   

    this.setState({ 
      isValidPassword: isPasswordValid },
      () => this.checkRequiredFields());
  };


  checkRequiredFields () {
    let { npass} = this.state.form
    let { isValidPassword } = this.state
    
  
    
    if (npass) {
      this.setState({
        isVisableUserBtn: true
            })
    }
         else {
          this.setState({
            isVisableUserBtn: false
          })
        }

   
  }
  handleReset = event => {
    event.preventDefault()

    // fetch('/api/password/reset', {
    //   method: 'POST',
    //   body: new URLSearchParams(
    //     `${this.props.tokenKey}=${this.props.token}&npass=${this.state.form.npass}`
    //   )
    // })
    instance({
      url: `/api/password/reset`,
      method: `POST`,
      data: (`${this.props.tokenKey}=${this.props.token}&npass=${this.state.form.npass}`)
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(res => {
          // if (res.status === 'success') {
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('passwordIsChanged'),
                autoDismiss: 10
              })
            )

            this.props.history.push('/logout')
          // } else {
          //   this.props.dispatch(
          //     Notifications.error({
          //       message: this.props.translate('somthingWentWrongMessage'),
          //       autoDismiss: 10
          //     })
          //   )
          // }
      //   })
      // } else {
      //   this.props.dispatch(
      //     Notifications.error({
      //       message: this.props.translate('somthingWentWrongMessage'),
      //       autoDismiss: 10
      //     })
      //   )
      //     throw response
      //   }
      })
      .catch(error => {errorHandler(error, this.props.dispatch)})
  }

  render () {
    const { form } = this.state

    return (
    	<div className="loginWrapper">
        <Style>
          {`
          a {
            color: ${this.props.themecolors.textColor}
          }
          .loginWrapperInner {
            background: ${this.props.themecolors.backgroundColor}
          }
          .login-footer {
            border-top: 1px solid ${this.props.themecolors.themeLightColor};
          }
          `}
          
        </Style>
        {/* <ResetPassword callback={this.drawParams} /> */}
        <div className="loginWrapperInner">
          {this.state.loading === true ? <Loader /> : 
          <form
            className='loginForm'
            onSubmit={this.handleReset}
            autoComplete='off'
          >
            <div className="login-logo-wrapper">
              <img
                className='login-logo'
                src={this.props.whiteLabling.logo}
                alt='logo'
              />
            </div>

            <TextField
              themecolors={this.props.themecolors}
              id='npass'
              variant='outlined'
              label={this.props.translate('userNewPassword')+ ' *'} 
              type='password'
              value={form.npass || ''}
              onChange={this.handleChange}
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
            <FormHelperText>
               <div style={{display:"flex", alignItems:"center"}}>
                {this.state.lengthCheck?
                  <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                  <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                  <span style={{marginLeft:"5px"}}>{this.props.translate('Length must be atleast 8 characters')}</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.numberCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>{this.props.translate('Must contain atleast one Number')}</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.upperCaseCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>{this.props.translate('Must contain atleast one Uppercase letter')}</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.lowerCaseCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>{this.props.translate('Must contain atleast one Lowercase letter')}</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.characterCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>{this.props.translate('Must contain atleast one Special character (!@#$%^&*(),.?:{}|<></>)')}</span>
                </div> 
            </FormHelperText>
            <p style={{textAlign: 'center', marginTop: 0}}>
              <Button
                type='submit'
                className="btn-submit"
                disabled={!this.state.isVisableUserBtn}
                themecolors={this.props.themecolors}
              >
                {this.props.translate('userReset')}
              </Button>
            </p>

            <div className="login-footer">
              <a href={this.props.whiteLabling && this.props.whiteLabling.termsLink}>
                {this.props.translate('about')}
              </a>
              <a href={this.props.whiteLabling && this.props.whiteLabling.termsLink}>
                {this.props.translate('termsAndConditions')}
              </a>
              <a href={this.props.whiteLabling && this.props.whiteLabling.privacyPolicyLink}>
                {this.props.translate('privacy')}
              </a>
            </div>

          </form>}
        </div>
        <div className="white-label-bg" style={{backgroundImage: `url(${this.props.whiteLabling.logInPageImage})`}}></div>
      </div>
    )
  }
}
const mapState = state => ({
  themecolors: themes[2]
})
const mapStateToProps = connect(mapState)
export const Params = mapStateToProps(withLocalize(Params1))
