import React, { Component } from 'react'
import Button from './../../../Components/common/Button'
import Loader from './../../Loader'
import Grid from '@material-ui/core/Grid'
import DateRangePicker from './../../../Components/common/DateRangePicker'
import Style from 'style-it'
import { checkPrivileges } from '../../../Helpers'
import Scrollbar from 'react-scrollbars-custom'
import DeviceSelector from '../../../Components/Devices/DeviceSelector'
import SearchDevices from '../../../Components/Devices/searchItems'
import Slider from '@material-ui/core/Slider'
import moment from 'moment'
import TextField from '../../../Components/common/TextField'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default class Timemachine extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tab: 'default',
      flag:true
    }
    this.goBack = this.goBack.bind(this)
  }
  createReport = type => {
    this.props.collapseSidebarWithButton()
    this.props.createReport(type)
  }
  goBack () {
    this.props.openForm()
  }

  valuetext = value => {
    return moment('2016-03-13')
      .startOf('day')
      .add(value, 'minutes')
      .format('HH:mm')
  }
  selectTab=(type)=>{
    this.setState({tab:type})
  }
  render () {
    let viewScreen
    viewScreen = (
      <div>
      {checkPrivileges('geopointtimeLapse') && (
        <>
        <Button
           onClick={()=>this.selectTab("default")} 
           style={{
              backgroundColor:this.state.tab === "default"?
              this.props.themecolors.menuBackgroundColor : 
              this.props.themecolors.menuActiveBackground ,
              width:"50%",
            }}
         >   
          {this.props.translate('timemachineForm')}
        </Button>
        <Button 
           onClick={()=>this.selectTab("report")} 
           style={{
              backgroundColor:this.state.tab === "report"?
              this.props.themecolors.menuBackgroundColor : 
              this.props.themecolors.menuActiveBackground,
              width:"50%",
            }}
        >
          {this.props.translate('REportForm')}
        </Button>
        </>)}
      <form style={{ padding: 20 }}>
      {!checkPrivileges('geopointtimeLapse') && (
        <h3 className='page-title'>
          {this.props.translate('timemachineForm')}
        </h3>)}
        <Grid container spacing={1}>
          <Grid item xs={12}>
             <DateRangePicker
              fill
              {...this.props.timelineRanges}
              selectedDate={this.props.selectedDay}
              // selectedDate={this.props.selectedDate}

              label={
                this.props.translate('selectedDay')
              }
              onEvent={this.props.showDates}
              timeMachine
              datepickerOnly={false}
            />
          </Grid>
       
          <Grid item xs={12} style={{margin:"25px 0px 5px 0px"}}>
          <Slider
              value={this.props.controlTime}
              onChange={this.props.handleTimeControl}
              valueLabelDisplay='on'
              aria-labelledby='range-slider'
              valueLabelFormat={this.valuetext}
              max={1439}
              classes={{
                root: 'theme-range-slider',
                thumb: 'theme-range-slider-thumb',
                track: 'theme-range-slider-track',
                rail: 'theme-range-slider-rail',
                valueLabel: 'theme-range-slider-label'
              }}
              step={5}
            />

          </Grid>
          {this.state.tab === "report" && (
      <>
       <Grid item xs={12} style={{paddingLeft:"0px"}}>
           <label className='checkbox' style={{display:"inline-block"}}>
               <input
         type="checkbox"name={`raw`}
                                 checked={this.props.isCheckRaw}
                                 onChange={(event) => this.props.handleChangeRaw(event)}
                               />
                                               <span className='theme-input-checkbox' style={{display:"flex",alignItems:"center"}}>
                                 <CheckBoxIcon className='on-active material-icons' style={{ fontSize: 22, color: 'inherit' }} />
                                 <CheckBoxOutlineBlankIcon className='on-inactive material-icons' style={{ fontSize: 22, color: 'inherit' }} />
                                 <label style={{ marginLeft: "4px" }}>Add raw</label>
                               </span>
            </label>
           </Grid>
      {
             this.props.isCheckRaw?
             <Grid item xs={12} style={{margin:"5px 0px 5px 0px"}}>
             <TextField
                 id='raw'
                 required
                 label={this.props.translate('enterLat,Lng')}
                 type='text'
                 value={this.props.rawLatLng}
                 onChange={(e)=>this.props.handleChange('rawLatLng',e.target.value)}
                 variant='outlined'
                 margin='dense'
                 fullWidth
               />
           </Grid>:<Grid item xs={12} style={{margin:"5px 0px 5px 0px", display:"flex",  }}>
              <TextField
                 id='lat'
                 required
                 label={this.props.translate('lat')}
                 type='number'
                 value={this.props.lat || 0}
                 onChange={(e)=>this.props.handleChange('lat',e.target.value)}
                 variant='outlined'
                 margin='dense'
                 fullWidth
               />
                <TextField
                 id='lon'
                 required
                 label={this.props.translate('lon')}
                 type='number'
                 value={this.props.lon || 0}
                 onChange={(e)=>this.props.handleChange('lon',e.target.value)}
                 variant='outlined'
                 margin='dense'
                 fullWidth
                 style={{marginLeft:"5px"}}
               />
           </Grid>
           }
           <Grid item xs={12} style={{margin:"5px 0px 5px 0px"}}>
             <TextField
                 id='radius'
                 required
                 label={this.props.translate('radius')}
                 type='number'
                 value={this.props.radius || 0}
                 onChange={(e)=>this.props.handleChange('radius',e.target.value)}
                 variant='outlined'
                 margin='dense'
                 fullWidth
               />
           </Grid>
           </>)}
        </Grid>
        {this.state.tab === "default" && (
        <SearchDevices
            isMulti={false}
            api='devices'
            isClearable
            onChange={this.props.selectedDevices}
            value={this.props.deviceId}
            placeholder={this.props.translate('searchUnits')}
          />)}
        <Button
          disabled={this.state.tab === "default" ? !this.props.validatedForm : !this.props.validatedReportForm}
          style={{ marginTop: 20 }}
          variant='contained'
          onClick={()=>this.createReport(this.state.tab)}
        >
          {this.props.translate('submit')}
        </Button>
      </form>
      </div>
    )

    if (viewScreen) {
      return (
        <Style>
          {`
            .material-icons {vertical-align: middle; color: inherit}
          `}
          <aside className='fms-sidebar sidebar-bg-layer'>
            {checkPrivileges('track') && <Scrollbar>{viewScreen}</Scrollbar>}
          </aside>
        </Style>
      )
    } else {
      return this.props.trackLoading ? <Loader /> : null
    }
  }
}
