import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import Style from 'style-it'
import SearchField from '../common/SearchField'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
import { fetchMoreDevices, searchDevices } from '../../Actions/Devices'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Layout/Loader'
import { setAttributeFormat } from '../../Helpers'
class DeviceSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: {},
      others: [],
      search: '',
      isChecked: false
    }
  }
  componentWillMount () {
    this.props.dispatch(fetchMoreDevices(true, this.props.rows || 10))

    const list = {}
    const others = []
    if (this.props.value && this.props.value.length && this.props.devices3 && this.props.devices3.length) {
      this.props.value.map(d => {
        const found = this.props.devices3.find(dev => dev.id === parseInt(d));
        if(found) {
          list[d] = true
        }
        else {
          others.push(d)
        }
        return null
      })
    }
    this.setState({ list, others })
  }

  componentWillReceiveProps (nextprops) {
     const list = {}
    const others = []
    if (nextprops.value && nextprops.value.length && nextprops.devices3 && nextprops.devices3.length) {
        nextprops.value.map(d => {
        const found = nextprops.devices3.find(dev => dev.id === parseInt(d));
        if(found) {
          list[d] = true
        }
        else {
          others.push(d)
        }
        return null
      })
    }

    this.setState({ list, others })

  }

  componentWillUnmount () {
    this.setState({
      list: {},
      search: '',
      isChecked: false
    })
  }
  sendToParent () {
    if (this.props.onChange) {
      this.props.onChange([...Object.keys(this.state.list), ...this.state.others])
    }
  }
  onChange = (e, v) => {
    let list = { ...this.state.list }
    if (e.target.checked) {
      list[v.id] = true
    } else {
      delete list[v.id]
    }
    this.setState(
      {
        list,
        isChecked: this.props.devicesList.length === Object.keys(list).length
      },
      () => {
        this.sendToParent()
      }
    )
  }
  searchItem = text => {
    this.setState({ search: text.target.value }, () => {
      this.props.dispatch(searchDevices({ device: this.state.search }))
    })
  }

  toggleItems = event => {
    if (event.target.checked) {
      let list = {}
      let items = []
      if (this.state.search) {
        items = this.props.devicesList.data
      } else {
        items = this.props.devices3
      }
      items.map(d => (list[d.id] = true))
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent()
      })
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent()
      })
    }
  }
   millisecondsToHMS(milliseconds) {
    let totalSeconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = totalSeconds % 60;

    // Pad the values to ensure two digits
    hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');
    seconds = String(seconds).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}




  render () {
    const endMessage =
      this.props.devicesList && this.props.devicesList.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {this.props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {this.props.translate('notFound')}{' '}
        </p>
      )
    return (
      <Style>
        {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
            background: ${this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors.themeLightInverse};
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            background: ${this.props.themecolors.themeListingColor};
          }
          .selector-list {
            padding: 0;
            margin: 0;
            color: ${this.props.themecolors.themeListingInverse};
            list-style: none
          }
          .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px;
            display: flex;
            justify-content: space-between
        }`}
        <div style={{ width: '100%' }}>
          <div
            className='section-head clearfix section-head-filter'
            style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
          >
            <ul className='filter-row pull-left'>
              <li>
                <SearchField
                  label={this.props.translate('searchUnits')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
               {!this.props.readOnly && !this.props.singleSelect ? (
                <li style={{ paddingRight: 0, paddingTop: 3 }}>
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={this.toggleItems}
                    label={this.props.translate('selectAll')}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!this.props.readOnly && !this.props.singleSelect ? (
            <div className='selector-count'>
              <span><span style={{marginRight: 3}}>{Object.keys(this.state.list).length}</span>
              {this.props.translate('UnitsSelected')}</span>

              <span><span style={{marginRight: 3}}>{Object.keys(this.state.others).length}</span>
              {this.props.translate('more')}</span>
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
              height: 32 * (this.props.rows || 10)
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id='scrollableDeviceSelector'
                  />
                )
              }
            }}
          >
            {this.props.devicesList.total ? (
              <InfiniteScroll
                dataLength={this.props.devicesList.data.length}
                next={() => this.props.dispatch(fetchMoreDevices())}
                hasMore={this.props.devicesList.hasNext}
                loader={<Loader defaultStyle={true} />}
                scrollableTarget='scrollableDeviceSelector'
                endMessage={endMessage}
              >
                <ul className='selector-list'>

                  {this.props.devicesList.data.map(row => {
                 
                  const currentTime = new Date(); // Get the current time
                  const lastUpdateTime = new Date(row.lastUpdate); // Convert lastUpdate to a Date object
                  // Calculate the time difference in milliseconds
                  const timeDifference = currentTime.getTime() - lastUpdateTime.getTime();

                  const getVehicle =  this.props.positions.find(item=> item.deviceId === row.id)
                  // const timeString = this.millisecondsToHMS(getVehicle?.attributes?.stopTime);
                  // const timeString1 = setAttributeFormat("hours",getVehicle?.attributes?.stopTime);

                  // console.log("time",getVehicle?.attributes?.stopTime,timeString1)
                  return (
                    <li key={row.id}>
                      <label className={this.state.list[row.id] ? 'active' : null}>
                        {!this.props.readOnly ? (
                          <span className='selector-check'>
                            <Checkbox
                              checked={this.state.list[row.id]}
                              value={row}
                              onChange={this.onChange}
                            />
                          </span>
                        ) : null}
                        {this.props.hideIcons ? null : (
                          <span className='selector-image'>
                            <img
                              src={'/assets/category/default/' + (row.category || 'default') + '.svg'}
                              // alt={row.name}
                            />
                          </span>
                        )}
                        <span className='selector-label'>{row.name}</span>
                        {this.props.dashboradDetails &&(<span style={{marginLeft:"5px"}}> | {this.props.motionSelectorName === "Moving"  ? 
                          setAttributeFormat('speed', getVehicle?.speed)
                           : 
                          this.props.motionSelectorName === "Idling" ||
                          // this.props.motionSelectorName === "statusParking" || 
                          // this.props.motionSelectorName === "stop" 
                          this.props.motionSelectorName === "Towing" ?
                          setAttributeFormat('hours', timeDifference) 
                          : 
                          this.props.motionSelectorName==="statusParking"|| this.props.motionSelectorName === "stop" ?
                          setAttributeFormat("hours",getVehicle?.attributes?.stopTime) :
                          this.props.motionSelectorName === "statusOffline" ||  this.props.motionSelectorName === "notRegistered" 
                          || this.props.motionSelectorName === "powerCut" ||  this.props.motionSelectorName === "gpsNotUpdated"  ?
                         <>  </> :
                          "" 
                        }
                          </span>)}
                      </label>
                    </li>
                  );
                })}
                  {/* {this.props.devicesList.data.map(row => (
                    <li key={row.id}>
                      <label
                        className={this.state.list[row.id] ? 'active' : null}
                      >
                        {!this.props.readOnly ? (
                          <span className='selector-check'>
                            <Checkbox
                              checked={this.state.list[row.id]}
                              value={row}
                              onChange={this.onChange}
                            />
                          </span>
                        ) : null}
                        {this.props.hideIcons ? null : (
                          <span className='selector-image'>
                            <img
                              src={
                                '/assets/category/default/' +
                                (row.category || 'default') +
                                '.svg'
                              }
                              // alt={row.name}
                            />
                          </span>
                        )}
                        <span className='selector-label'>{row.name}</span>
                        <span className='selector-label'>{row.lastUpdate}</span>
                      </label>
                    </li>
                  ))} */}
                </ul>
              </InfiniteScroll>
            ) : this.props.devicesList.hasNext ||
              this.props.devicesList.isFetching ? (
              <Loader defaultStyle={true} />
            ) : null}
          </Scrollbar>
        </div>
      </Style>
    )
  }
}

const mapState = state => ({
  devices3: state.devices3,
  devicesList: state.devices,
  themecolors: state.themeColors,
  positions :state.positions
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(DeviceSelector))
