import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import { withLocalize } from 'react-localize-redux'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'
import {
  Map,
  TileLayer,
  FeatureGroup,
  Circle,
  Polygon,
  Tooltip,
  Polyline,
  Marker,
  ZoomControl,
  LayersControl
} from 'react-leaflet'
import ReactHtmlParser from 'react-html-parser'
import { EditControl } from 'react-leaflet-draw'
import { addGeoFence, toggleAllGeofences, updateGeoFence } from './../../Actions/Devices'
import Layout from './../../Layout'
import L from 'leaflet'
import { MapPagination } from './../../Components/Maps/MapPagination'
import { convertSpeedUnits, errorHandler } from '../../Helpers'
import GeofenceModal from '../../Components/Geofence/GeofenceModal'
import TrackModal from '../../Components/Geofence/KMLTracksModal'
import MarkerClusterGroup from './../../Components/Maps/MarkerClusterGroup'
import CustomMarker from './../../Components/Maps/CustomMarker'
import { MapTooltip } from './../../Components/Maps/MapTooltip'
import { MapTileLayers } from '../../Reducers/Maps'
import moment from "moment"
import instance from '../../axios'
import Axios from 'axios'
import Notifications from 'react-notification-system-redux'
//import { checkPrivileges } from '../../Helpers/index'
let geo = {}
class Geofences extends Component {
  constructor (props) {
    super(props)
    this.state = {
      minZoom: 3,
      lat: 25.097919,
      lng: 55.236683,
      zoom: 3,
      bounds: null,
      update: true,
      EditControlObject: {},
      newVector: {},
      updatedGeo: {},
      updateVisible: this.updateVisible.bind(this),
      cancelDrawing: this.cancelDrawing.bind(this),
      saveDrawing: this.saveDrawing.bind(this),
      undoDrawing: this.undoDrawing.bind(this),
      enableEditMap: this.enableEditMap.bind(this),
      cancelMapChanges: this.cancelMapChanges.bind(this),
      save: this.save.bind(this),
      DrawControl: {},
      Drawing: '',
      EditMode: false,
      fitBounds: {},
      resourceList: 'geofences',
      linkResource: false,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMaoData: {},
      currentLayer: null,
      kmlDataForm : {
        name : "",
        calendarId:0,
        description : "",
        area:"",
        attributes:{
          color:"", 
          imported:false,
          type:"kml"
        }
      },
      disableSubmit:true,
    }

    this.addShape = this.addShape.bind(this)
    this.mapReference = this.mapReference.bind(this)
    this._onEditControlReady = this._onEditControlReady.bind(this)
    this._onDrawControlReady = this._onDrawControlReady.bind(this)
    this._onDrawStop = this._onDrawStop.bind(this)
    this._onDrawStart = this._onDrawStart.bind(this)
    this._onEditPath = this._onEditPath.bind(this)
    this._onEditStart = this._onEditStart.bind(this)
    this._saveShap = this._saveShap.bind(this)
    this.setBoundOptions = this.setBoundOptions.bind(this)
    this.setEditControlValue = this.setEditControlValue.bind(this)
    this.reactECref = {}
    this.markers = null
  }

  componentWillUnmount () {
    this.setState({
      minZoom: 3,
      lat: 25.097919,
      lng: 55.236683,
      zoom: 3,
      bounds: null,
      update: true,
      EditControlObject: {},
      newVector: {},
      updatedGeo: {},
      DrawControl: {},
      Drawing: '',
      EditMode: false,
      fitBounds: {},

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMaoData: {},
      enableEdit:false
    })
  }

    setEditControlValue (obj) {
    if (obj && obj.id) {
      this.setState({
        EditControlObject: { ...this.state.EditControlObject, [obj.id]: obj }
      })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  setBoundOptions () {
    return {}
  }

  mapReference (el) {
    if (el) {
      this.map = el.leafletElement
      this.map && L.Control.geocoder({
        position: 'topright',
        placeholder: 'Search location...',
        defaultMarkGeocode: false,
        expand: 'click',
        geocoder: L.Control.Geocoder.nominatim({
          htmlTemplate: r => {
            return `<span class="leaflet-control-geocoder-address-context">${r.display_name}<br>`
          }
        })
      })
        .on('finishgeocode', e => {
          e.target._alts.childNodes.forEach(el => (el.innerHTML = 'hello'))
        })
        .on('markgeocode', e => {
          this.map && this.map.fitBounds([[e.geocode.center.lat, e.geocode.center.lng]])
          this.setState({
            pointer: (
              <Marker
                position={[e.geocode.center.lat, e.geocode.center.lng]}
                icon={L.icon({
                  iconUrl: '/assets/images/location-pin.svg',
                  iconSize: [79, 64],
                  iconAnchor: [20, 64]
                })}
              />
            )
          })

          if (this.props.getPostion && this.map) {
            this.props.getPostion(e.geocode.center, this.map.getZoom())
          }
        })
        .addTo(this.map)
    }
  }

  componentWillMount () {
    if (this.props.ServerSetting&&this.props.ServerSetting.zoom) {
      this.setState({
        zoom: this.props.ServerSetting.zoom,
        lat: this.props.ServerSetting.latitude,
        lng: this.props.ServerSetting.longitude
      })
    }
    if (this.props.logInUser&&this.props.logInUser.zoom) {
      this.setState({
        zoom: this.props.logInUser.zoom,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude
      })
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.props.geoFence.length &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            this.props.logInUser.id,
            this.state.currentPage,
            this.state.pagesize
          )
          this.showUnits(this.props)
        }
      )
    }
  }

  componentWillReceiveProps (n) {
    if (n.ServerSetting.zoom) {
      this.setState({
        zoom: n.ServerSetting.zoom,
        lat: n.ServerSetting.latitude,
        lng: n.ServerSetting.longitude
      })
    }
    if (n.logInUser.zoom) {
      this.setState({
        zoom: n.logInUser.zoom,
        lat: n.logInUser.latitude,
        lng: n.logInUser.longitude
      })
    }

    if (n.logInUser && n.logInUser.id && n.geoFence.length) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            n.logInUser.id,
            this.state.currentPage,
            this.state.pagesize
          )
        }
      )

    }
      this.showUnits(n)
  }
  showResources = (type) => {
    this.setState({
      resourceList: type
    })
  }
  addResource = () =>{
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'addResource',
      selecteditem: '',
      driverAddress: '',
      driverLat: '',
      enableEdit:false,

      driverLon: ''
    })
  }
  onEditResource = (item) =>{

    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'editResource',
      selecteditem: item,
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    })
  }
  onLinkResource = (item) =>{
    this.setState({
      linkResource: true,
      selecteditem: item,
    },()=>{this.props.fetchNestedItems(item.id,1)})
  }
  onCloseResource = () =>{
    this.setState({
      linkResource: false,
    });
    this.onCloseModal();
  }
  onCloseModal = () => {
    this.setState({
      isVisable: false,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: '',
      onDeleteConfirmation: false
    })
  }
  showUnits = (n) => {
    if (
      n.deviceRelatedData &&
      Object.keys(n.deviceRelatedData).length
    ) {
      this.markers = []
      n.devices.data && n.devices.data.map((deviceData, index) => {
        const position = n.deviceRelatedData[deviceData.id]
        const driver =
          n.drivers &&
          n.drivers.find(
            dr =>
              position &&
              position.exists &&
              position.driverId &&
              position.driverId === dr.id
          )
          const trailer =
          n.trailers &&
          n.trailers.find(
            t =>
              position &&
              position.exists &&
              position.attributes.trailerUniqueId &&
              position.attributes.trailerUniqueId === t.uniqueId
            )
        if (
          position &&
          position.exists &&
          deviceData &&
          deviceData.visible === true
        ) {
          this.markers.push(
            <CustomMarker
              key={deviceData.id}
              position={{ lat: position.latitude, lng: position.longitude, updated: moment(position.serverTime) }}
              rotationAngle={0}
              rotationOrigin='center'
              animationTime={
                deviceData.id === this.props.trackId &&
                this.state.animCount === 1
                  ? position.animationTime
                  : 0.5
              }
              tracking={this.props.trackId}
              icon={L.divIcon({
                iconUrl:
                  '/assets/category/default/' +
                  (deviceData.category || 'default') +
                  'top.svg',
                iconSize: [50, 50],
                iconAnchor: [25, 25],
                tooltipAnchor: [0, -20],
                className: 'custom-marker',
                html: `<img
                  style="transform: rotate(${position.course}deg)"
                    src=
                      '/assets/category/default/${deviceData.category ||
                        'default'}top.svg'
                    
                    alt=''
                  />`
              })}
              iconSize={[50, 50]}
            >
              <Tooltip direction={'top'}>
                <MapTooltip
                  trailer={trailer}
                  themecolors={this.props.themecolors}
                  position={position}
                  driver={driver}
                  device={deviceData}
                  logInUser={this.props.logInUser}
                  translate={this.props.translate}
                />
              </Tooltip>
            </CustomMarker>
          )
        }
        return null
      })
    } else {
      this.markers = null
    }
  }

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser.id,
      this.state.currentPage,
      this.state.pagesize
    )
  }

  searchItems = text => {
    this.setState(
      {
        searchText: text
      },
      () => {
        this.fetchData(this.props.logInUser.id, 1, this.state.pagesize, true)
      }
    )
  }

  fetchData = (userId, page, perPage, reset) => {
    let searchText = this.state.searchText
    let items = this.props.geoFence.filter(e =>
      e.name.toLowerCase().includes((searchText || '').toLowerCase())
    )
    this.setState({
      itemPagination: {
        items: items.map(e => ({
          ...e,
          name: ReactHtmlParser(e.name)[0],
          description: ReactHtmlParser(e.description)[0]
        })),
        total: items.length,
        currentPage: 1,
        hasNext: false
      }
    })
  }
  onDelete = () => {
    this.fetchData()
  }
  openGeofenceModal = data => {
    if(this.map) {
      let geofenceModalMaoData = {
        center: this.map.getCenter(),
        zoom: this.map.getZoom()
      }

      this.setState({
        geofenceModal: true,
        geofenceModalData: data,
        enableEdit: data && data ? true : false,
        geofenceModalMaoData
      })
    }
  }
  closeGeofenceModal = data => {
    this.setState({
      geofenceModal: false,
      enableEdit:false,
      geofenceModalData: null,
      geofenceModalMaoData: {}
    })
  }

  addShape (type) {
    if (this.state.EditMode === false) {
      if (this.state.DrawControl) {
        this.setState({ Drawing: type }, () => {
          if (type === 'circle') {
            this.state.DrawControl.leafletElement._toolbars.draw._toolbarContainer.children[2].click()
          } else if (type === 'polygon') {
            this.state.DrawControl.leafletElement._toolbars.draw._toolbarContainer.children[1].click()
          } else if (type === 'polyline') {
            this.state.DrawControl.leafletElement._toolbars.draw._toolbarContainer.children[0].click()
          }
        })
      }
    } else {
      alert('Please finish your modification first!')
    }
  }

  save (obj) {
    this.setState({ geo: obj }, () => {
      if (
        this.state.EditControlObject[obj.id]._toolbars.edit._actionButtons[0]
      ) {
        this.state.EditControlObject[
          obj.id
        ]._toolbars.edit._actionButtons[0].button.click()
      }
    })
  }

  enableEditMap (id) {
    setTimeout(() => {
      if (this.state.EditControlObject[id]) {
        this.setState({ EditMode: true })
        this.state.EditControlObject[
          id
        ]._toolbars.edit._toolbarContainer.children[0].click()
      }
    }, 100)
  }

  cancelMapChanges (id) {
    if (this.state.EditControlObject[id]) {
      this.setState({ EditMode: false }, () => {
        this.state.EditControlObject[
          id
        ]._toolbars.edit._actionButtons[1].button.click()
      })
    }
  }

  _onDrawControlReady (reactECref) {
    this.setState({ DrawControl: reactECref })
  }

  _onDrawStart (e) {
    this.setState({
      Drawing: e.layerType
    })
  }

  _onDrawStop (e) {
    let currentShap = {}
    Object.keys(e.target._targets).map(id => {
      if (parseInt(id) !== e.target._containerId) {
        currentShap = e.target._targets[id]
      }
      return null
    })

    if (Object.keys(currentShap).length) {
      if (currentShap.options.newShap) {
        if (this.state.Drawing === 'circle') {
          this.setState(
            {
              newVector: {
                name: 'New Circle',
                calendarId: 0,
                description: '',
                area:
                  'CIRCLE (' +
                  currentShap._latlng.lat +
                  ' ' +
                  currentShap._latlng.lng +
                  ', ' +
                  currentShap._mRadius +
                  ')',
                attributes: {
                  type: 'circle',
                  radius: currentShap._mRadius,
                  color: currentShap.options.color,
                  latlng: [currentShap._latlng.lat, currentShap._latlng.lng]
                }
              }
            },
            () => {
              this._saveShap(this.state.newVector)
            }
          )
        } else if (this.state.Drawing === 'polygon') {
          if (currentShap._latlngs) {
            let prepare = currentShap._latlngs[0].map(pos => {
              return '' + pos.lat + ' ' + pos.lng + ''
            })
            this.setState(
              {
                newVector: {
                  name: 'New Polygon',
                  calendarId: 0,
                  description: '',
                  area: 'POLYGON((' + prepare.join(', ') + '))',
                  attributes: {
                    type: 'polygon',
                    color: currentShap.options.color,
                    latlng: currentShap._latlngs[0]
                  }
                }
              },
              () => {
                this._saveShap(this.state.newVector)
              }
            )
          }
        } else if (this.state.Drawing === 'polyline') {
          let distance = 0
          let prepare = currentShap._latlngs.map((pos, index) => {
            distance += pos.distanceTo(currentShap._latlngs[index - 1] || pos)
            return '' + pos.lat + ' ' + pos.lng + ''
          })
          this.setState(
            {
              newVector: {
                name: 'New Polyline',
                calendarId: 0,
                description: '',
                area: 'LINESTRING (' + prepare.join(', ') + ')',
                attributes: {
                  type: 'polyline',
                  color: currentShap.options.color,
                  latlng: currentShap._latlngs,
                  polylineDistance: distance / 1000
                }
              }
            },
            () => {
              this._saveShap(this.state.newVector)
            }
          )
        }
      }
    } else {
      this.setState({ Drawing: '' })
    }
  }

  cancelDrawing () {
    if (this.state.Drawing === 'polygon' || this.state.Drawing === 'polyline') {
      this.state.DrawControl.leafletElement._toolbars.draw._actionButtons[2].button.click()
    }
    if (this.state.Drawing === 'circle') {
      this.state.DrawControl.leafletElement._toolbars.draw._actionButtons[0].button.click()
    }
    this.setState({ Drawing: '' })
  }

  saveDrawing () {
    if (this.state.Drawing === 'polygon' || this.state.Drawing === 'polyline') {
      this.state.DrawControl.leafletElement._toolbars.draw._actionButtons[0].button.click()
    }
  }

  undoDrawing () {
    if (this.state.Drawing === 'polygon' || this.state.Drawing === 'polyline') {
      this.state.DrawControl.leafletElement._toolbars.draw._actionButtons[1].button.click()
    }
  }

  _onEditControlReady (reactECref) {
    setTimeout(() => {
      if (reactECref) {
        var key = Object.keys(
          reactECref.leafletElement._toolbars.edit.options.featureGroup._layers
        )
        if (key.length) {
          var id =
            reactECref.leafletElement._toolbars.edit.options.featureGroup
              ._layers[key].options.id

          this.reactECref[id] = reactECref.leafletElement

          this.setState(
            {
              EditControlObject: {
                ...this.state.EditControlObject,
                [id]: reactECref.leafletElement
              }
            },
            () => {
              //this.enableEditMap(id);
            }
          )
        }
      }
    }, 50)
  }

  _onEditStart (id, event) {
    if (this.reactECref[id]) {
      this.setEditControlValue(this.state.EditControlObject)
      this.updateVisible()
    }
  }

  _saveShap (updatedItem) {
    // fetch(`api/geofences`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({ ...updatedItem })
    // })
    instance({
      url: `api/geofences`,
      method: `POST`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...updatedItem
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(geofence => {
          this.props.dispatch(addGeoFence([{ ...geofence, visible: true }]))
          this.setState(
            {
              newVector: {},
              Drawing: ''
            },
            () => {
              this.state.DrawControl.leafletElement._toolbars.edit._toolbarContainer.children[1].click()
              if (
                this.state.DrawControl.leafletElement._toolbars.edit
                  ._actionButtons &&
                this.state.DrawControl.leafletElement._toolbars.edit
                  ._actionButtons[2]
              ) {
                this.state.DrawControl.leafletElement._toolbars.edit._actionButtons[2].button.click()
              }
            }
          )
      //   })
      // }
      // else{
      //   throw response
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }

  _onEditPath (id, e) {
    if (this.reactECref[id]) {
      this.setState(
        {
          EditControlObject: {
            ...this.state.EditControlObject,
            [id]: this.reactECref[id]
          }
        },
        () => {
          let layers = e.layers
          let item = this.state.geo
          let attributes = item ? item.attributes : {}
          let updatedItem = this.state.geo || {}
          let jsonObj

          layers.eachLayer(layer => {
            jsonObj = layer.toGeoJSON()
            if (jsonObj.geometry.type === 'Point') {
              var latlng2 = jsonObj.geometry.coordinates
              updatedItem = {
                ...item,
                area:
                  'CIRCLE (' +
                  latlng2[1] +
                  ' ' +
                  latlng2[0] +
                  ', ' +
                  layer._mRadius +
                  ')',
                attributes: {
                  ...attributes,

                  type: 'circle',
                  latlng: [latlng2[1], latlng2[0]],
                  radius: layer._mRadius
                }
              }
            } else if (jsonObj.geometry.type === 'Polygon') {
              item = this.state.geo
              var latlng22 = jsonObj.geometry.coordinates
              var p22 = []
              let prepare = latlng22[0].map(pos => {
                p22.push([pos[1], pos[0]])
                return '' + pos[1] + ' ' + pos[0] + ''
              })
              updatedItem = {
                ...item,
                area: 'POLYGON((' + prepare.join(', ') + '))',
                attributes: { ...attributes, type: 'polygon', latlng: p22 }
              }
            } else if (jsonObj.geometry.type === 'LineString') {
              item = this.state.geo
              var latlng23 = jsonObj.geometry.coordinates
              var p23 = []
              let prepare = latlng23.map(pos => {
                p23.push([pos[1], pos[0]])

                return '' + pos[1] + ' ' + pos[0] + ''
              })
              updatedItem = {
                ...item,
                area: 'LINESTRING (' + prepare.join(', ') + ')',
                attributes: { ...attributes, type: 'polyline', latlng: p23, imported: false, }
              }
            }
          })

          if (
            this.state.updatedGeo[id] === false ||
            this.state.updatedGeo[id] === undefined
          ) {
            if (updatedItem && updatedItem.visible !== undefined) {
              delete updatedItem.visible
            }
            if (
              updatedItem &&
              updatedItem.attributes &&
              updatedItem.attributes.speedLimit
            ) {
              updatedItem.attributes.speedLimit = convertSpeedUnits(
                updatedItem.attributes.speedLimit || 0,
                'kmh',
                'kn'
              )
            }
            this.setState({
              updatedGeo: {
                [id]: true
              }
            })

            // fetch(`api/geofences/${id}`, {
            //   method: 'PUT',
            //   headers: {
            //     Accept: 'application/json',
            //     'Content-Type': 'application/json'
            //   },
            //   body: JSON.stringify({ ...updatedItem })
            // })
            instance({
              url: `api/geofences/${id}`,
              method: `PUT`,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              data:{
                ...updatedItem
              }
            })
            // .then(response => {
            //   if (response.ok) {
            //     response.json()
                .then(geofence => {
                  this.setState({
                    updatedGeo: {
                      [id]: false
                    },
                    EditMode: false
                  })
                  this.props.dispatch(
                    updateGeoFence({ ...geofence, visible: false })
                  )
                  this.props.dispatch(
                    updateGeoFence({ ...geofence, visible: true })
                  )
              //   })
              // }
              // else{
              //   throw response
              // }
            }).catch(error => {errorHandler(error, this.props.dispatch)})
          }
        }
      )
    }
  }

  onFeatureGroupAdd = (e, b) => {
    geo[b] = e.target.getBounds()
    this.setState({
      fitBounds: {
        ...geo
      }
    }, () => {
      let bounds = []
      this.props.geoFence.map(g => {
        if (g.visible === true && this.state.fitBounds[g.id]) {
          bounds.push(this.state.fitBounds[g.id])
        }
        return null
      })
      if(!bounds.length) {
        geo = Object.assign({})
      }
    })
  }

  toggleGeofences = (isChecked , arr) => {
    if(!isChecked ) {
      this.setState({fitBounds: {}})
      geo = {}
    }
    else {
      this.updateVisible(arr);
    }
    this.props.dispatch(toggleAllGeofences(isChecked , "geofences", arr));

  }

  updateVisible = (arr) => {
  const geofences = arr || this.props.geoFence;
  setTimeout(() => {
    let bounds = [];
    geofences.forEach((g) => {
      if (g.visible === true && this.state.fitBounds[g.id]) {
        bounds.push(this.state.fitBounds[g.id]);
      }
    });
    if (bounds.length) {
      this.map.fitBounds(bounds);
    }
  }, 50);
}

  

  changeBaseLayer = event => {
    
    if(this.map) {
      const center = this.map.getCenter(); 
      this.map.options.crs = ['yandexMap', 'yandexSat'].includes(event.layer.options && event.layer.options.id) ? L.CRS.EPSG3395 : L.CRS.EPSG3857;
      this.map.setView(center);
    }
    
    this.setState({currentLayer: event.layer.options});
    
  }

   getLatLngString = (layer)=> {
    if (!layer || !layer.latLngs || !Array.isArray(layer.latLngs)) {
        return ''; // return empty string if layer object or latLngs array is missing
    }

    // Map through the latLngs array and extract latLng coordinates
    const latLngString = layer.latLngs.map(latLng => {
        if (latLng && latLng.lat && latLng.lng) {
            return `${latLng.lat} ${latLng.lng}`;
        } else {
            return ''; // return empty string if latLng object or lat/lng values are missing
        }
    }).join(', '); // join latLng strings with comma and spaces

    return latLngString;
}
  upload = n => {
    if (n.target.files) {
      let e = n.target.files[0],
        t = e.name.split('.'),
        i = e.size,
        ko = 50000000,
        r = ['geojson', 'json', 'gpx', 'kml', 'xml'],
        u = t[t.length - 1].toLowerCase()

      if (r.includes(u)) {
        if (i > ko) {
          return (
            'File size is too large. Max allowed size is ' +
            Math.round(ko / (1024 * 1000)) +
            'MB'
          )
        } else {
          let p = new FileReader()
          const base64String = p.result;

          p.readAsText(e, 'UTF-8')
          p.onload = async o => {
            let n = o.target.result

            if ('kml' === u) {
              let t = new DOMParser().parseFromString(n, 'text/xml'),
                tag = t && t.documentElement && t.documentElement.nodeName
              if ('kml' !== tag && 'gpx' !== tag) {
                return 'File content is not valid.'
              }
              u = tag
            }

            const parser = new DOMParser()
            const kml = parser.parseFromString(n, 'text/xml')

            const layer = new L.KML(kml)
            const latLonString =  this.getLatLngString(layer);

            // geofence work start from here...

            const geofences = [];
            // Object.values(layer._layers).map((l, i) =>  {

            //     let distance = 0
            //     let prepare = l._latlngs.map((pos, index) => {
            //       distance += pos.distanceTo(l._latlngs[index - 1] || pos)
            //       return '' + pos.lat + ' ' + pos.lng + ''
            //     })

                
                geofences.push({
                  name: 'Test KML '+(moment().format('YYYY-MM-DD')+' '+(i+1)),
                  calendarId: 0,
                  description: 'Test KML',
                  area: 'KML (' + latLonString + ')',
                  attributes: {
                    imported: true,
                    // color: l.options.fillColor,
                    // polylineDistance: distance / 1000
                  }
                });
            // });
            // for(const geo of geofences) {

            //   const response = await Axios.post(`/api/geofences`, geo);

            // }

            this.setState({
              kmlContent: kml,
              kmlType: u,
              kmlDataForm:{
                ...this.state.kmlDataForm,
                area: 'KML (' + latLonString + ')',
                attributes:{
                  ...this.state.kmlDataForm.attributes,
                  latlng:layer.latLngs
                }
              }
            },()=>{
              this.checkReqFields()
            })
            return {
              name: e.name,
              size: Math.round(e.size / (1024 * 1000)) + 'MB'
            }
          }
        }
      } else {
        return 'Unsupported file type.'
      }
      return { name: e.name, size: (e.size / 1024).toFixed(2) + 'KB' }
    }
  }


  handleChange =(name , value) =>{
    this.setState({
      kmlDataForm:{
        ...this.state.kmlDataForm,
        [name]:value
      }
    },()=>{
      this.checkReqFields()
    })
  }
  checkReqFields = ()=>{
   const  {name, area} = this.state.kmlDataForm;
   if(name && area){
        this.setState({disableSubmit :false })
   }else{
      this.setState({disableSubmit : true})
   }
  }



  submitKMLForm = async()=>{
    const geo = this.state.kmlDataForm;
    
    this.setState({ }, () => {
      instance({
        url: `/api/geofences`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data :{
          ...geo
        }
      })
      .then(geofence => {
        this.setState({disableSubmit:true},()=>this.closeGeofenceModal())
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('kmlTrackCreated'),
                autoDismiss: 10
              })
            )
          })
      .catch(error => {errorHandler(error, this.props.dispatch)})
    })
  }



  render () {

    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {this.props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {this.props.translate('notFound')}{' '}
        </p>
      )
    if (this.props.mapLayer) {
      const settings = {
        rectangle: false,
        circle: false,
        circlemarker: false,
        marker: false,
        polyline: false,
        polygon: false
      }
      let bounds ;
      const geofences = this.props.geoFence.map(obj => {
        if (obj && obj.attributes.type === 'circle' && obj.visible === true) {
          return (
            <FeatureGroup
              onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              ref={this._onFeatureGroupReady}
              key={obj.id}
            >
              <EditControl
                ref={this._onEditControlReady}
                position='bottomleft'
                draw={settings}
                onEdited={this._onEditPath.bind(this, obj.id)}
                onEditStart={this._onEditStart.bind(this, obj.id)}
              />
              <Circle
                id={obj.id}
                radius={obj.attributes.radius}
                center={obj.attributes.latlng}
                color={obj.attributes.color}
              >
                <Tooltip direction={'top'} permanent>
                  <div>
                    <span>{ReactHtmlParser(obj.name)}</span>
                  </div>
                </Tooltip>
              </Circle>
            </FeatureGroup>
          )
        } else if (
          obj &&
          obj.attributes.type === 'polygon' &&
          obj.visible === true
        ) {
          return (
            <FeatureGroup
              onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              ref={this._onFeatureGroupReady}
              key={obj.id}
            >
              <EditControl
                ref={this._onEditControlReady}
                position='bottomleft'
                draw={settings}
                edit={{ remove: false }}
                onEdited={this._onEditPath.bind(this, obj.id)}
                onEditStart={this._onEditStart.bind(this, obj.id)}
              />
              <Polygon
                id={obj.id}
                key={obj.id + '__1'}
                positions={obj.attributes.latlng}
                color={obj.attributes.color}
              >
                <Tooltip direction={'top'} permanent>
                  <div>
                    <span>{ReactHtmlParser(obj.name)}</span>
                  </div>
                </Tooltip>
              </Polygon>
            </FeatureGroup>
          )
        } else if (
          obj &&
          obj.attributes.type === 'polyline' &&
          obj.visible === true
        ) {
          return (
            <FeatureGroup
              onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              ref={this._onFeatureGroupReady}
              key={obj.id}
            >
              <EditControl
                ref={this._onEditControlReady}
                position='bottomleft'
                draw={settings}
                edit={{ remove: false }}
                onEdited={this._onEditPath.bind(this, obj.id)}
                onEditStart={this._onEditStart.bind(this, obj.id)}
              />
              <Polyline
                id={obj.id}
                key={obj.id + '__1'}
                positions={obj.attributes.latlng}
                color={obj.attributes.color}
              >
                <Tooltip direction={'top'} permanent>
                  <div>
                    <span>{ReactHtmlParser(obj.name)}</span>
                  </div>
                </Tooltip>
              </Polyline>
            </FeatureGroup>
          )
        }else if (
          obj &&
          obj.attributes.type === "kmlFile" &&
          obj.visible === true
        ) {
         const coordinates = obj?.attributes?.latlng;
         bounds = coordinates;
          return (
            <FeatureGroup
              // onAdd={e => this.onFeatureGroupAdd(e, obj.id)}
              // ref={this._onFeatureGroupReady}
              key={obj.id}
            >
             <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* Render Polyline with the extracted coordinates */}
            <Polyline positions={coordinates} color={"red"} />
            </FeatureGroup>
          )
        }

        return ''
      })

      const position = [this.state.lat, this.state.lng]
      const drawOptions = {
        shapeOptions: {
          color: this.props.themecolors.themeDarkColor,
          opacity: 1,
          newShap: true
        }
      }
      const drawSettings = {
        ...settings,
        circle: drawOptions,
        polygon: drawOptions,
        polyline: drawOptions
      }

      const body = [
        <>
          <FeatureGroup>
            <EditControl
              ref={this._onDrawControlReady}
              draw={drawSettings}
              position='bottomleft'
              onDrawStop={this._onDrawStop}
              onDrawStart={this._onDrawStart}
            />
          </FeatureGroup>
          {/* start */}
          {this.props.logInUser.attributes &&
          this.props.logInUser.attributes.clusters ? (
            <MarkerClusterGroup key={1}>{this.markers}</MarkerClusterGroup>
          ) : (
            this.markers
          )}
          {/* end */}
          {geofences}
          <ZoomControl position={'bottomright'} />
          <MapPagination />
        </>
      ]

      const thisMap = [
        <Map
          key={1}
          ref={this.mapReference}
          zoomControl={false}
          style={{ height: this.props.height, width: this.props.width }}
          center={position}
          zoom={this.state.zoom}
          onBaselayerchange={this.changeBaseLayer}
          maxZoom={this.props.mapLayer.maxZoom}
          bounds={bounds && bounds.length ? bounds : null}

        >
          {this.state.pointer}
          {body}
          <LayersControl position="bottomright">
            {MapTileLayers.map(layer => <LayersControl.BaseLayer id={layer.id} checked={layer.id === this.props.mapLayer.id} key={layer.id} name={layer.name}>
              <TileLayer {...layer} minZoom={this.state.minZoom} />    
            </LayersControl.BaseLayer>)}
          </LayersControl>
        </Map>
      ]
      const kmlData =  {
        "id": 2518,
        "attributes": {
        "color": "red",
        "imported": false,
        "type": "kml",
        "latlng": [
          {
          "lat": 33.53700601190363,
          "lng": 73.13564292970007
          },
          {
          "lat": 33.53689177398902,
          "lng": 73.13738268449964
          },
          {
          "lat": 33.53622179618755,
          "lng": 73.13800931239196
          },
          {
          "lat": 33.53624510671542,
          "lng": 73.14005418654592
          },
          {
          "lat": 33.53711676581349,
          "lng": 73.14039840570258
          },
          {
          "lat": 33.53752227015402,
          "lng": 73.14448939188482
          },
          {
          "lat": 33.53662833949034,
          "lng": 73.14626260214281
          },
          {
          "lat": 33.53792449740454,
          "lng": 73.14774431115549
          },
          {
          "lat": 33.53808315889335,
          "lng": 73.14996964831468
          },
          {
          "lat": 33.53859845237259,
          "lng": 73.15508856280225
          },
          {
          "lat": 33.53634115548154,
          "lng": 73.15561964740314
          },
          {
          "lat": 33.53627256686909,
          "lng": 73.15736848286971
          },
          {
          "lat": 33.54195993919434,
          "lng": 73.15766998785087
          },
          {
          "lat": 33.54229666381013,
          "lng": 73.15670278180997
          },
          {
          "lat": 33.54023958124583,
          "lng": 73.13355535999847
          },
          {
          "lat": 33.53700601190363,
          "lng": 73.13564292970007
          }
          ]
        },
        "calendarId": 0,
        "name": "test-hamza-1",
        "type": "kmlFile",
        "description": "test-hamza ",
        "area": "KML (33.53700601190363 73.13564292970007,33.53689177398902 73.13738268449964,33.53622179618755 73.13800931239196,33.53624510671542 73.14005418654592,33.53711676581349 73.14039840570258,33.53752227015402 73.14448939188482,33.53662833949034 73.14626260214281,33.53792449740454 73.14774431115549,33.53808315889335 73.14996964831468,33.53859845237259 73.15508856280225,33.53634115548154 73.15561964740314,33.53627256686909 73.15736848286971,33.54195993919434 73.15766998785087,33.54229666381013 73.15670278180997,33.54023958124583 73.13355535999847,33.53700601190363 73.13564292970007)",
        "parentId": 1,
        "directAccess": true,
        "groupAccess": false
      }
      // const coordinates = this.state?.itemPagination?.items?.map(coord => [coord.lat, coord.lng]);

      const coordinates = this.state?.itemPagination?.items?.map(item => item.attributes?.latlng);

      // const thisMap = [
      //     <Map  
      //       key={1}
      //       ref={this.mapReference}
      //       zoomControl={false}
      //       style={{ height: this.props.height, width: this.props.width }}
      //       center={position}
      //       zoom={this.state.zoom}
      //       onBaselayerchange={this.changeBaseLayer}
      //       maxZoom={this.props.mapLayer.maxZoom}>
      //       <TileLayer
      //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      //         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      //       />
      //       {/* Render Polyline with the extracted coordinates */}
      //       <Polyline positions={coordinates} color={"red"} />
      //    </Map>
      //   ]
      return (
        <div className='no-map-controls no-widgets'>
          {this.state.geofenceModal &&  this.state.resourceList !== 'tracks'  && (
            <GeofenceModal
              geofence={this.state.geofenceModalData}
              {...this.state.geofenceModalMaoData}
              onClose={this.closeGeofenceModal}
              resourceList={this.state.resourceList}
            />
          )}
           {this.state.geofenceModal && this.state.resourceList === 'tracks' &&  (
                <TrackModal
                  enableEdit={this.state.enableEdit}
                  geofence={this.state.geofenceModalData}
                  {...this.state.geofenceModalMaoData}
                  onClose={this.closeGeofenceModal}
                  resourceList={this.state.resourceList}
                  kmlFileUpload={this.upload}
                  kmlDataForm={this.state.kmlDataForm}
                  onChange={this.handleChange}
                  disableSubmit={this.state.disableSubmit}
                  submitKMLForm={this.submitKMLForm}
                />
              )}
          <Layout
            {...this.props}
            kmlFileUpload={this.upload}
            enableEdit={this.state.enableEdit}
            geofenceData={this.state}
            toggleGeofences={this.toggleGeofences}
            fetchMoreItems={this.fetchMoreItems}
            itemPagination={{ ...this.state.itemPagination, endMessage }}
            searchItems={this.searchItems}
            openGeofenceModal={this.openGeofenceModal}
            onDelete={this.onDelete}
            showResources={this.showResources}
            onEditResource={this.onEditResource}
            onLinkResource={this.onLinkResource}
            addResource={this.addResource}
            resourceList={this.state.resourceList}
          >
            {!['addResource', 'editResource'].includes(this.state.activeOperation) ? <div>
            {['osm', ''].includes(this.props.mapLayer.id) ? thisMap : null}
            {['carto'].includes(this.props.mapLayer.id) ? thisMap : null}
            {['googleTerrain'].includes(this.props.mapLayer.id)
              ? thisMap
              : null}
            {['googleSatellite'].includes(this.props.mapLayer.id)
              ? thisMap
              : null}
            {['googleHybrid'].includes(this.props.mapLayer.id) ? thisMap : null}
            {['googleRoad'].includes(this.props.mapLayer.id) ? thisMap : null}
            {['baidu'].includes(this.props.mapLayer.id) ? thisMap : null}
            {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)
              ? thisMap
              : null}
              </div> : null}

            <button
              style={{
                position: 'absolute',
                left: '-10000px',
                top: '-10000px'
              }}
              onClick={e => this.addShape('circle')}
              id='addCircle'
            ></button>
            <button
              style={{
                position: 'absolute',
                left: '-10000px',
                top: '-10000px'
              }}
              onClick={e => this.addShape('polygon')}
              id='addPolygon'
            ></button>
            <button
              style={{
                position: 'absolute',
                left: '-10000px',
                top: '-10000px'
              }}
              onClick={e => this.addShape('polyline')}
              id='addPolyline'
            ></button>   
            <div className="has-padding">
            <ResourceModal
              changeResource={this.props.changeResource}
              selectedResourse={this.state.selecteditem}
              activeOperation={this.state.activeOperation}
              onCloseResource={this.onCloseResource}
              itemPagination={this.state.itemPagination&&this.state.itemPagination.items}
              assignItem={this.props.assignItem}
              unassignItem={this.props.unassignItem}
              fetchNestedItems={this.props.fetchNestedItems}
              nestedResources={this.props.nestedResources}
              translate={this.props.translate}
              editResource={this.state.editResource}
              linkResource={this.state.linkResource}
              themecolors={this.props.themecolors}
              itemType='Geofence'
            />
            </div>
          </Layout>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  geoFence: state.geoFence,
  deviceRelatedData: state.deviceRelatedData,
  devices: state.devices,
  mapLayer: state.mapLayer,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)(withLocalize(withResources(Geofences, 'Geofence')))
