import React, { PureComponent } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import './index.scss'
import { Icon, Tooltip } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import { checkPrivileges, errorHandler } from '../../Helpers'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import Style from 'style-it'
import MenuBar from './navbar'
import moment from "moment"

import { ReactComponent as MonitorIcon } from './../../assets/nav/monitor.svg'
import { ReactComponent as DashboardIcon } from './../../assets/nav/dashboard.svg'
import { ReactComponent as EmployeeIcon } from './../../assets/nav/employees-one.svg'
import { ReactComponent as BinderIcon  } from './../../assets/nav/binders.svg'
import { ReactComponent as TrashIcon  } from './../../assets/nav/trash.svg'
import { ReactComponent as GeofenceIcon } from './../../assets/nav/geofence.svg'
import { ReactComponent as MessageIcon } from './../../assets/nav/message.svg'
import { ReactComponent as MailIcon } from './../../assets/nav/mail.svg'
import { ReactComponent as TracksIcon } from './../../assets/nav/tracks.svg'
import { ReactComponent as UnitsIcon } from './../../assets/nav/units.svg'
import { ReactComponent as VehicleIcon } from './../../assets/nav/vehicle.svg'
import { ReactComponent as StaffIcon } from './../../assets/nav/staff.svg'
import { ReactComponent as TimeMachineIcon } from './../../assets/nav/time-machine.svg'
import { ReactComponent as ReportIcon } from './../../assets/nav/reports.svg'
import { ReactComponent as ReportHistoryIcon } from './../../assets/nav/report-history.svg'
import { ReactComponent as CalendarIcon } from './../../assets/nav/calendar.svg'
import { ReactComponent as NotificationIcon } from './../../assets/nav/notifications.svg'
import { ReactComponent as UsersIcon } from './../../assets/nav/user-two.svg'
import { ReactComponent as CommandIcon } from './../../assets/nav/command.svg'
import { ReactComponent as MaintenanceIcon } from './../../assets/nav/spanner.svg'
import { ReactComponent as InvoiceIcon } from './../../assets/nav/invoice.svg'
import { ReactComponent as ParameterIcon } from './../../assets/nav/algorithm.svg'
import { ReactComponent as actionLog } from './../../assets/nav/actionLog.svg'
import { ReactComponent as Garage } from './../../assets/nav/garage-icon.svg'
import { ReactComponent as Area } from './../../assets/nav/area-icon.svg'
import { ReactComponent as Services } from './../../assets/nav/car-expence.svg'
import { ReactComponent as WorkFlow } from './../../assets/nav/workflow.svg'
import { ReactComponent as Metrix } from './../../assets/nav/metrix.svg'

import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import instance from '../../axios';
const emptyLink = '#'
const mapStateToProps = state => ({
  notificationCount: state.notificationCount,
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  dashboards: state.dashboards?.data?.map(item => ({
    ...item,
    icon: DashboardIcon,
  }))||[]
})

class Header extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      currentLang: 'en'
    }
  }
  componentWillReceiveProps (n) {
    if (
      n.activeLanguage &&
      n.activeLanguage.code &&
      n.activeLanguage.code !== this.props.activeLanguage.code
    ) {
      this.setState({ currentLang: '' }, () => {
        this.setState({ currentLang: n.activeLanguage.code })
      })
    }
  }

  render () {
    return this.props.logInUser &&
      this.props.logInUser.id &&
      localStorage.getItem('frontPrivileges') &&
      this.state.currentLang ? (
      <HeaderInner {...this.props} />
    ) : null
  }
}
class HeaderInner extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      collapseMenu: true,
      privilagesProcess: false,
      navigation: {
        dashboard: {
          checked: true,
          href: '/dashboard',
          icon: DashboardIcon,
          title: this.props.translate('dashboard')
        },
        monitoring: {
          checked: checkPrivileges('device'),
          href: '/monitoring',
          icon: MonitorIcon,
          title: this.props.translate('Monitoring')
        },
        
        
        units: {
          checked: checkPrivileges('device'),
          href: '/units',
          icon: UnitsIcon,
          title: this.props.translate('trackers')
        },
        resources: {
          checked: checkPrivileges('group'),
          href: '/resources',
          icon: DeviceHubIcon,
          title: this.props.translate('resources')
        },
        users: {
          checked: checkPrivileges('user'),
          href: '/users',
          icon: UsersIcon,
          title: this.props.translate('Users')
        },
        drivers: {
          checked: checkPrivileges('driver'),
          href: '/drivers',
          icon: EmployeeIcon,
          title: this.props.translate('Staff')
        },
        trailer: {
          checked: checkPrivileges('binder'),
          href: '/binders',
          icon: BinderIcon,
          title: this.props.translate('binder')
        },
        staff: {
          checked: false,
          href: '/staff',
          icon: StaffIcon,
          title: 'Staff'
        },
        vehicles: {
          checked: checkPrivileges('vehicle'),
          href: '/vehicles',
          icon: VehicleIcon,
          title: this.props.translate('sharedVehicle')
        },
        // garage: {
        //   checked: checkPrivileges('garage'),
        //   href: '/garage',
        //   icon: Garage,
        //   title: this.props.translate('Garage')
        // },
        // area: {
        //   checked: checkPrivileges('area'),
        //   href: '/area',
        //   icon: Area,
        //   title: this.props.translate('sharedArea')
        // },
        services: {
          checked: checkPrivileges('expensetypeTab'),
          href: '/services',
          icon: Services,
          title: this.props.translate('Services')
        },
        workFlow: {
          checked: checkPrivileges('workflow'),
          // checked: true,
          href: '/workFlow',
          icon: WorkFlow,
          title: this.props.translate('worKFLow')
        },
        metrics: {
          checked: checkPrivileges('workflowmatrix'),
          // checked: true,
          href: '/metrics',
          icon: Metrix,
          title: this.props.translate('Metrics')
        },
        geofences: {
          checked: checkPrivileges('geofence'),
          href: '/geofences',
          icon: GeofenceIcon,
          title: this.props.translate('Geofences')
        },
        calendars: {
          checked: false, //checkPrivileges('calendar'),
          href: '/calendars',
          icon: CalendarIcon,
          title: this.props.translate('sharedCalendar')
        },
        events: {
          checked: checkPrivileges('notification'),
          href: '/events',
          icon: NotificationIcon, // EventsIcon,
          title: this.props.translate('reportEvents')
        },
        notifications: {
          checked: false, //checkPrivileges('device'),
          href: '/notifications',
          icon: NotificationIcon,
          title: this.props.translate('Notifications')
        },
        reports: {
          checked: checkPrivileges('report'),
          href: '/reports',
          icon: ReportIcon,
          title: this.props.translate('Reports')
        },
        reportsHistory: {
          checked: checkPrivileges('reportHistory'),
          href: '/reportsHistory',
          icon: ReportHistoryIcon,
          title: this.props.translate('reportHistory')
        },
        messages: {
          checked: checkPrivileges('position'),
          href: '/Dlogs',
          icon: MessageIcon,
          title: this.props.translate('Dlogs')
        },
        tracks: {
          checked: checkPrivileges('track'),
          href: '/tracks',
          icon: TracksIcon,
          title: this.props.translate('Tracks')
        },
        timemachine: {
          checked: checkPrivileges('timeLapse'),
          href: '/timemachine',
          icon: TimeMachineIcon,
          title: this.props.translate('timeMachine')
        },
        jobs: {
          checked: checkPrivileges('job'),
          href: '/schedules',
          icon: MailIcon,
          title: this.props.translate('sharedSchedule')
        },

        task: {
          checked: false,
          href: '/task',
          icon: DashboardIcon,
          title: 'Task'
        },
        commands: {
          checked: checkPrivileges('command'),
          href: '/commands',
          icon: CommandIcon,
          title: this.props.translate('sharedSavedCommand')
        },
        maintenance: {
          checked: checkPrivileges('maintenance'),
          href: '/maintenance',
          icon: MaintenanceIcon,
          title: this.props.translate('sharedMaintenance')
        },
        // invoice: {
        //   checked: checkPrivileges('service'),
        //   href: '/invoice',
        //   icon: InvoiceIcon,
        //   title: this.props.translate('sharedInvoice')
        // },
        parameters: {
          checked: checkPrivileges('attribute'),
          href: '/parameters',
          icon: ParameterIcon,
          title: this.props.translate('sharedComputedAttributes')
        }, 
        trash: {
          checked: true,
          href: '/trash',
          icon: TrashIcon,
          title: this.props.translate('trash')
        },
        actionlogs: {
          checked: true,
          href: '/actionlogs',
          icon: actionLog,
          title: this.props.translate('actionLogs')
        },
        
      },
      rightNav: {}
    }
    this.collapseMenu = this.collapseMenu.bind(this)
  }
  
  componentWillReceiveProps(n) {
    this.setState({navigation: {...this.state.navigation, events: {...this.state.navigation.events, count: n.notificationCount}}})
  }

  componentWillMount () {
    this.setState({
      privilagesProcess: false
    })
  }
  handleUserSetting = () => {
    this.props.history.push('/accountManagements')
  }
  handleUserChangedPassword = () => {
    let changedPass = 4
    this.props.history.push('/accountManagements', [changedPass])
  }
  collapseMenu () {
    document.body.classList.toggle('header-open')
  }

  VisibleLeftMenuItem (items) {
    return (
      <MenuBar {...this.props} items={items} collapseMenu={this.collapseMenu} />
    )
  }

  AllMenuItem (items) {
    return Object.keys(items).map((userData, i) => {
      return (
        <li key={i}>
          <label href={items[userData].href} title={items[userData].title}>
            <input
              type='checkbox'
              name={userData}
              onChange={this.showHideMenuItems(userData)}
              checked
            />
            {items[userData].title}
          </label>
        </li>
      )
    })
  }

  refreshPriviliges = () => {
    this.setState(
      {
        privilagesProcess: true
      },
      () => {
        // fetch(`/api/privileges?roleId=${this.props.logInUser.roleId}`, {
        //   method: 'Get'
        // })
        instance({
          url: `/api/privileges`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params:{
            roleId: this.props.logInUser.roleId
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(privileges => {
              let privilegeKeys = []
              privileges.map(item => {
                privilegeKeys.push(item.key)
                return null
              })
              localStorage.setItem(
                'frontPrivileges',
                JSON.stringify({ ...privilegeKeys })
              )
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
    )
  }
  goToLogoutpage = () => {
    localStorage.removeItem('backtoAdmin')
    this.props.history.push('/logout')
  }
  switchUserAccount = () => {
    this.props.history.push(
      '/logout',
      this.props.ServerSetting.attributes.adminUrl
    )
  }

  collapseSidebar = () => {
    this.props.collapseSidebar()
  }

  render () {
    if (this.props.ServerSetting) {
      const { attributes } = this.props.ServerSetting
     
      return (
        <Style>
          {`
				.nav .active {
					color:  ${this.props.themecolors.themeDarkColor};
        }
        .left-nav {
          background-color: ${this.props.themecolors.menuBackgroundColor};
          color:  ${this.props.themecolors.menuTextColor};
        }
        .nav .user-avatar {
          background-color:  ${this.props.themecolors.themeDarkColor};
        }
				.sub-menu label:hover, .sub-menu .active a, .sub-menu a:hover {
					color:  ${this.props.themecolors.themeDarkColor}
        }
        .navbar-menu .navbar-menu-item:hover,
        .navbar-menu .navbar-menu-item.active {
          color:  ${this.props.themecolors.menuActiveColor}; 
          background-color:  ${this.props.themecolors.menuActiveBackground}; 
        }
        .navbar-menu-item .counter {
          background: ${this.props.themecolors.themeLightInverse};
          color: ${this.props.themecolors.themeLightColor}
        }

			`}
          <div>
            <div className='left-nav'>
              <ul className='nav'>
                {this.VisibleLeftMenuItem(this.state.navigation)}
              </ul>
            </div>

            <header className='fms-header'>
              <nav className='nav-container'>
                <div className='logo-section'>
                  <MenuIcon style={{display: 'none'}}>menu</MenuIcon>
                  <button
                    style={{
                      color: this.props.themecolors.themeInverse,
                      background: this.props.themecolors.themeDarkColor
                    }}
                    className='collapse-header'
                    onClick={this.collapseMenu}
                  >
                    <MenuIcon style={{width: 26, height: 26}}>menu</MenuIcon>
                  </button>
                  <NavLink to='/'>
                    <img
                      className='logo'
                      src={
                        this.props.whiteLabling.logo
                          ? this.props.whiteLabling.logo
                          : ''
                      }
                      alt=''
                    />
                  </NavLink>
                </div>
                <div className='right-nav'>
                  <ul className='nav'>
                    {localStorage.getItem('backtoAdmin') ? (
                      <li>
                        <Tooltip
                          title={
                            this.props.translate(
                              'youAreTemporarilyLoggedInAsAnotherUser'
                            ) +
                            ' ' +
                            this.props.translate('switch') +
                            ' ' +
                            this.props.translate('backToYourAccount')
                          }
                          classes={{
                            popper: 'menu-popper',
                            tooltip: 'menu-popper-tooltip'
                          }}
                        >
                          <a
                            href={emptyLink}
                            onClick={this.switchUserAccount}
                            title=''
                          >
                            <Icon>logout</Icon>
                          </a>
                        </Tooltip>
                      </li>
                    ) : null}
                     <li>
                        <Tooltip
                            title={ this.props.notificationCount +" "+this.props.translate('Notifications') }
                            classes={{
                              popper: 'menu-popper',
                              tooltip: 'menu-popper-tooltip'
                            }}
                          >
                          
                          <NavLink
                            to='/events'
                            style={{position: 'relative'}}
                          >
                            {this.props.notificationCount !== undefined ? <span className="counter"></span> : null}
                            <NotificationIcon fill="currentColor" width={18} height={18} />
                          </NavLink>
                          </Tooltip>
                      </li>
                    <li className='has-menu'>
                      <a href={emptyLink} title={this.props.logInUser.name}>
                        <span className='user-menu-name'>
                          {this.props.logInUser.name}
                        </span>
                      </a>
                      <ul className='sub-menu right-menu'>
                        <li>
                          <a href={emptyLink} onClick={this.handleUserSetting}>
                            {this.props.translate('settingsUserSetting')}
                          </a>
                        </li>

                        {attributes.privacyPolicyLink && (
                          <li>
                            <a href={attributes.privacyPolicyLink}>
                              {this.props.translate(
                                'settingsPrivacyPolicyLink'
                              )}
                            </a>
                          </li>
                        )}
                        {attributes.termsLink && (
                          <li>
                            <a href={attributes.termsLink}>
                              {this.props.translate('settingsTermsLink')}
                            </a>
                          </li>
                        )}
                        <li className='divider'></li>
                        <li>
                          <a href={emptyLink} onClick={this.goToLogoutpage}>
                            {this.props.translate('loginLogout')}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
          </div>
        </Style>
      )
    } else {
      return null
    }
  }
}

export default connect(mapStateToProps)(withLocalize(withRouter(Header)))
