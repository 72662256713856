import React, { useState, useEffect } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import TextField from '../common/TextField';

function ReleaseHistory(props) {
  const [releaseData, setReleaseData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredReleases, setFilteredReleases] = useState([]);

  useEffect(() => {
    fetch('/releaseData.json')
      .then(response => response.json())
      .then(data => {
        console.log("data====", data);
        setReleaseData(data.releases);
        setFilteredReleases(data.releases); // Initial set of filtered releases
      });
  }, []);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filtered = releaseData.filter(release => 
      release.version.toLowerCase().includes(searchValue)
    );
    setFilteredReleases(filtered);
  };

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <h2 style={{ color: "white", flexGrow: 0.2 , marginTop:"10px"}}>Release History</h2>
        <TextField
          type="text"
          placeholder="Search Release Version"
        //   style={{ padding: '5px', marginRight: '10px' }}
          onChange={handleSearch}
          
        //   placeholder ="Release 1.1"
        />
      </div>
      <Scrollbar
        disableTracksWidthCompensation={true}
        style={{ height: '210px' }}
        scrollerProps={{
          renderer: props => {
            const { elementRef, ...restProps } = props;
            return (
              <div {...restProps} ref={elementRef} id='scrollableDiv' />
            );
          }
        }}
      >
        <ul>
          {filteredReleases.length > 0 ? (
            filteredReleases.map(release => (
              <li key={release.version}>
                <h3 style={{ margin: '10px 0px' }}> {release.version} - {release.date}</h3>
                <ul>
                  {release.changes?.map(change => (
                    <li key={change} style={{ margin: '3px 0px' }}>{change}</li>
                  ))}
                </ul>
              </li>
            ))
          ) : (
            <p style={{ textAlign: 'center' }}>No releases found for your search</p>
          )}
        </ul>
      </Scrollbar>
    </div>
  );
}

export default ReleaseHistory;
