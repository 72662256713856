import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import { SnackbarProvider } from 'notistack'
import combineReducers from './Reducers'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import SocketController from './SocketController'
import SocketControllerShare from './SocketControllerShare'

import { LocalizeProvider } from 'react-localize-redux'
import thunk from 'redux-thunk'

export const store = createStore(combineReducers, compose(applyMiddleware(...[thunk])));
const rootElement = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        preventDuplicate
      >
        <BrowserRouter>
          <SocketController />
          {/* <SocketControllerShare/> */}
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </LocalizeProvider>
  </Provider>,
  rootElement
)

registerServiceWorker()
