import React, { Component } from 'react'
import { logInUserInfo, getRoles } from './../../Actions/Users'
import { ServerInfo } from './../../Actions/serverSetting'
import { getLayer } from './../../Actions/Maps'
import {
  getDevices2,
  fetchDevicesList,
  fetchDevices
} from './../../Actions/Devices'
import { connect } from 'react-redux'
import Loader from './../../Layout/Loader'
import {
  displayErrorMessages,
  setCookie,
  checkedDevelopmentType,
  getCookie,
  errorHandler,
  checkPrivileges
} from '../../Helpers/index'
import { withLocalize } from 'react-localize-redux'
import instance from '../../axios'

class loginAsUser extends Component {
  constructor (props) {
    super(props)
    this.state = { loading: false, loginErrorMessage: '' }
  }
  componentWillMount () {
    this.setState({
      loading: true,
      loginErrorMessage: ''
    })
    this.loginAPiCall()
  }
  loginAPiCall = () => {
    if (this.props.location.search) {
      let params = this.props.location.search.toString().split('&')
      if (params[2]) {
        localStorage.setItem('backtoAdmin', params[2])
      }
      let fetchUrl = checkedDevelopmentType()
  
      instance({
        url: `/api/session`,
        method: 'GET',
        headers: {
          UIP: getCookie('UIP')
        },
        params: {
          token: atob(params[1]),
          app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
          host: fetchUrl
        }
      })
  
            .then(userInfoFront => {
              if (userInfoFront) {
                let lang = userInfoFront.attributes.lang
                this.props.setActiveLanguage(lang || 'en')
                let nToken = this.generateToken()
                let obj = userInfoFront
                obj.token = nToken
                
                instance({
                  url: `/api/users/${userInfoFront.id}`,
                  method: 'PUT',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                  },
                  data: {
                    ...obj
                  }
                })
             
                    .then(user => {
                      this.props.dispatch(logInUserInfo(user))
                
                }).catch(error => {
                })
                instance({
                  url: `/api/devices/list`,
                  method: 'GET',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                  },
                  params: {
                    userId: userInfoFront.id,
                    all: true,
                    limit: -1
                  }
                })
              
                    .then(devices => {
                      this.props.dispatch(getDevices2(devices.data))
               
                }).catch(error => {
                })
                if (userInfoFront.accountId && userInfoFront.accountId !== 0) {
                  instance({
                    url: `/api/accounts`,
                    method: 'GET',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json'
                    },
                    params: {
                      accountId: userInfoFront.accountId
                    }
                  })
            
                        .then(server => {
                          if (server[0]) {
                            this.props.dispatch(ServerInfo(server[0]))
                            this.props.dispatch(
                              getLayer({
                                id: userInfoFront.map || server[0].map || 'osm'
                              })
                            )
                            instance({
                              url: `/api/privileges`,
                              method: 'GET',
                              headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json'
                              },
                              params: {
                                roleId: userInfoFront.roleId
                              }
                            })
                                .then(privileges => {
                                  let privilegeKeys = []
                                  privileges.map(item => {
                                    privilegeKeys.push(item.key)
                                    return null
                                  })
                                  localStorage.setItem(
                                    'frontPrivileges',
                                    JSON.stringify({ ...privilegeKeys })
                                  )
                                  if (privileges.length) {
                                    this.props.history.push('/')
                                    this.props.dispatch(
                                      fetchDevicesList(userInfoFront)
                                    )
                                    this.props.dispatch(
                                      fetchDevices(userInfoFront)
                                    )
                                   if (checkPrivileges('role')) {
                                      instance({
                                            url: `/api/roles`,
                                            method: 'GET',
                                            params:{
                                              userId: userInfoFront.id
                                            }
                                          })
                                          .then(role => {
                                            this.props.dispatch(getRoles(role))
                                          }).catch(error => {
                                          // errorHandler(error, this.props.dispatch)
                                        })
                                    }

                                  } else {
                                    this.setState(
                                      {
                                        loading: false,
                                        loginErrorMessage: 'Lack Of Privileges'
                                      },
                                      () => {
                                        this.someThingWendWrong(
                                          'Lack Of Privileges'
                                        )
                                      }
                                    )
                                  }
                        
                          }).catch(error => {
                          })
                          } else {
                            this.someThingWendWrong('Account Data Not Found')
                          }
                     
                    })
                    .catch(error => {
                      this.errorMessage(error)
                    })
                } else {
                  this.someThingWendWrong('User Account is not set')
                }
              } else {
                this.someThingWendWrong('user not Found')
              }
            })
     
        .catch(error => {
          this.errorMessage(error)
        })
    }
  }
  someThingWendWrong = mgs => {
    if (localStorage.getItem('backtoAdmin')) {
      localStorage.setItem('backtoAdmin', '')
      this.props.history.push('/logIn', mgs)
    } else {
      this.props.history.push('/logIn', mgs)
    }
  }
  errorMessage = err => {
        this.setState(
          {
            loading: false,
            loginErrorMessage: displayErrorMessages(err.message)
          },
          () => {
            //redirect to admin if he come from admin
            if (localStorage.getItem('backtoAdmin')) {
              localStorage.setItem('backtoAdmin', '')
              this.props.history.push(
                '/logIn',
                this.props.translate(this.state.loginErrorMessage)
              )
            } else {
              this.props.history.push(
                '/logIn',
                this.props.translate(this.state.loginErrorMessage)
              )
            }
          }
        )
  }

  generateToken = () => {
    let symbols =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i,
      newToken = ''
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }
    return newToken
  }

  render () {
    if (this.state.loading) {
      return <Loader />
    } else {
      return null
    }
  }
}
const mapState = state => {
  return {}
}
const mapStateToProps = connect(mapState)

export const LoginAsUser = mapStateToProps(withLocalize(loginAsUser))
