import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { checkPrivileges, errorHandler } from '../../Helpers'
import Layout from './../../Layout'
import Button from '../../Components/common/Button'
import Notifications from 'react-notification-system-redux'
import {Grid, InputAdornment} from '@material-ui/core'
import instance from '../../axios'
import Table from '../../Components/common/tableWithBackEndPagination'
import TextField from '../../Components/common/TextField'
import isEqual from 'react-fast-compare'
import axios from 'axios'

const CancelToken = axios.CancelToken
let source

const DataFormat = (data,logInUser) => {
  return {
        id: data && data.id ? data.id : 0,  
        updatedBy: data && data.updatedBy ? data.updatedBy : 0,
        startPoint: data && data.startPoint ? data.startPoint :'',
        startOutSkirts :data && data.startOutSkirts ? data.startOutSkirts :'',
        endPoint: data && data.endPoint ? data.endPoint :'',
        endOutSkirts:data && data.endOutSkirts ? data.endOutSkirts :'',
        mileage: data && data.mileage ? parseFloat(data.mileage) :'',
        perDiemCost: data && data.perDiemCost ? parseFloat(data.perDiemCost) :0,
        foodCost: data && data.foodCost ? parseFloat(data.foodCost) :'',
        cost: data && data.cost ? parseInt(data.cost) :'',
        parentId: data && data.parentId ? data.parentId :logInUser&&logInUser.id,
        updatedBy: data && data.updatedBy ? data.updatedBy :'',
        modified: data && data.modified ? data.modified :'',
        created: data && data.created ? data.created :'',
        attributes: data && data.attributes ? data.attributes :{},
  }
}

class Commands extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mode: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 20,
      showTable:true,
      showForm:false,
      form:{}, 
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 1,
        hasNext: true,
        searchText: ''
      },
      isVisible:true
    }
  }

  componentWillUnmount () {
    this.setState({
      mode: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 20,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 1,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
      metrixData:[]
    })
  }

  componentWillMount () {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          // this.fetchTypes()
        }
      )
    }
  }

  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            n.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          // this.fetchTypes()
        }
      )
    }
  }

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize
    )
  }


  
 searchItems =(e)=>{
  if(source){
    source.cancel()
  }
     this.setState(
       {
         searchText: e
       },
       () => {
           this.fetchData(
           this.props.logInUser,
           this.state.currentPage,
           this.state.pagesize,
           )
         })
  
   }
  

  fetchData = (userId, page, perPage, reset) => {
    source = CancelToken.source()
    const query = 'all=true'
    let searchText = this.state.searchText

    if (searchText) {
      searchText = '&search=' + searchText
    } else {
      searchText = ''
    }
    let items = this.state.itemPagination.items
    if (reset) {
      this.setState(
        {
          itemPagination: { ...this.state.itemPagination, items: [] }
        },
        () => {
          items = []
        }
      )
    }
    instance({
      url: `/api/workflows/matrix/get?${query}&page=${page}&limit=${perPage}${searchText}`,
      method: 'GET',
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
        this.setState({metrixData:response})
    }).catch(error => {
      // errorHandler(error,this.props.dispatch)
    })
  }

  handleChange = (name, value) => {
      this.setState({
          form:{
            ...this.state.form,
            [name]: value
          }
      }, ()=>{
        this.checkRequiredFields()
      })
  } 

  checkRequiredFields = () => {
    const {startPoint, endPoint,mileage,cost} = this.state.form
    if(startPoint && endPoint && mileage && cost){
      if(this.state.mode==='edit'){
        let value = isEqual(this.state.form, this.state.form2)
            this.setState({
              isVisible:value
            })
      }
      else{
        this.setState({isVisible:false})
      }
    }
    else {
      this.setState({
      isVisible:true
    }
    )}
  }

  handleChangeRowsPerPage = (value) => {
    if(source){
      source.cancel()
    }
      this.setState(
        {
          pagesize: value,
          currentPage: 1
        },
        () => {
            this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize)
          })
  }
     
  handleChangePage = (value)=>{
    if(source){
      source.cancel()
    }
      this.setState(
        {
          currentPage: value
        },
        () => {
            this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize,
            )
          })
  }

   setPagination = user => {
    const { metrixData } = this.state
    if (metrixData && metrixData.data && metrixData.data.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / this.state.pagesize
      let IsFloate = this.checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pageSize = this.state.pagesize
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }
     
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
   }

   editMetricsForm = (item) =>{
    this.setState({
      form:item,
      form2:item,
      mode:'edit',
      showTable:false,
      showForm:true,
      isVisible:true
    })
  }
  
  openCreateFrom = ()=>{
      this.setState({
        mode:'create',
        showTable:false,
        showForm:true,
        form:{}
      })
  }
  closeCreateFrom = ()=>{
    this.setState({
      showForm:false,
      showTable:true,

      form:{}
    })
}
  isSubmit = ()=>{
    const obj = DataFormat(this.state.form,this.props.logInUser)

    instance({
      url: this.state.mode === 'edit' ? `/api/workflows/matrix/${this.state.form.id}` :`/api/workflows/matrix`,
      method: this.state.mode === 'edit' ? 'PUT': 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
    .then(response => {
            this.props.dispatch(
              Notifications.success({
                message:this.state.mode === 'edit' ? this.props.translate('metricsUpdated'): this.props.translate('metricsCreated'),
                autoDismiss: 10
              })
            )
          this.setState({ 
            form:{},
            showTable:true,
            showForm:false,
           }, ()=>{ this.fetchData(this.props.logInUser, 1, this.state.pagesize, true)})
      })
      .catch(error => {errorHandler(error,this.props.dispatch)})
  }

  render () {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          -- {this.props.translate('end')} --
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {this.props.translate('notFound')}
        </p>
      )
        return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            classFromChildren='has-padding'
            closeCreateFrom={this.closeCreateFrom}
            editMetricsForm={this.editMetricsForm}
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            itemPagination={{ ...this.state.itemPagination }}
            modeEmpty={this.modeEmpty}
            {...this.state}
          >
            <div className='main-content-page'>
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  padding: 16
                }}
              >
                  {this.state.showTable ?(
                  <Table
                      rows={this.state?.metrixData?.data || []}
                      pagination={this.setPagination(this.state?.metrixData || {} )}
                      handleChangeRowsPerPage={(n)=>this.handleChangeRowsPerPage(n,'metrix')}
                      handleChangePage={(n)=>this.handleChangePage(n,'metrix')}
                      logInUser={this.props.logInUser}
                      isEditable={false}
                      status={false}
                      completeMark={false}
                      themecolors={this.props.themecolors}
                      translate={this.props.translate}
                      hasAccessOfCreate={false}
                      hasAccessOfUpdate={false}
                      hasAccessOfDelete={false}
                      metrics
                      rowDefinition={[
                        {
                          id: 'startPoint',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('startPoint')
                        },
                        {
                          id: 'startOutSkirts',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('startOutSkirts')
                        },
                        {
                          id: 'endPoint',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('endPoint')
                        },
                        {
                          id: 'endOutSkirts',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('endOutSkirts')
                        },
                        {
                          id: 'mileage',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('Mileage (km)')
                        },
                        {
                          id: 'perDiemCost',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('perDiemCost')
                        },
                        {
                          id: 'foodCost',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('foodCost')
                        },
                        {
                          id: 'cost',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('cost km')
                        },
                        {
                          id: 'created',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('createdTime')
                        }
                      ]}
                      />
                  ):null }
                  {this.state.showForm ? (
                      <CreateMetixForm
                       closeCreateFrom={this.closeCreateFrom}
                        form={this.state.form}
                        handleChange={this.handleChange}
                        translate={this.props.translate}
                        isVisible={this.state.isVisible}
                        isSubmit={this.isSubmit}
                        mode={this.state.mode}
                        logInUser={this.props.logInUser}
                      />
                    ) : null}
                {/* {this.state.mode === '' ? (
                  <EmptyState
                    text={this.props.translate('noCommandSelected')}
                  />
                ) : null} */}
              </div>
            </div>
          </Layout>
        </div>
      )
    
  }
}

const CreateMetixForm = ({
  form,
  handleChange,
  translate,
  isSubmit,
  isVisible,
  mode,
  logInUser,
  closeCreateFrom
}) => {
  return (
    <div>
      <div style={{display:"flex", justifyContent:"space-between"}}>
      <h4 className='page-title'>{translate('Metrics')}</h4>
      <Button onClick={closeCreateFrom}>{translate('sharedBack')}</Button>
      </div>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='startPoint'
                required
                margin='dense'
                label={translate('startPoint')}
                variant='outlined'
                fullWidth
                value={form?.startPoint || ''}
                onChange={e => handleChange('startPoint', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='startOutSkirts'
                required
                margin='dense'
                label={translate('startOutSkirts')}
                variant='outlined'
                fullWidth
                value={form?.startOutSkirts || ''}
                onChange={e => handleChange('startOutSkirts', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='endPoint'
                required
                margin='dense'
                label={translate('endPoint')}
                variant='outlined'
                fullWidth
                value={form?.endPoint || ''}
                onChange={e => handleChange('endPoint', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='endOutSkirts'
                required
                margin='dense'
                label={translate('endOutSkirts')}
                variant='outlined'
                fullWidth
                value={form?.endOutSkirts || ''}
                onChange={e => handleChange('endOutSkirts', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='mileage'
                required
                margin='dense'
                label={translate('Mileage km')}
                variant='outlined'
                fullWidth
                value={form?.mileage || ''}
                type='number'
                onChange={e => handleChange('mileage', e.target.value)}
              />
            </Grid>

            
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='perDiemCost'
                required
                margin='dense'
                label={translate('perDiemCost')}
                variant='outlined'
                fullWidth
                type='number'
                value={form?.perDiemCost  || ''}
                onChange={e => handleChange('perDiemCost', e.target.value)}
                InputProps={{
                    style: {padding: 0},
                    endAdornment: (
                      <InputAdornment
                        position='start'
                        disableTypography
                      >
                        {logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                      </InputAdornment>
                    )
                }} 
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='foodCost'
                required
                margin='dense'
                label={translate('foodCost')}
                variant='outlined'
                fullWidth
                type='number'
                value={form?.foodCost || ''}
                onChange={e => handleChange('foodCost', e.target.value)}
                InputProps={{
                    style: {padding: 0},
                    endAdornment: (
                      <InputAdornment
                        position='start'
                        disableTypography
                      >
                        {logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                      </InputAdornment>
                    )
                }} 
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='cost'
                required
                margin='dense'
                label={translate('cost km')}
                variant='outlined'
                fullWidth
                type='number'
                value={form?.cost || ''}
                onChange={e => handleChange('cost', e.target.value)}
                InputProps={{
                    style: {padding: 0},
                    endAdornment: (
                      <InputAdornment
                        position='start'
                        disableTypography
                      >
                        {logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                      </InputAdornment>
                    )
                }} 
              />
            </Grid>

          </Grid>
          <Grid container spacing={1} style={{ margin:'5px 15px 0 0' }}>
                <Grid item sm={12} xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                    <Button
                        size='small'
                        // className='button-white'
                        onClick={()=>isSubmit()} 
                        disabled={isVisible}
                    >
                        {mode === 'edit' ? translate('Update'):translate('Create') }{' '}
                    </Button>
                </Grid>
             </Grid>  
        </div>
)}
const mapStateToProps = state => ({
  themecolors: state.themeColors,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withSnackbar(withLocalize(Commands)))
