import React from 'react'
import TimeAgo from 'react-timeago'
import { setAttributeFormat,setIconOnList,SpeedUnits } from '../../Helpers'
import PinIcon from './../../assets/nav/pin.svg'
import CoordinatesIcon from './../../assets/nav/coordinates.svg'
import SpeedIcon from './../../assets/nav/speed.svg'
// import { ReactComponent as FileCopyIcon } from './../../assets/monitoring/copy.svg'
import { ReactComponent as OtherSensorIcon } from './../../assets/sensors/sensor.svg'
import { ReactComponent as ElogicIcon } from './../../assets/nav/algorithm.svg'
import { ReactComponent as AggregateIcon } from './../../assets/sensors/chip.svg'
import { ReactComponent as MeasurementIcon } from './../../assets/sensors/centimeter.svg'
import { ReactComponent as DiscreteIcon } from './../../assets/sensors/discrete.svg'
// import { ReactComponent as StaffIcon } from './../../assets/nav/staff.svg'
import StaffIcon from '@material-ui/icons/Person'
import CallIcon from '@material-ui/icons/Call'
import { ReactComponent as TrailerIcon } from './../../assets/nav/trailerr.svg'
import MotorIcon from './../../assets/nav/motor.svg'
import RoadIcon from './../../assets/nav/road.svg'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import moment from 'moment'


const Wrapper = ({
  disabled,
  attribute,
  value,
  unit,
  customUnit,
  description,
  logInUserAttr,
  category,
  driver,
  trailer,
  fixDriver,
  translate,
  driverDetails
}) => {
  let u = ''
  let driverRow = null
  if(attribute === 'driverUniqueId' && driver && driver.id && value && driverDetails) {
      driverRow = (<>
      <div className='sensor-item'>
        <StaffIcon style={{marginRight: 5, verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> 
        {driver && driver.name || 'unknown'}
      </div>
      <div className='sensor-item'>
        <CallIcon style={{marginRight: 5, verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> 
        {driver && driver.attributes && driver.attributes.phone || ''}
      </div>
      </>);
  }
  if(fixDriver) {
    driverRow = (<>
      <div
        // style={{
        //   background: themecolors.themeLightColor,
        //   color: themecolors.themeLightInverse
        // }}
        className='unit-tooltip-address-bg-heading'
      >
        <strong>{translate('sharedDriver')}</strong>
      </div>
      <div className='unit-tooltip-address clearfix'>
        <div className='sensor-item'>
          <StaffIcon style={{marginRight: 5, verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> 
          {fixDriver.name || 'unknown'}
        </div>
        {fixDriver.attributes && fixDriver.attributes.phone ?
        <div className='sensor-item'>
          <CallIcon style={{marginRight: 5, verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> 
          {fixDriver.attributes.phone}
        </div> : null}
      </div>
      </>)}

  let trailerRow =null
  if(attribute === 'trailerUniqueId' && trailer && trailer.id && value) {
    trailerRow = (<div className='sensor-item'>
      <TrailerIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> <span style={{marginLeft: 5}}>{translate && translate('trailersName')}:</span> {trailer && trailer.name || 'unknown'}
    </div>);
  }
  if (attribute === 'mileage' || attribute === 'distance') {
    u = logInUserAttr.distanceUnit || customUnit || unit
  } else if (attribute === 'fuel') {
    u = logInUserAttr.volumeUnit || customUnit || unit
  } else if (attribute === 'speed') {
    u = logInUserAttr.speedUnit || customUnit || unit
  } else {
    u = customUnit || unit
  }

  /* if (unknown && unknown.length && unknown.indexOf(attribute) !== -1) {
    return (
      <div className='clearfix item-row'>
        <span className='row-label'>{description}</span>
        <span className='row-value'>Unknown</span>
      </div>
    )
  } else */ if (value !== undefined) {
    return (<>
      {!disabled ? <div className='sensor-item'>
        {category === 0 ? <ElogicIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> : category === 1 ? <DiscreteIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> : category === 2 ? <MeasurementIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> : category === 3 ? <OtherSensorIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> : <AggregateIcon style={{verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} />} <span style={{marginLeft: 5}}>{description}:</span> {setAttributeFormat(attribute, value, u, null, category)}
      </div> : null}
      {driverRow} {trailerRow}</>
    )
   
  } else return null
}
class MapTooltip1 extends React.PureComponent {
  render () {
    const {
      trailer,
      position,
      device,
      translate,
      driver,
      themecolors,
      allComputedAttributes,
      computedAttributes,
      logInUser,
      isShare
    } = this.props
    const park = (position?.attributes?.stopTime > (parseInt(this.props.device.attributes?.minimalParkingDuration) * 1000));
    const ms = (position?.attributes?.stopTime - (parseInt(this.props.device.attributes?.minimalParkingDuration) * 1000));
    const pp = position?.icons?.playicon?.label || ""   //motion Status
    // let color = device.status === "online"  ?
    //         pp === "online"  ? "#00BF4E" :
    //         pp === "moving"  ? "#00BF4E" :
    //         pp === "parking"  ? "#1E90FF" :
    //         pp === "offline"  ? "#FF0000" :
    //         pp === "idling"  ? "#ffc107" :
    //         pp === "stop"  ? "#87CEFA" :
    //         pp === "gpsNotUpdated"  ? "#1976d2" :
    //         pp === "not-updated"  ? "#1976d2" : "#FF0000"
    //     : device.status === "gpsNotUpdated"  ?  "#1976d2"
    //     : "#FF0000"

      const viewUserType = this.props?.logInUser?.attributes?.unitsViewType; // Assuming you have the value of viewUserType

      let color;

      if (viewUserType === "unitStatus") {
          // Set color based on device.status
          switch (device.status) {
              case "online":
                  color = "#00BF4E"; // Green
                  break;
              case "offline":
                  color = "#FF0000"; // Red
                  break;
              case "gpsNotUpdated":
                  color = "#1976d2"; // Grey
                  break;
              default:
                  color = "orange"; // Default to red if status is not recognized
          }
      } else if (viewUserType === "motionStatus") {
         // Set color based on pp (position.icons.playicon.label)
         const pp = position?.icons?.playicon?.label || "";
         switch (pp) {
             case "online":
             case "moving":
                 color = "#00BF4E"; // Green
                 break;
             case "parking":
                 color = "#1E90FF"; // Blue
                 break;
             case "idling":
                 color = "#ffc107"; // Yellow
                 break;
             case "stop":
                 color = "#87CEFA"; // Light Blue
                 break;
             case "not-updated":
                 color = "#1976d2"; // Grey
                 break;
             default:
                 color = "#FF0000"; // Default to red if motion status is not recognized
         }
      }
      //  else {
      //   color =  device?.attributes?.color || '';
      // }


    let calculateTime = ms > 3600000 ? true : false
    let parking = ms > 0 ? ms : 0 
    let updatedColor=this.props.isShare?this.props.device?.attributes?.color:color
    return (
      <div className='unit-tooltip-custom'>
        <div className='unit-tooltip-custom-header clearfix'>
          <div className='unit-tooltip-address'>
          { viewUserType === "default" ? 
            <img
              style={{height:"50px"}}
              src={
                '/assets/category/default/' +
                (this.props.device.category || 'default') +
                '.svg'
              }
              alt={this.props.device.name }
            />
             : setIconOnList(device.category,updatedColor)
             }
  
            {/* <img
              width='40px'
              height='40px'
              src={`/assets/category/default/${device.category ||
                'default'}.svg`}
              alt=''
            /> */}
            <span className='unit-tooltip-heading'>
              {device.name} <br />
              <small>{logInUser.userType===-1 ? device.uniqueId : 
              logInUser&&logInUser.attributes&&logInUser.attributes.viewIMEI?device.uniqueId:null}</small>
            </span>
          </div>
        </div>

        {position.address ? (
          <div className='unit-tooltip-address'>
            <strong>
              <svg fill='currentColor' width={14} height={14}>
                <use xlinkHref={`${PinIcon}#icon`} />
              </svg>
            </strong>
            &nbsp;
            <span>
              {position.address ? ReactHtmlParser(`${position.address}`) : null}
            </span>
          </div>
        ) : null}

        {position.latitude ? (
          <div className='unit-tooltip-address'>
            <strong>
              <svg fill='currentColor' width={14} height={14}>
                <use xlinkHref={`${CoordinatesIcon}#icon`} />
              </svg>
            </strong>
            &nbsp;
            <span>
              {setAttributeFormat('latitude', position.latitude)},
              {setAttributeFormat('longitude', position.longitude)}
              {/* {document.queryCommandSupported('copy') && (
                <button
                  onClick={e => copyToClipboard(position)}
                  className='button-to-normal'
                >
                  <FileCopyIcon fill='currentColor' width='14' height='14' />
                </button>
              )} */}
            </span>
            <span className='pull-right'>
              <TimeAgo date={position.serverTime} minPeriod={60} />
            </span>
          </div>
        ) : null}

        <div className='speed-row unit-tooltip-address clearfix'>
        {!(park) && 
          (<div className='sensor-item-2'>
            <strong>
              <svg fill='currentColor' width={14} height={14}>
                <use xlinkHref={`${SpeedIcon}#icon`} />
              </svg>
              {translate('sensorsTranslation.speed')}
            </strong>{' '}
            <br />
            <span>
              {!isShare?setAttributeFormat('speed', position.speed || 0):SpeedUnits(position.speed||0,"kmh")}
            </span>
          </div>)}
          {(park) && 
            (<div className='sensor-item-2'>
              <strong>
                <svg fill='currentColor' width={14} height={14}>
                  <use xlinkHref={`${SpeedIcon}#icon`} />
                </svg>
                {position.attributes?.ignition?translate('idling'):translate('parking')}
              </strong>{' '}
              <br />
              <span>
              {calculateTime ? 
                moment.duration(parking).format('h[h] m[m]') :
                moment.duration(parking).format('m[m] s[s]')
                }
              </span>
            </div>)}

          <div className='sensor-item-2'>
            <strong>
              <svg fill='currentColor' width={14} height={14}>
                <use xlinkHref={`${MotorIcon}#icon`} />
              </svg>
              {translate('reportEngineHours')}
            </strong>{' '}
            <br />
            <span>
              {position &&
              position.attributes &&
              position.attributes?.hours ? (position.attributes?.hours / (1000 * 3600)).toFixed(2)+" hrs" : null}
            </span>
          </div>

          <div className='sensor-item-2'>
            <strong>
              <svg fill='currentColor' width={14} height={14}>
                <use xlinkHref={`${RoadIcon}#icon`} />
              </svg>
              {translate('sensorsTranslation.mileage')}
            </strong>{' '}
            <br />
            <span>
              {setAttributeFormat(
                'distance',
                (position &&
                  position.attributes &&
                  position.attributes?.totalDistance) ||
                  0
              )}
            </span>
          </div>
        </div>
        <div
          style={{
            background: themecolors.themeLightColor,
            color: themecolors.themeLightInverse
          }}
          className='unit-tooltip-address-bg-heading'
        >
          <strong>{translate('sensors')}</strong>
        </div>
        <div className='unit-tooltip-address clearfix'>
          {position.attributes &&
            allComputedAttributes &&
            allComputedAttributes.length
              ? allComputedAttributes.map(({item, deviceIds}) => {
                  return (
                    deviceIds.includes(device.id) ? <Wrapper
                      key={item.id}
                      {...item}
                      logInUserAttr={
                        this.props.logInUser
                          ? this.props.logInUser.attributes
                          : {}
                      }
                      category={item.category}
                      unit={item.attributes && item.attributes.unit}
                      customUnit={item.attributes && item.attributes.customUnit}
                      value={position.attributes[item.attribute]}
                      unknown={position.attributes.unknown}
                      driver={driver}
                      trailer={trailer}
                      translate={this.props.translate}
                    /> : null
                  )
                })
              : null}
        </div>
        
          {position.attributes &&
            allComputedAttributes &&
            allComputedAttributes.length
              ? allComputedAttributes.map(({item, deviceIds}) => {
                  return (
                    driver && deviceIds.includes(device.id) && item.attribute === 'driverUniqueId' ? <>
                    <div
                      style={{
                        background: themecolors.themeLightColor,
                        color: themecolors.themeLightInverse,
                        marginTop: 15
                      }}
                      className='unit-tooltip-address-bg-heading'
                    >
                      <strong>{item.description}: {setAttributeFormat(item.attribute, position.attributes[item.attribute], item.attributes.unit, null, item.category)} </strong>
                    </div>
                    <div className='unit-tooltip-address clearfix'>
                    
                      <Wrapper
                        driverDetails
                        key={item.id}
                        {...item}
                        logInUserAttr={
                          this.props.logInUser
                            ? this.props.logInUser.attributes
                            : {}
                        }
                        category={item.category}
                        unit={item.attributes && item.attributes.unit}
                        customUnit={item.attributes && item.attributes.customUnit}
                        value={position.attributes[item.attribute]}
                        unknown={position.attributes.unknown}
                        driver={driver}
                        fixDriver={this.props.fixDriver}
                        trailer={trailer}
                        translate={this.props.translate}
                        disabled
                      />
                    </div></> : null
                  )
                })
              : null}
              {this.props.fixDriver ? (
              <>
                <div
                  style={{
                    background: themecolors.themeLightColor,
                    color: themecolors.themeLightInverse
                  }}
                  className='unit-tooltip-address-bg-heading'
                >
                  <strong>{translate('sharedDriver')}</strong>
                </div>
                <div className='unit-tooltip-address clearfix'>
                  <div className='sensor-item'>
                    <StaffIcon style={{marginRight: 5, verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> 
                    {this.props.fixDriver.name || 'unknown'}
                  </div>
                  {this.props.fixDriver.attributes && this.props.fixDriver.attributes.phone ?
                  <div className='sensor-item'>
                    <CallIcon style={{marginRight: 5, verticalAlign: 'middle'}} fill="currentColor" width={16} height={16} /> 
                    {this.props.fixDriver.attributes.phone}
                  </div> : null}
                </div>
                </>) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => 
  {
    let fixDriver = null;
    if(props.device&&props.device.attributes.fixDriverId){
      fixDriver = state.drivers.find(item => item.id === props.device.attributes.fixDriverId)
    }
    return({
      fixDriver,
      computedAttributes: state.computedAttributes,
      allComputedAttributes: state.allComputedAttributes
    })
}

export const MapTooltip = connect(mapStateToProps)(MapTooltip1)