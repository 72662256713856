import React, { Component } from 'react'
import Button from './../../../Components/common/Button'
import SearchItems from './../../../Components/Devices/searchItems'
import Grid from '@material-ui/core/Grid'
import DateRangePicker from './../../../Components/common/DateRangePicker'
import Scrollbar from 'react-scrollbars-custom'
import Checkbox from '../../../Components/common/Checkbox'
import moment from "moment";
import FormHelperText from '@material-ui/core/FormHelperText';


export default class MessagesSidebar extends Component {
  createMessagesReport = (type) => {
    this.props.collapseSidebar()
    this.props.createMessagesReport(1,type)
  }

  render () {
    let viewScreen = (
      <form style={{ padding: 20 }}>
        <h3 className='page-title'>{this.props.translate('DlogsForm')}</h3>
        <Grid container>
          <Grid item xs={12}>
            <DateRangePicker
              fill
              selectedDate={this.props.selectedDate}
              label={
                this.props.translate('reportFrom') +
                ' ' +
                this.props.translate('reportTo')
              }
              onEvent={this.props.selectDate}
              dlogs
              isDisabled={(date) => moment().diff(date, 'days', true) <= 0 || moment().diff(date, 'days', true) >= 3}
            />
               <FormHelperText 
                      style={{ fontWeight: "bold", color: "#ffc107" , fontSize:"12px"}} // Changed fontWeight and color
                    >
                      {this.props.translate(`You can select maximum 3 days`)}
                    </FormHelperText>
          </Grid>

          <Grid item xs={12} style={{ padding: '0 0 8px' }}>
            <SearchItems
              api='devices'
              fill
              isMulti={false}
              onChange={this.props.selectDevice}
              value={
                this.props.selectedDevice && this.props.selectedDevice.label
                  ? this.props.selectedDevice
                  : null
              }
              placeholder={this.props.translate('selectedTrackers')}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: '0 0 8px' }}>    
          <Checkbox
              checked={this.props.detail && this.props.detail}
              onChange={e =>this.props.changeDetail('detail', e)}
              canAssign
              canRemove
              label={this.props.translate('detail')}
          />
           </Grid>
          <Grid item xs={12} style={{ padding: '0 0 8px' }}>
            <Button
              fullWidth
              disabled={!this.props.validatedForm}
              onClick={this.createMessagesReport}
            >
              {this.props.translate('submit')}
            </Button>
          </Grid>
          <div className='report-sidebar-switch' style={{margin: '0 auto', marginTop: '-5px'}}></div>
          <Grid item xs={12} style={{ padding: '0 0 8px' }}>
            <Button
              fullWidth
              disabled={!this.props.validatedForm}
              onClick={e => this.createMessagesReport('excel')}
            >
              {this.props.translate('DOWNLOAD')}
            </Button>
          </Grid>
        </Grid>
      </form>
    )

    if (viewScreen) {
      return (
        <aside className='fms-sidebar sidebar-bg-layer'>
          <Scrollbar>{viewScreen}</Scrollbar>
        </aside>
      )
    } else {
      return null
    }
  }
}
