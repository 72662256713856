import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import {errorHandler, getCookie, setCookie } from '../../Helpers'
import { connect } from 'react-redux'
// import './Style.scss'
import axios from 'axios'
import Notifications from 'react-notification-system-redux'
import instance from '../../axios'
import moment from 'moment'
import ShareMap from '../../Components/Maps/ShareMap'
import { logInUserInfo } from '../../Actions/Users'
import Loader from '../../Layout/Loader'
import { getLayer } from '../../Actions/Maps'

const options = {
  width: 250,
  margin: 10
}

const maxBoxes = 20;

const CancelToken = axios.CancelToken
let source
class MonitoringShareLocation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      deviceData:null,
      userInfoFront: {},
      userFetch: false,
      userId: props.logInUser && props.logInUser.id ? props.logInUser.id : 0,
      initFetch: false,
      prepareTags: false,
      vehicleModal: false,
      driverModal: false,
      resourceList: false,
      linkResource: false,
      selecteditem: '',
      showAll:false,
      currentPage2: 1,
      pageSize2: -1,
      itemPagination2: {
        isFetching: true,
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: ''
      },
      boxes: [],
      sensorsDesc:[],
      showMonitoringTracks : false,
      routes : {},
      listType:"geofences",
      center:null,
      lastDeviceId:null,


    }
    this.map = null
  }
  determineAndDispatchLayer(userInfo) {
    const maps = userInfo.attributes?.maps || [];
    const mapLayer = maps.includes('googleRoad') ? 'googleRoad' : 'osm';
    this.props.dispatch(getLayer({ id: mapLayer}));
  }
  componentWillMount () {
    this.checkUserSession();
  }

  checkUserSession = () => {
    const token = localStorage.getItem("backtoAdmin");
    
    if (!this.props.logInUser.id) {
      if (!token) {
        this.props.history.push('/login');
        return;
      }
      // const fetchUrl = checkedDevelopmentType();

      instance({
        url: `/api/session`,
        method: 'GET',
        headers: {
          UIP: getCookie('UIP'),
        },
        params: {
          token: token,
          app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
          // host: fetchUrl,
        },
      })
        .then(userInfoFront => {
          if (userInfoFront) {
            this.determineAndDispatchLayer(userInfoFront)
            this.props.dispatch(logInUserInfo(userInfoFront));
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(error => {
          localStorage.removeItem("backtoAdmin")
          // Handle error here
          setCookie('JSESSIONID', 0);
          this.props.history.push('/logout', {
            from: this.props.history.location.pathname,
          });
        });
    } else {
      this.setState({ loading: false });
    }
  };

//   showResources = (type) => {
//     if(!type) {
//       this.props.dispatch(saveGroupWiseDevices([]))
//       this.props.dispatch(resetFilters())
//     }
//     this.setState({
//       resourceList: type
//     })
//   }

//   showBox = ({id, name}) => {
//     const boxes1 = this.state.boxes.filter(box => box.id !== id);
//     const boxes = boxes1.filter((e, index) => (index + 1) < maxBoxes);
    
//     this.setState({ boxes: [...boxes, { id, name, ...options }] });
//   }

//   closeBox = (id, event) => {
//     event.stopPropagation()
//     this.setState({boxes: this.state.boxes.filter(box => box.id !== id)});
//   } 

//   componentWillMount () {
//     this.props.dispatch(saveGroupWiseDevices([]))
//     this.props.dispatch(resetFilters())
//     if (this.props.logInUser && this.props.logInUser.id) {
//       this.fetchData2(
//         this.props.logInUser.id,
//         this.state.currentPage2,
//         this.state.pageSize2
//       )
//     }
//   }

  setMapRef = ref => {
    this.map = ref
  }

//   displayError = e => {
//     if (e) {
//       // e.text().then(err => {
//       //   console.log(err)
//       // })
//       console.log(e)
//     }
//   }

//   componentWillReceiveProps (n) {
//     if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
//       this.setState(
//         {
//           initFetch: true
//         },
//         () => {
//           this.fetchData2(
//             n.logInUser.id,
//             this.state.currentPage2,
//             this.state.pageSize2
//           )
//         }
//       )
//     }
//   }
//   openDeviceSettings = deviceId => {
//     this.props.history.push('/units/' + deviceId)
//   }

//   fetchMoreItems2 = (a, b, c) => {
//     this.setState(
//       {
//         searchText2: ''
//       },
//       () => {
//         this.fetchData2(
//           this.props.logInUser.id,
//           this.state.currentPage2,
//           this.state.pageSize2,
//           true
//         )
//       }
//     )
//   }

//   removeDuplicates = (n, o) => {
//     let items = []
//     o.map(j => {
//       let found = n.data.find(e => e.id === j.id)
//       if (found) {
//         items.push(found)
//       }
//       return null
//     })

//     if (!items.length) {
//       this.setState(
//         {
//           itemPagination2: {
//             ...n,
//             isFetching: false,
//             items: o.concat(n.data)
//           },
//           currentPage2: n.hasNext ? n.page + 1 : n.page
//         },
//         () => {
//           this.props.dispatch(getGroups(o.concat(n.data)))
//         }
//       )
//     }
//   }

//   fetchData2 = (userId, page, perPage, reset) => {
//     let searchText = this.state.searchText2

//     if (searchText) {
//       searchText = '&search=' + searchText
//     } else {
//       searchText = ''
//     }

//     let items = this.state.itemPagination2.items
//     if (reset) {
//       this.setState(
//         {
//           itemPagination2: {
//             ...this.state.itemPagination2,
//             isFetching: true,
//             items: []
//           }
//         },
//         () => {
//           items = []
//         }
//       )
//     }
//     source = CancelToken.source()
//     instance({
//       method: 'GET',
//       url: `/api/groups/get?page=${page}&limit=${perPage}${searchText}`,
//       cancelToken: source.token,
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json'
//       }
//     }).then(response => {
//       // if (response.status === 200) {
//         this.removeDuplicates(response, items)
//       // }
//     }).catch(error => {
//       // errorHandler(error, this.props.dispatch)
//     })
//   }

//   searchItems2 = text => {
//     this.setState(
//       {
//         searchText2: text
//       },
//       () => {
//         this.fetchData2(this.props.logInUser.id, 1, this.state.pageSize2, true)
//       }
//     )
//   }

//   componentWillUnmount () {
//     this.props.dispatch(unsetDeviceId(0))
//     this.props.dispatch(resetFilters())
//     this.props.dispatch(saveGroupWiseDevices([]))
//     if (source) {
//       source.cancel()
//     }
//     this.setState({
//       userInfoFront: {},
//       userFetch: false,
//       userId: 0,
//       initFetch: false,
//       vehicleModal: false,
//       driverModal: false,
//       resourceList: false,
//       linkResource: false,
//       selecteditem: '',

//       currentPage2: 1,
//       pageSize2: -1,
//       itemPagination2: {
//         items: [],
//         total: 0,
//         currentPage: 0,
//         currentDevice: this.props.deviceId,
//         hasNext: true,
//         searchText2: ''
//       }
//     })
//   }

  copyToClipboard = (obj,chk) => {
    const el = document.createElement('textarea')
    chk ?el.value = obj :el.value = `${obj.latitude},${obj.longitude}`
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.props.dispatch(
      Notifications.success({
        message: 'Copied to clipboard!',
        autoDismiss: 10
      })
    )
  }

//   openVehicleTagsModal = () => {
//     this.setState({
//       vehicleModal: true
//     })
//   }

//   openDriverTagsModal = () => {
//     this.setState({
//       driverModal: true
//     })
//   }

//   cancelFilter = () => {
//     this.setState({
//       vehicleModal: false,
//       driverModal: false
//     })
//   }

//   saveFilter = () => {
//     this.setState({
//       vehicleModal: false,
//       driverModal: false
//     })
//   }

//   onLinkResource = (item) =>{
//     this.setState({
//       linkResource: true,
//       selecteditem: item,
//     },()=>{this.props.fetchNestedItems(item.id,1)})
//   }
//   onCloseResource = () =>{
//     this.setState({
//       linkResource: false,
//       selecteditem: ''
//     });
//   }

//   getSensorsData = (id) =>{
//        instance({
//         url: `api/attributes/computed`,
//         method: 'GET',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json'
//         },
//         params :{
//           deviceId: id ,
//           // limit: 4,
//           category:"all",
//           all:true
//         }
//       })
//       .then(
//         response => {
//           if (response) {
//             this.setState({
//               sensorsDesc:response
//             })
//             // response.json()
//             // .then(events => {
//             //   this.props.dispatch(updateEvents(events.data))
//             // })
//           }
//           else{
//            console.log("error")
//           }
//         }).catch(error => {
//           // errorHandler(error, this.props.dispatch)
//         })
//   }
  showAllTooltTip = ()=> {
     this.setState({showAll:!this.state.showAll})
  }  

//   // Add this function to get the timezone
//   getTimezone = () => {
//     let timezone = 'Asia/Dubai';
  
//     if (
//       this.props.ServerSetting &&
//       this.props.ServerSetting.attributes &&
//       this.props.ServerSetting.attributes.timezone
//     ) {
//       timezone = this.props.ServerSetting.attributes.timezone;
//     }
  
//     if (
//       this.props.logInUser &&
//       this.props.logInUser.attributes &&
//       this.props.logInUser.attributes.timezone
//     ) {
//       timezone = this.props.logInUser.attributes.timezone;
//     }
  
//     return timezone;
//   };

  hideTracks =() =>{
    this.setState({showMonitoringTracks:false, routes:{}})
  }



  showTracks = (deviceId) => {
    this.setState({
      showMonitoringTracks : true,
    })

    const timezone = this.getTimezone();

    moment.tz.setDefault(timezone);

    const todayStart = moment().startOf('day');
    const todayEnd = moment().endOf('day');

    const formatedDate = {
        from: todayStart.toISOString(),
        to: todayEnd.toISOString()
    };

    this.setState({
      showMonitoringTracks : false,
      tracksLoader:true,
    }, () => {
        let form = {
            to: todayEnd.toISOString(),
            from: todayStart.toISOString(),
            deviceIds: [deviceId?.id?.toString()] || [],
            itemGroupIds:  [],
            detail: false,
            types: [],
            mail: false
        };

        instance({
            url: `/api/reports/tracks?from=${formatedDate.from}&to=${formatedDate.to}&deviceId=${deviceId?.id}`,
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            // data: {...form}
        })
        .then(response => {
            let  obj = response || {}
            const transformedObj = {};

            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                if (obj[key].length === 0) {
                  this.props.dispatch(
                    Notifications.success({
                      message: `No tracks found for vehicle ${deviceId.name}`,
                      autoDismiss: 10
                    })
                  )
                } else {
                  transformedObj[key] = {};
                  obj[key].forEach((item, index) => {
                    transformedObj[(index + 1).toString()] = { visible: true, positions: item.positions, row: item.track };
                  });
                }
              }
            }
          
              this.setState({
                showMonitoringTracks : Object.keys(transformedObj).length > 0,
                tracksLoader:false,
                routes : transformedObj||[]
              })
            }
          )
        .catch(error => {errorHandler(error, this.props.dispatch)});
    });
}
showListType = (type) => {
  this.setState({
    listType: type
  })
}

showListType = (type) => {
  this.setState({
    listType: type
  })
}

componentDidMount() {
  const initialId = this.props.positions[0]?.deviceId;
  if (initialId) {
    this.fetchData(initialId);
  }
}

componentDidUpdate(prevProps) {
  const prevDeviceId = prevProps.positions[0]?.deviceId;
  const currentDeviceId = this.props.positions[0]?.deviceId;

  if (currentDeviceId && currentDeviceId !== prevDeviceId) {
    this.fetchData(currentDeviceId);
  }
}

fetchData = async (id) => {
  try {
    const response = await instance({
      method: 'GET',
      url: `/api/devices/${id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    this.setState({ deviceData: response, lastDeviceId: id });

  } catch (error) {
    this.setState({ error });
  }
};


render () {

  const {devices3, ...resetProps}  = this.props;
if(!this.state.loading){
  return(
    <>
    <ShareMap center={this.state.center} {...this.props} deviceData={this.state.deviceData}/>
    </>

    )
}else{
  return <Loader/>
}
  }
}


const mapState = state => ({
  driverList: state.drivers,
  themecolors: state.themeColors,
  positions: state.positions,
  vehicles:state.vehicles,
  allComputedAttributes: state.allComputedAttributes,
  mapLayer: state.mapLayer,
  logInUser: state.logInUsers,
  devicesFetched: state.devices.isFetching,
  devices: state.devices,
  devices3: state.devices3,
  trailers: state.trailers,
  tags: state.tags,
  driverTags: state.driverTags,
  allNotifications: state.allNotifications,
  deviceId: state.deviceId,
  trackId: state.trackId,
  drivers: state.drivers,
  ServerSetting: state.ServerSetting,
  filterTagsList: state.filterTagsList,
  filterDriversList: state.filterDriversList,
  vehicles:state.vehicles,
  filterList:
    state.filterList && state.filterList.filters
      ? state.filterList.filters
      : []

})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(MonitoringShareLocation))
