import React, { Component } from "react";
import TextField from "./TextField";
import SearchField from "./SearchField";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import Style from "style-it";
import { connect } from "react-redux";

class CustomDateRangePicker extends Component {
  render() {
    const rangesFuture = {
      Today: [moment().startOf("day"), moment().endOf("day")],
      "Next 7 Days": [
        moment().endOf("day"),
        moment().add(7, "days").endOf("day"),
      ],
      "Next 30 Days": [
        moment().endOf("day"),
        moment().add(30, "days").endOf("day"),
      ],
      "This Month": [
        moment().startOf("month").startOf("day"),
        moment().endOf("month").endOf("day"),
      ],
      "Next Month": [
        moment().add(1, "month").startOf("month").startOf("day"),
        moment().add(1, "month").endOf("month").endOf("day"),
      ],
    };
    const ranges = {
      Today: [moment().startOf("day"), moment().endOf("day")],
      Yesterday: [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day"),
      ],
      "Last 7 Days": [
        moment().subtract(7, "days").startOf("day"),
        moment().startOf("day"),
      ],
      "Last 30 Days": [
        moment().subtract(30, "days").startOf("day"),
        moment().startOf("day"),
      ],
      "This Month": [
        moment().startOf("month").startOf("day"),
        moment().endOf("month").endOf("day"),
      ],
      "Last Month": [
        moment().subtract(1, "month").startOf("month").startOf("day"),
        moment().subtract(1, "month").endOf("month").endOf("day"),
      ],
    };
    const timeMachineRanges = {
      Today: [moment().startOf("day"), moment().endOf("day")],
      Yesterday: [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day"),
      ],
      "Last 7 Days": [
        moment().subtract(7, "days").startOf("day"),
        moment().startOf("day"),
      ],
    };
    const dlogsRanges = {
      Today: [moment().startOf("day"), moment().endOf("day")],
      Yesterday: [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day"),
      ],
      "Last 3 Days": [
        moment().subtract(2, "days").startOf("day"),
        moment().startOf("day"),
      ],
    };
    if (this.props.fill) {
      return (
        <>
          <Style>
            {`
            .daterangepicker td.in-range {
               background: ${this.props.themecolors[100]};
            }
            .daterangepicker td.active, .daterangepicker td.active:hover,
            .daterangepicker .ranges li.active {
               background: ${this.props.themecolors.themeLightColor};
               color: ${this.props.themecolors.themeLightInverse};
            }
            `}
          </Style>
          <DateRangePicker
            containerStyles={{}}
            {...this.props}
            ranges={
              this.props.futureDates
                ? rangesFuture
                : this.props.timeMachine
                ? timeMachineRanges
                : this.props.dlogs
                ? dlogsRanges
                : this.props.ranges || ranges
            }
            // onCallback={(e)=>{console.log("Res==", e)}}
            timePicker={this.props.datepickerOnly !== false}
            timePicker24Hour={this.props.datepickerOnly !== false}
            showCustomRangeLabel={true}
            // showCustomRangeLabel={this.props.timeMachine ? false : true}
            singleDatePicker={this.props.timeMachine ? true : false}
            // minDate={moment().subtract(2, 'days').startOf('day')} 
            // maxDate={moment().add(2, 'days').endOf('day')} 
          >
            <SearchField
              error={this.props.error}
              fullWidth
              margin="dense"
              label={this.props.label}
              value={this.props.selectedDate || ""}
            />
          </DateRangePicker>
        </>
      );
    } else {
      return (
        <>
          <Style>
            {`
            .daterangepicker td.in-range {
               background: ${this.props.themecolors[100]};
            }
            .daterangepicker td.active, .daterangepicker td.active:hover,
            .daterangepicker .ranges li.active {
               background: ${this.props.themecolors.backgroundColor};
               color: ${this.props.themecolors.textColor};
            }
            `}
          </Style>

          <DateRangePicker
            containerStyles={{}}
            {...this.props}
            ranges={
              this.props.futureDates
                ? rangesFuture
                : this.props.ranges || ranges
            }
            timePicker={this.props.datepickerOnly !== false}
            timePicker24Hour={this.props.datepickerOnly !== false}
          >
            <TextField
              fullWidth
              error={this.props.error}
              margin="dense"
              label={this.props.label || "Select Date"}
              value={this.props.selectedDate}
            />
          </DateRangePicker>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  themecolors: state.themeColors,
});

export default connect(mapStateToProps)(CustomDateRangePicker);
